import { crudService } from '../_services/'

export const tipoUsuarioAction = {
  getTiposUsuarioSelect
}

function getTiposUsuarioSelect() {
  return (dispatch) => {
    let apiEndpoint = 'tiposUsuarios/select'
    crudService
      .get(apiEndpoint)
      .then((response) => {
        if (response.data) {
          // response.data.unshift({ value: '', text: ' SELECIONE ...' })
          dispatch(changeTiposUsuariosList(response.data))
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }
}

export function changeTiposUsuariosList(tipoUsuario) {
  return {
    type: 'FETCHED_ALL_TIPO_USUARIO',
    tipoUsuario: tipoUsuario
  }
}

