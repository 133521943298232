import { crudService } from '../_services'

export const cupomProdutoAction = {
  getFidelizacaoProdutos,
  clear,
  clearAll
}

function getFidelizacaoProdutos(id, showWaiting) {
  return (dispatch) => {
    dispatch(isLoading())
    let apiEndpoint = 'cuponsProdutos/fidelizacao/' + id
    crudService.get(apiEndpoint).then((response) => {
      dispatch(changeCuponsProdutosList(response.data))
      dispatch(notIsLoading())
    })
  }
}

export function clear() {
  return {
    type: 'CUPOMPRODUTO_CLEAR'
  }
}

export function clearAll() {
  return {
    type: 'CUPOMPRODUTO_CLEAR_ALL'
  }
}

export function editCuponsProdutosDetails(cupomProduto) {
  return {
    type: 'CUPOMPRODUTO_DETAIL',
    id: cupomProduto.id,
    isLoading: cupomProduto.isLoading,
    idCupom: cupomProduto.idCupom,
    idProduto: cupomProduto.idProduto,
    idProdutoReferencia: cupomProduto.idProdutoReferencia,
    quantidade: cupomProduto.quantidade,
    valor: cupomProduto.valor
  }
}

export function changeCuponsProdutosList(cupomProduto, totalRows) {
  return {
    type: 'FETCHED_ALL_CUPOMPRODUTO',
    cupomProduto: cupomProduto,
    totalRegistros: totalRows
  }
}
export function handleOnChangeProps(props, value) {
  return {
    type: 'CUPOMPRODUTO_HANDLE_ON_CHANGE',
    props: props,
    value: value
  }
}

export function createCuponsProdutosInfo() {
  return {
    type: 'CUPOMPRODUTO_CREATED_SUCCESSFULLY'
  }
}

export function isLoading() {
  return {
    type: 'CUPOMPRODUTO_ISLOADING'
  }
}

export function notIsLoading() {
  return {
    type: 'CUPOMPRODUTO_NOTISLOADING'
  }
}

