import React, { useState, useEffect } from 'react'
import { connect, useDispatch, useSelector } from 'react-redux'
import clsx from 'clsx'
import DeleteIcon from '@material-ui/icons/Delete'
import EditIcon from '@material-ui/icons/Edit'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import { AccessControl } from '../../components/Utils/AccessControl'
import { toast } from 'react-toastify'
import { useConfirm } from 'material-ui-confirm'
import {
  Badge,
  Button,
  Card,
  CardHeader,
  CardContent,
  CardActions,
  CircularProgress,
  Divider,
  FormControlLabel,
  Grid,
  MenuItem,
  IconButton,
  Input,
  Switch,
  Table,
  TableContainer,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip
} from '@material-ui/core'
import {
  ValidatorForm,
  TextValidator,
  SelectValidator
} from 'react-material-ui-form-validator'
import { checklistOperacaoAction, totvsInsumoAction, checklistOperacaoItemAction, lojaAction, categoriaInsumoAction, unidadeAction } from '../../_actions'
import { withRouter } from 'react-router-dom'
import { history, stringUtils } from '../../utils'
import { Page } from 'components'
import SaveIcon from '@material-ui/icons/Save'
import SortIcon from '@material-ui/icons/Sort'
import HelpIcon from '@material-ui/icons/Help'
import { isEmpty } from 'lodash'
import { useDebounce } from 'use-debounce';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus, faTrashAlt } from '@fortawesome/free-solid-svg-icons'
import Swal from 'sweetalert2'
import { sortableContainer, sortableElement } from 'react-sortable-hoc'
import { arrayMoveMutable } from 'array-move'
import DragHandleIcon from '@material-ui/icons/DragHandle'

const styles = (theme) => ({
  root: {
    padding: theme.spacing(2)
  },
  content: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    textAlign: 'center'
  },
  descricao: {
    marginTop: theme.spacing(1)
  },
  uploadButton: {
    marginRight: theme.spacing(2),
    justifyContent: 'space-between'
  },
  avatar: {
    height: 200,
    width: 200,
    borderRadius: '10px',
    webkitBorderRadius: '10px',
    mozBorderRadius: '10px',
    cursor: 'pointer'
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff'
  },
  tituloDelivery: {
    textAlign: 'center',
    alignItems: 'center',
    display: 'column',
    flex: 1,
    marginTop: 10
  }
})

const ChecklistOperacaoEdit = (props) => {

  const dispatch = useDispatch()
  const confirm = useConfirm()
  const { classes, checklistOperacao, checklistOperacaoItem, className, ...rest } = props
  const { match: { params } } = props;
  const formRef = React.createRef(null);

  const [idLoja, setIdLoja] = useState(0);
  const [textLoja, setTextLoja] = useState('');
  const [vincularLojas, setVincularLojas] = useState(false);

  const [idTotvsInsumo, setIdTotvsInsumo] = useState(0);
  const [, forceUpdate] = React.useState(0);

  const totvsInsumos = useSelector((state) => state.totvsInsumo.totvsInsumo)
  const lojas = useSelector((state) => state.loja.loja)
  const categoriasInsumos = useSelector((state) => state.categoriaInsumo.categoriaInsumo)

  const [termOperacao, setTermOperacao] = useState('');
  const [debounceTermOperacao] = useDebounce(termOperacao, 1000);

  const checklistsOperacaoItensSelector = useSelector((state) => state.checklistOperacaoItem.checklistOperacaoItem.checklistsOperacaoItens);
  const [checklistsOperacaoItens, setChecklistsOperacaoItens] = useState(checklistsOperacaoItensSelector);
  const unidade = useSelector((state) => state.unidade.unidade)

  const [editOrdem, setEditOrdem] = useState(false);

  const { isLoading } = useSelector((state) => state.checklistOperacao);
  const { isLoadingItem } = useSelector((state) => state.checklistOperacaoItem);

  const SortableItem = sortableElement(({ value }) => {
    return (
      <TableRow hover key={value}>
        <TableCell component="th" scope="row">
          <IconButton
            aria-label="Arrastar"
            component="a"
            id="btnEdit"
          >
            <Tooltip title="Arraste e solte">
              <Tooltip >
                <DragHandleIcon />
              </Tooltip>
            </Tooltip>
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row">
          {value.descricao}
        </TableCell>        
      </TableRow>
    )
  });

  const SortableContainer = sortableContainer(({ children }) => {
    return (
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell></TableCell>
              <TableCell>Descrição</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {children}
          </TableBody>
        </Table>
      </TableContainer>
    )
  });

  useEffect(() => {
    if (params.id) {
      dispatch(checklistOperacaoAction.getChecklistOperacaoById(params.id))

      let filterModel = {
        IdChecklistOperacao: params.id
      };
      dispatch(checklistOperacaoItemAction.getChecklistOperacaoItem(filterModel))
    } else {
      dispatch(checklistOperacaoAction.clear())
      dispatch(checklistOperacaoAction.clearChecklistOperacoesItens())
      dispatch(checklistOperacaoAction.clearChecklistOperacoesLojas())
    }

    dispatch(totvsInsumoAction.getTotvsInsumoSelect())
    dispatch(categoriaInsumoAction.getCategoriaInsumoSelect())
    dispatch(unidadeAction.getUnidadeSelect())
    dispatch(lojaAction.getLojaSelect())
    dispatch(checklistOperacaoItemAction.clear())

  }, []);

  useEffect(() => {
    if (props.checklistOperacao.todasLojas) {
      setVincularLojas(false);
      props.checklistOperacao.checklistsOperacaoLojas = []
    } else {
      setVincularLojas(true);
    }
  }, [props.checklistOperacao.todasLojas])

  const handleEditItem = (n,) => {
    dispatch(checklistOperacaoItemAction.getChecklistOperacaoItemById(n.id))
    setIdTotvsInsumo(n.idTotvsInsumo);

    dispatch(checklistOperacaoItemAction.editChecklistOperacoesItensDetails(n.id))
  }

  const handleDeleteChecklistOperacaoItem = (id) => {
    confirm({
      title: 'Você deseja excluir este item do checklist?',
      description: 'Essa operação é irreversível',
      disabled: isLoadingItem,
      confirmationText: isLoadingItem ? 'Excluindo Item...' : 'Sim, excluir!',
      cancellationText: 'Não!'

    }).then(() => {
      let filterModel = {
        IdChecklistOperacao: params.id
      };
      dispatch(checklistOperacaoItemAction.deleteChecklistOperacaoItemById(id, filterModel))
    })
  }

  useEffect(() => {
    var nextOrdem = 0;
    var tmp;

    if (checklistsOperacaoItens) {
      for (var i = checklistsOperacaoItens.length - 1; i >= 0; i--) {
        tmp = checklistsOperacaoItens[i].ordem;
        if (tmp > nextOrdem) nextOrdem = tmp;
      }
    }
    let event = {
      type: 'text',
      target: {
        value: nextOrdem + 1
      }
    }
    dispatch(checklistOperacaoItemAction.onChangeProps('ordem', event))

  }, [isLoadingItem, checklistsOperacaoItens])

  useEffect(() => {
    if (checklistsOperacaoItensSelector && checklistsOperacaoItensSelector.length > 0) {
      setChecklistsOperacaoItens(checklistsOperacaoItensSelector)
    } else {
      setChecklistsOperacaoItens([])
    }
  }, [checklistsOperacaoItensSelector])

  const handleChange = (prop) => (event) => {
    dispatch(checklistOperacaoAction.onChangeProps(prop, event))
  }

  const handleChangeItem = (prop) => (event) => {
    dispatch(checklistOperacaoItemAction.onChangeProps(prop, event))
  }

  const handleSelectChangeState = prop => event => {
    setIdLoja(event.target.value);
    setTextLoja(event.nativeEvent.target.innerText);
  };

  const handleSelectChangeStateInsumo = prop => event => {
    setIdTotvsInsumo(event.target.value);
  };

  const handleSubmit = (event) => {
    let payload = {
      id: params.id,
      nome: props.checklistOperacao.nome,
      instrucao: props.checklistOperacao.instrucao,
      periodicidade: props.checklistOperacao.periodicidade,
      idCategoriaInsumo: props.checklistOperacao.idCategoriaInsumo,
      tipo: props.checklistOperacao.tipo,
      todasLojas: props.checklistOperacao.todasLojas,
      checklistsOperacaoLojas: props.checklistOperacao.checklistsOperacaoLojas,
      checklistsOperacaoItens: (params.id > 0 ? checklistsOperacaoItens : null)
    }

    if (params.id) {
      dispatch(checklistOperacaoAction.editChecklistOperacaoInfo(params.id, payload, true))

    } else {
      dispatch(checklistOperacaoAction.createChecklistOperacao(payload))
    }
  };

  const handleSubmitItem = (event) => {

    if (props.checklistOperacaoItem.descricao != null && props.checklistOperacaoItem.descricao != '') {

      if (props.checklistOperacaoItem.ordem != 0 && props.checklistOperacaoItem.ordem != null) {

        let payload = {
          id: props.checklistOperacaoItem.id,
          descricao: props.checklistOperacaoItem.descricao,
          idChecklistOperacao: props.checklistOperacao.id,
          idTotvsInsumo: idTotvsInsumo ? idTotvsInsumo : null,
          ordem: props.checklistOperacaoItem.ordem,
          diasVencimento: (props.checklistOperacaoItem.diasVencimento != 0 ? parseInt(props.checklistOperacaoItem.diasVencimento) : null),
          coeficienteEstoqueMinimo: (props.checklistOperacaoItem.coeficienteEstoqueMinimo != 0 ? parseFloat(props.checklistOperacaoItem.coeficienteEstoqueMinimo) : null),
          coeficienteEstoqueMaximo: (props.checklistOperacaoItem.coeficienteEstoqueMaximo != 0 ? parseFloat(props.checklistOperacaoItem.coeficienteEstoqueMaximo) : null),
          pesoLiquido: (props.checklistOperacaoItem.pesoLiquido != 0 ? props.checklistOperacaoItem.pesoLiquido : null),
          idUnidadeProducao: (props.checklistOperacaoItem.idUnidadeProducao != 0 ? props.checklistOperacaoItem.idUnidadeProducao : null)
        }

        let filterModel = {
          IdChecklistOperacao: params.id
        };

        if (props.checklistOperacaoItem.id) {
          dispatch(checklistOperacaoItemAction.editChecklistOperacaoItemInfo(props.checklistOperacaoItem.id, payload, filterModel))
          setIdTotvsInsumo(0);
        } else {
          dispatch(checklistOperacaoItemAction.createChecklistOperacaoItem(payload, filterModel))
          setIdTotvsInsumo(0);
        }
      } else {
        toast.error('Preencha a ordem!')
      }
    } else {
      toast.error('Preencha a descrição!')
    }
  };

  const handleCleanItens = () => {
    dispatch(checklistOperacaoItemAction.clear())
    setIdTotvsInsumo(0);
  }

  const handleAddOnList = e => {

    if (props.checklistOperacao.checklistsOperacaoLojas.filter(ts => ts.idLoja == parseInt(idLoja)).length > 0) {
      toast.error('Erro ao adicionar! Esta loja já existe!')
    }
    else {

      if (idLoja != '' && idLoja != '0') {
        props.checklistOperacao.checklistsOperacaoLojas.push({ 'idLoja': idLoja, 'idChecklist': props.checklistOperacao.id, 'loja': { 'nome': textLoja } });
        forceUpdate(n => !n);
        setIdLoja(0);

      } else {
        Swal.fire({
          icon: 'warning',
          title: 'Erro ao salvar',
          showCloseButton: true,
          showConfirmButton: true,
          html: '<div style="font-size: 14px; text-align: center">Informe ao menos uma loja!</div>'
        });
      }
    }
  };

  const handleRemoveOnList = id => {
    props.checklistOperacao.checklistsOperacaoLojas = props.checklistOperacao.checklistsOperacaoLojas.filter(ts => ts.idLoja != parseInt(id));
    forceUpdate(n => !n);
  };

  const onSortEnd = ({ oldIndex, newIndex }) => {
    dispatch(checklistOperacaoItemAction.onChangeProps('checklistOperacaoItem', arrayMoveMutable(checklistsOperacaoItens, oldIndex, newIndex)))
    toast.warning('Ordem editada. Clique no botão atualizar para confirmar e gravar a alteração!')
  };

  function excludeFilter(obj) {
    if (props.checklistOperacao.checklistsOperacaoLojas && props.checklistOperacao.checklistsOperacaoLojas.length > 0) {
      if (props.checklistOperacao.checklistsOperacaoLojas.filter(fl => fl.idLoja == parseInt(obj.value)).length > 0) {
        return false;
      } else {
        return true;
      }

    } else {
      return true;
    }
  }

  function InsertText() {
    return (
      <Typography variant="h4">
        <b>{'Novo Checklist de Operação'}</b>
      </Typography>
    )
  }

  function EditText() {
    return (
      <Typography variant="h4">
        <b>{'Editar Checklist de Operação'}</b>
      </Typography>
    )
  }

  function SegHeader() {
    if (params.id) {
      return <EditText />
    }
    return <InsertText />
  }

  return (
    <Page
      className={classes.root}
      title={`Gerência de Checklist de Operação - ${params.id ? 'Editar' : 'Nova'} Checklist de Operação`}
    >
      <Grid
        {...rest}
        className={clsx(classes.root, className)}
        container
        spacing={3}
      >
        <Grid item lg={12} md={12} xl={12} xs={12}>
          <Grid item md={12} xs={12}>
            <Card>
              <ValidatorForm
                className={classes.form}
                ref={formRef}
                id="formChecklist"
                onSubmit={(event) => handleSubmit(event)}
              >
                <CardHeader
                  subheader="Cadastro de Checklist de Operação"
                  title={<SegHeader />}
                />
                <Divider />
                <CardContent lg={12} md={12} xl={12} xs={12}>
                  <Grid container spacing={2}>
                    <Grid item lg={6} md={6} xl={6} xs={12}>
                      <TextValidator
                        className={classes.textField}
                        fullWidth
                        id="nome"
                        label="Nome *"
                        onInput={stringUtils.toInputUppercase}
                        margin="normal"
                        onChange={handleChange('nome')}
                        value={props.checklistOperacao.nome}
                        variant="outlined"
                        validators={['required']}
                        errorMessages={['Campo obrigatório!']}
                      />
                    </Grid>
                    <Grid item lg={6} md={6} xl={6} xs={12}>
                      <TextValidator
                        className={classes.textField}
                        fullWidth
                        id="instrucao"
                        label="Instrução"
                        margin="normal"
                        onChange={handleChange('instrucao')}
                        value={props.checklistOperacao.instrucao}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item lg={4} md={4} xl={4} xs={12}>
                      <SelectValidator
                        validators={['required']}
                        errorMessages={['Campo obrigatório!']}
                        className={classes.textField}
                        label="Periodicidade *"
                        labelId="periodicidade"
                        value={props.checklistOperacao.periodicidade || ''}
                        onChange={handleChange('periodicidade')}
                        name="periodicidade"
                        fullWidth
                        inputProps={{
                          name: "Periodicidade",
                          id: "periodicidade",
                          shrink: true
                        }}
                        input={<Input id="periodicidade" />}
                        margin="normal"
                        variant="outlined"
                        id="tipo"
                      >
                        <MenuItem value={'S'}>SEMANAL</MenuItem>
                        <MenuItem value={'M'}>MENSAL</MenuItem>
                      </SelectValidator>
                    </Grid>
                    <Grid item lg={4} md={4} xl={4} xs={12}>
                      <SelectValidator
                        validators={['required']}
                        errorMessages={['Campo obrigatório!']}
                        className={classes.textField}
                        variant="outlined"
                        id="idCategoriaInsumo"
                        label="Categoria"
                        value={props.checklistOperacao.idCategoriaInsumo || ''}
                        onChange={handleChange('idCategoriaInsumo')}
                        margin="normal"
                        InputLabelProps={{
                          shrink: true
                        }}
                        fullWidth
                        displayEmpty
                        labelId="idCategoriaInsumo"
                      >
                        <MenuItem disabled value="">
                          <em>Categoria *</em>
                        </MenuItem>
                        {!isEmpty(categoriasInsumos) &&
                          undefined !== categoriasInsumos &&
                          categoriasInsumos.length &&
                          categoriasInsumos.map((row, index) => (
                            <MenuItem key={index} value={row.value}>
                              {row.text}
                            </MenuItem>
                          ))}
                      </SelectValidator>
                    </Grid>
                    <Grid item lg={4} md={4} xl={4} xs={12}>
                      <SelectValidator
                        validators={['required']}
                        errorMessages={['Campo obrigatório!']}
                        className={classes.textField}
                        label="Tipo *"
                        labelId="tipo"
                        value={props.checklistOperacao.tipo || ''}
                        onChange={handleChange('tipo')}
                        name="tipo"
                        fullWidth
                        inputProps={{
                          name: "Tipo",
                          id: "tipo",
                          shrink: true
                        }}
                        input={<Input id="tipo" />}
                        margin="normal"
                        variant="outlined"
                        id="tipo"
                      >
                        <MenuItem value={'C'}>CHECKLIST</MenuItem>
                        <MenuItem value={'P'}>PRODUÇÃO</MenuItem>
                        <MenuItem value={'T'}>TEMPERATURA</MenuItem>
                      </SelectValidator>
                    </Grid>
                  </Grid>
                  <Grid item md={12} xs={12} style={{ marginTop: '15px', marginBottom: '15px' }}>
                    <Divider />
                  </Grid>
                  <Grid item lg={12} md={12} xl={12} xs={12} style={{ alignContent: 'center', justifyContent: 'center' }}>
                    <Typography variant="h5" align="center">
                      <b>{'Lojas vinculadas'}</b>
                    </Typography>
                  </Grid>
                  <Grid item lg={12} md={12} xl={12} xs={12} align="center" style={{ marginTop: '15px', marginBottom: '20px' }}>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={props.checklistOperacao.todasLojas}
                          onChange={handleChange('todasLojas')}
                          color="primary"
                        />
                      }
                      label="Selecionar todas as lojas"
                    />
                  </Grid>
                  <Grid container xl={12} lg={12} md={12} xs={12} style={{ marginTop: '15px', marginBottom: '15px' }}>
                    <Grid item lg={4} md={4} xl={4} xs={12} style={{ alignItems: 'center', justifyContent: 'center', alignContent: 'center' }} >
                      <SelectValidator
                        validators={['required']}
                        errorMessages={['Campo obrigatório!']}
                        variant="outlined"
                        disabled={!vincularLojas}
                        id="idLoja"
                        label="Lojas vinculadas *"
                        value={idLoja}
                        onChange={handleSelectChangeState('idLoja')}
                        InputLabelProps={{
                          shrink: true
                        }}
                        fullWidth
                        displayEmpty
                        labelId="idLoja"
                      >
                        <MenuItem disabled value="" key={12}>
                          <em>Loja *</em>
                        </MenuItem>
                        {!isEmpty(lojas) &&
                          undefined !== lojas &&
                          lojas.length &&
                          lojas.filter(excludeFilter).map((row, index) => (
                            <MenuItem key={'u' + index} value={row.value}>
                              {row.text}
                            </MenuItem>
                          ))}
                      </SelectValidator>
                    </Grid>
                    <Grid item lg={4} md={4} xl={4} xs={12} style={{ alignItems: 'top', justifyContent: 'center' }}>
                      <Button style={{ width: '200px', marginLeft: '15px' }} disabled={!vincularLojas} name="btnLojas" id="btnLojas" className="btn" variant="contained" color="primary" component='a' onClick={handleAddOnList}>Adicionar&nbsp;<FontAwesomeIcon icon={faPlus} /></Button>
                    </Grid>
                    <Grid
                      item
                      lg={vincularLojas ? 4 : 12}
                      md={vincularLojas ? 4 : 12}
                      xl={vincularLojas ? 4 : 12}
                      xs={12}
                    >
                      <div className="selectgroup selectgroup-pills" style={{ marginLeft: '5px', display: 'flex-row', alignItems: 'top' }}>
                        <Grid
                          item
                          lg={vincularLojas ? 12 : 4}
                          md={vincularLojas ? 12 : 4}
                          xl={vincularLojas ? 12 : 4}
                          xs={12}
                        >
                          {(props.checklistOperacao && props.checklistOperacao.checklistsOperacaoLojas && props.checklistOperacao.checklistsOperacaoLojas.length > 0 ?
                            props.checklistOperacao.checklistsOperacaoLojas.map(n => {
                              return (
                                (n.id !== '' ?
                                  <Button color="default" onClick={() => handleRemoveOnList(n.idLoja)} key={'button' + n.id} disabled={!vincularLojas}>
                                    <Badge color="primary" style={{ fontSize: 15, paddingLeft: 7, paddingRight: 7 }}>
                                      {(n.loja ? n.loja.nome : n.idLoja)}
                                    </Badge>
                                    &nbsp;<FontAwesomeIcon icon={faTrashAlt} />
                                  </Button>
                                : null)
                              );
                            })
                            :
                            null
                          )}
                        </Grid>
                      </div>
                    </Grid>
                  </Grid>
                  <CardActions>
                    <Grid item lg={12} md={12} xl={12} xs={12}>
                      <Typography style={{ fontSize: "10px", paddingLeft: "6px" }}>
                        * Campos obrigatórios
                      </Typography>
                    </Grid>
                    <Grid item lg={12} md={12} xl={12} xs={12} style={{
                      display: 'flex',
                      alignItems: 'right',
                      flexDirection: 'row',
                      textAlign: 'right',
                      justifyContent: 'right'
                    }}>
                      <Button
                        color="default"
                        onClick={() => history.push('/checklistsOperacoes')}
                        variant="contained"
                      >
                        Cancelar
                      </Button>
                      <Button
                        color="primary"
                        type="submit"
                        variant="contained"
                        disabled={isLoading || isLoadingItem}
                        startIcon={<SaveIcon />}
                        endIcon={isLoading || isLoadingItem && <CircularProgress size={24} />}
                      >
                        {params.id ? (isLoading || isLoadingItem ? 'Atualizando checklist de operação...' : 'Atualizar') : (isLoading || isLoadingItem ? 'Salvando checklist de operação...' : 'Salvar')}
                      </Button>
                    </Grid>
                  </CardActions>
                  {(params.id ?
                    <>
                      <Grid item md={12} xs={12} style={{ marginTop: '15px', marginBottom: '15px' }}>
                        <Divider />
                      </Grid>
                      <Typography variant="h4" style={{ marginTop: '5px' }} >
                        <b>{'Adicionar itens'}</b>
                      </Typography>
                      <Grid container xl={12} lg={12} md={12} xs={12} spacing={2}>
                        <Grid item lg={10} md={10} xl={10} xs={12}>
                          <TextValidator
                            className={classes.textField}
                            fullWidth
                            id="descricao"
                            label="Descrição *"
                            inputProps={{
                              maxLength: 100
                            }}
                            onInput={stringUtils.toInputUppercase}
                            margin="normal"
                            onChange={handleChangeItem('descricao')}
                            value={props.checklistOperacaoItem.descricao || ''}
                            variant="outlined"
                          />
                        </Grid>
                        <Grid item lg={2} md={2} xl={2} xs={12}>
                          <TextValidator
                            className={classes.textField}
                            fullWidth
                            id="ordem"
                            label="Ordem *"
                            type="number"
                            onInput={(e) => {
                              e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, 6)
                            }}
                            margin="normal"
                            onChange={handleChangeItem('ordem')}
                            value={props.checklistOperacaoItem.ordem || 0}
                            variant="outlined"
                            validators={['required']}
                            errorMessages={['Campo obrigatório!']}
                          />
                        </Grid>
                      </Grid>
                      <Grid container xl={12} lg={12} md={12} xs={12} spacing={2}>
                        <Grid item lg={3} md={3} xl={3} xs={12} style={{ alignItems: 'center', justifyContent: 'center', alignContent: 'center' }} >
                          <SelectValidator
                            className={classes.textField}
                            variant="outlined"
                            id="idTotvsInsumo"
                            label="Insumo"
                            value={idTotvsInsumo}
                            onChange={handleSelectChangeStateInsumo('idTotvsInsumo')}
                            margin="normal"
                            InputLabelProps={{
                              shrink: true
                            }}
                            fullWidth
                            displayEmpty
                            labelId="idTotvsInsumo"
                          >
                            <MenuItem disabled value="">
                              <em>Insumo *</em>
                            </MenuItem>
                            {!isEmpty(totvsInsumos) &&
                              undefined !== totvsInsumos &&
                              totvsInsumos.length &&
                              totvsInsumos.map((row, index) => (
                                <MenuItem key={index} value={row.value}>
                                  {row.text}
                                </MenuItem>
                              ))}
                          </SelectValidator>
                        </Grid>
                        <Grid item lg={3} md={3} xl={3} xs={12}>
                          <TextValidator
                            className={classes.textField}
                            fullWidth
                            id="diasVencimento"
                            label="Vencimento (dias)"
                            margin="normal"
                            type="number"
                            onChange={handleChangeItem('diasVencimento')}
                            value={props.checklistOperacaoItem.diasVencimento || 0}
                            variant="outlined"
                            inputProps={{
                              min: 0,
                              step: 1
                            }}
                          />
                        </Grid>
                        <Grid item lg={3} md={3} xl={3} xs={12}>
                          <TextValidator
                            className={classes.textField}
                            fullWidth
                            id="coeficienteEstoqueMinimo"
                            label="Coeficiente de Estoque Mínimo"
                            margin="normal"
                            type="number"
                            onChange={handleChangeItem('coeficienteEstoqueMinimo')}
                            value={props.checklistOperacaoItem.coeficienteEstoqueMinimo || 0}
                            variant="outlined"
                            inputProps={{
                              min: 0,
                              step: 0.0001,
                              shrink: true
                            }}
                          />
                        </Grid>
                        <Grid item lg={3} md={3} xl={3} xs={12}>
                          <TextValidator
                            className={classes.textField}
                            fullWidth
                            id="coeficienteEstoqueMaximo"
                            label="Coeficiente de Estoque Máximo"
                            margin="normal"
                            type="number"
                            onChange={handleChangeItem('coeficienteEstoqueMaximo')}
                            value={props.checklistOperacaoItem.coeficienteEstoqueMaximo || 0}
                            variant="outlined"
                            inputProps={{
                              min: 0,
                              step: 0.0001,
                              shrink: true
                            }}
                          />
                        </Grid>
                        <Grid item lg={5} md={5} xl={5} xs={12}>
                          <SelectValidator
                            className={classes.textField}
                            variant="outlined"
                            id="idUnidadeProducao"
                            label="Unidade Produção"
                            value={props.checklistOperacaoItem.idUnidadeProducao || ''}
                            onChange={handleChangeItem('idUnidadeProducao')}
                            margin="normal"
                            InputLabelProps={{
                              shrink: true
                            }}
                            fullWidth
                            displayEmpty
                            labelId="idUnidadeProducao"
                            variant="outlined"
                          >
                            <MenuItem disabled value="">
                              <em>Unidade Produção</em>
                            </MenuItem>
                            {!isEmpty(unidade) &&
                              undefined !== unidade &&
                              unidade.length &&
                              unidade.map((row, index) => (
                                <MenuItem key={index} value={row.value}>
                                  {row.text}
                                </MenuItem>
                              ))}
                          </SelectValidator>
                        </Grid>
                        <Grid item lg={3} md={3} xl={3} xs={11} >
                          <TextValidator
                            className={classes.textField}
                            fullWidth
                            id="pesoLiquido"
                            label="Peso líquido/Volumes (Conversão)"
                            margin="normal"
                            type="number"
                            onChange={handleChangeItem('pesoLiquido')}
                            value={props.checklistOperacaoItem.pesoLiquido || 0}
                            validators={['required']}
                            errorMessages={['Campo obrigatório!']}
                            variant="outlined"
                            inputProps={{
                              min: 0,
                              step: 0.01
                            }}
                          />
                        </Grid>
                        <Grid item lg={1} md={1} xl={1} xs={1} style={{ display: 'flex', alignItems: 'center' }}>
                          <Tooltip
                            title="A cada quantas unidades/gramas/mls vendidas o sistema deve sugerir a produção de uma nova unidade"
                          >
                            <HelpIcon />
                          </Tooltip>
                        </Grid>
                      </Grid>
                      <CardActions>
                        <Grid item lg={12} md={12} xl={12} xs={12}>
                          <Typography style={{ fontSize: "10px", paddingLeft: "6px" }}>
                            * Campos obrigatórios
                          </Typography>
                        </Grid>
                        <Grid item lg={12} md={12} xl={12} xs={12} style={{
                          display: 'flex',
                          alignItems: 'right',
                          flexDirection: 'row',
                          textAlgin: 'right',
                          justifyContent: 'right'
                        }}>
                          <Button
                            color="default"
                            onClick={() => setEditOrdem(!editOrdem)}
                            variant="contained"
                            startIcon={<SortIcon />}
                          >
                            {(editOrdem ? 'Editar valores' : 'Editar ordem')}
                          </Button>
                          <Button
                            color="default"
                            onClick={() => handleCleanItens()}
                            variant="contained"
                          >
                            Limpar
                          </Button>
                          <Button
                            name="btnInsumos"
                            id="btnInsumos"
                            className="btn"
                            variant="contained"
                            disabled={props.checklistOperacaoItem.isLoading}
                            color="primary"
                            component='a'
                            startIcon={<SaveIcon />}
                            endIcon={props.checklistOperacaoItem.isLoading && <CircularProgress size={24} />}
                            onClick={handleSubmitItem}>
                            {props.checklistOperacaoItem.id ? (props.checklistOperacaoItem.isLoading ? 'Atualizando item...' : 'Atualizar item') : (props.checklistOperacaoItem.isLoading ? 'Salvando item...' : 'Salvar item')}
                          </Button>
                        </Grid>
                      </CardActions>
                      <Grid container xl={12} lg={12} md={12} xs={12} spacing={2} style={{ marginTop: 10 }}>

                        {(editOrdem && checklistsOperacaoItens && checklistsOperacaoItens.length > 0 ?
                          //<SortableContainer onSortEnd={onSortEnd} onSortStart={onSortStart}>
                          <SortableContainer onSortEnd={onSortEnd}>
                            {checklistsOperacaoItens.map((n, index) => (
                              <SortableItem key={`item-${n.descricao}`} index={index} value={n} />
                            ))}
                          </SortableContainer>
                          :
                          <TableContainer>
                            <Table>
                              <TableHead>
                                <TableRow>
                                  <TableCell align="center">Ordem</TableCell>
                                  <TableCell>Descrição</TableCell>
                                  <TableCell>Vencimento (dias)</TableCell>
                                  <TableCell>Coeficiente de Estoque Máximo</TableCell>
                                  <TableCell>Coeficiente de Estoque Máximo</TableCell>
                                  <TableCell align="center">Ações</TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {(checklistsOperacaoItens && checklistsOperacaoItens.length > 0 ?
                                  checklistsOperacaoItens.map((n, index) => {
                                    return (
                                      <TableRow hover key={(n.id || 0)}>
                                        <TableCell component="th" scope="row" align="center">
                                          {n.ordem}
                                        </TableCell>
                                        <TableCell component="th" scope="row">
                                          {n.descricao}
                                        </TableCell>
                                        <TableCell component="th" scope="row">
                                          {n.diasVencimento || ''}
                                        </TableCell>
                                        <TableCell component="th" scope="row">
                                          {n.coeficienteEstoqueMinimo || ''}
                                        </TableCell>
                                        <TableCell component="th" scope="row">
                                          {n.coeficienteEstoqueMaximo || ''}
                                        </TableCell>
                                        <TableCell component="th" scope="row" align="center">
                                          <AccessControl
                                            rule={'checklistsOperacoes.edit'} //permissão necessária para acessar conteúdo
                                            yes={() => (
                                              <IconButton
                                                aria-label="Edit"
                                                component="a"
                                                onClick={() => handleEditItem(n)}
                                                size="small"
                                              >
                                                <Tooltip title="Editar">
                                                  <Tooltip >
                                                    <EditIcon />
                                                  </Tooltip>
                                                </Tooltip>
                                              </IconButton>
                                            )}
                                          />
                                          <AccessControl
                                            rule={'checklistsOperacoes.delete'} //permissão necessária para acessar conteúdo
                                            yes={() => (
                                              <IconButton
                                                aria-label="Delete"
                                                onClick={() => handleDeleteChecklistOperacaoItem(n.id)}
                                                size="small"
                                              >
                                                <Tooltip
                                                  title="Excluir"
                                                >
                                                  <DeleteIcon />
                                                </Tooltip>
                                              </IconButton>
                                            )}
                                          />
                                        </TableCell>
                                      </TableRow>
                                    )
                                  })
                                  :
                                  <TableRow>
                                    <TableCell colSpan={3}>
                                      <Typography align="center" variant="subtitle2">
                                        Nenhum item adicionado até o momento
                                      </Typography>
                                    </TableCell>
                                  </TableRow>
                                )}
                              </TableBody>
                            </Table>
                          </TableContainer>
                        )}

                      </Grid>
                    </>
                    : null
                  )}
                </CardContent>
              </ValidatorForm>
            </Card>
          </Grid>
        </Grid>
      </Grid >
    </Page >
  );
}

ChecklistOperacaoEdit.propTypes = {
  classes: PropTypes.object.isRequired,
}
const mapStateToProps = (state) => {
  return state
}

const connectedChecklistOperacaoEditPage = withRouter(
  connect(mapStateToProps, null, null, {
    pure: false
  })(withStyles(styles)(ChecklistOperacaoEdit))
)
export { connectedChecklistOperacaoEditPage as ChecklistOperacaoEdit };
