import React from 'react'
import Typography from '@material-ui/core/Typography'
import {
  Box,
  Button,
  CircularProgress,
  Grid
} from '@material-ui/core'
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import CloseIcon from '@material-ui/icons/Close';
import { AccessControl } from '../../components/Utils/AccessControl'
import DialogActions from '@material-ui/core/DialogActions';
import { StickyContainer, Sticky } from 'react-sticky';

const styles = {
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4
};

export const CompraInfoGtinModal = ({ modal, toggle, gtin, loading }) => {
  return (
    <Dialog
      open={modal}
      onClose={toggle}
      maxWidth='md'
      fullWidth
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
    >
      <DialogTitle>
        <Grid alignItems="flex-end" container justifyContent="space-between">
          <Grid item>
            <Typography style={{ fontSize: "16px", fontWeight: 'bold', textAlign: "left", marginRight: 5 }}>
              INFORMAÇÕES DO GTIN
            </Typography>
          </Grid>
          <AccessControl
            rule={'fornecedores.list'}
            yes={() => (
              <Grid item>
                <Grid item xs={6} align="right">
                  <Button
                    color="default"
                    variant="contained"
                    endIcon={<CloseIcon />}
                    onClick={toggle}
                  >
                    Fechar
                  </Button>
                </Grid>
              </Grid>
            )}
          />
        </Grid>
      </DialogTitle>
      <DialogContent sx={styles}>
        {!!gtin?.gtin && !loading ?
          <Grid direction="column" container justifyContent="space-between" spacing={2}>
            <Grid item stye={{ display: "flex", flexDirection: "row" }}>
              <Typography variant="h5" direction="row">Código:</Typography>
              <Typography style={{ fontWeight: "regular" }}>{gtin.gtin}</Typography>
            </Grid>
            <Grid item>
              <Typography variant="h5">Descrição:
                <Typography style={{ fontWeight: "regular" }}>{gtin.description}</Typography>
              </Typography>
            </Grid>
          </Grid>
          :
          <Box style={{ height: '100px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            {loading ? <CircularProgress /> : <Typography variant="h5">Algo inesperado aconteceu! Por favor, tente novamente mais tarde!</Typography>}
          </Box>
        }
      </DialogContent>
      <DialogActions>
        <StickyContainer>
          <Sticky relative={false} isSticky={true}>
            {({ style }) => (
              <Grid style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-end', justifyContent: "space-between", marginRight: 12 }}>
              </Grid>
            )}
          </Sticky>
        </StickyContainer>
      </DialogActions>
    </Dialog >
  );
}
