import { history } from 'utils'
import { crudService } from '../_services'
import { stringUtils } from 'utils'
import { toast } from 'react-toastify'

export const sorteioAction = {
  getSorteio,
  addSorteio,
  getSorteioSelectLoja,
  getSorteioById,
  getSorteioArquivo,
  onChangeProps,
  editSorteioInfo,
  editSorteiosDetails,
  createSorteio,
  deleteSorteioById,
  clear,
  clearAll
}

let filterModel = {
  Page: 0,
  Limit: 10,
  Term: ''
};

function getSorteio(filterModel, showWaiting) {
  return (dispatch) => {
    let apiEndpoint = 'sorteios' + stringUtils.serialize(filterModel);
    crudService
      .get(apiEndpoint)
      .then((response) => {
        dispatch(changeSorteiosList(response.data.items, response.data.totalRows))
      })
      .catch((err) => {
        console.log(err)
      })
  }
}

function createSorteio(payload) {
  return (dispatch) => {
    dispatch(isLoading())
    let apiEndpoint = 'sorteios/'
    crudService.post(apiEndpoint, payload)
      .then((response) => {
        if (response.request != undefined && response.request.status === 200) {
          var ret = JSON.parse(response.request.responseText);
          dispatch(createSorteioInfo())
          toast.success('Feito! Cadastro realizado com sucesso! 😎')
          dispatch(clear())
          dispatch(notIsLoading())
          history.push('/sorteios')
        } else {
          toast.err('Oops! Erro ao cadastrar sorteio! 😥')
        }
      }).catch((err) => {
       // console.log(err.response);
        dispatch(notIsLoading())
        toast.error('Oops! Erro ao cadastrar sorteio! 😥')
        return err.response;
      })
  }
}

function getSorteioSelectLoja(id) {
  return (dispatch) => {
    let apiEndpoint = 'sorteios/select/loja/' + id
    crudService
      .get(apiEndpoint)
      .then((response) => {
        if (response.data) {
          dispatch(changeSorteiosList(response.data))
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }
}

function getSorteioById(id) {
  return (dispatch) => {
    let apiEndpoint = 'sorteios/' + id
    crudService.get(apiEndpoint).then((response) => {
      dispatch(editSorteiosDetails(response.data))
    })
  }
}

function getSorteioArquivo(id) {
  return (dispatch) => {
    dispatch(isLoading())
    let apiEndpoint = 'sorteios/arquivo/' + id;
    crudService
      .get(apiEndpoint)
      .then((response) => {
        const BOM = '\uFEFF';
        var FileSaver = require('file-saver');
        var blob = new Blob([BOM + response.data], { type: "text/csv;charset=utf-8" });
        FileSaver.saveAs(blob, "sorteio_" + id + ".csv");
        toast.success('Feito! Download do arquivo realizado com sucesso! 😎')
        dispatch(notIsLoading())
      })
      .catch((err) => {
        console.log(err)
      })
  }
}

function onChangeProps(props, event) {
  return (dispatch) => {
    if (event.target.type === 'checkbox') {
      dispatch(handleOnChangeProps(props, event.target.checked))
    } else {
      dispatch(handleOnChangeProps(props, event.target.value))
    }
  }
}

function editSorteioInfo(id, payload, redirect) {
  return (dispatch) => {
    if (redirect) {
      dispatch(isLoading())
    }
    let apiEndpoint = 'sorteios/' + id
    crudService.put(apiEndpoint, payload)
      .then((response) => {
        if (response.request != undefined && response.request.status === 200) {
          var ret = JSON.parse(response.request.responseText);
          dispatch(updatedSorteioInfo())
          if (redirect) {
            dispatch(clear())
            dispatch(notIsLoading())
            history.push('/sorteios')
          }
        }
      }).catch((err) => {
        dispatch(notIsLoading())
        toast.error('Oops! Erro ao atualizar sorteio! 😥')
        //  console.log(err.response);
        return err.response;
      }
  )}
}

function deleteSorteioById(id) {
  return (dispatch) => {
    dispatch(isLoading())
    let apiEndpoint = 'sorteios/' + id
    crudService.deleteDetail(apiEndpoint).then(() => {
      dispatch(deleteSorteiosDetails())
      dispatch(getSorteio(filterModel))
      dispatch(notIsLoading())
      history.push('/sorteios')
    })
  }
}

export function addSorteio(payload) {
  return [{ type: 'ADD_SORTEIO', sorteio: payload }, clear()]
}

export function changeSorteiosList(sorteio, totalRows) {
  return {
    type: 'FETCHED_ALL_SORTEIO',
    sorteio: sorteio,
    totalRegistros: totalRows
  }
}

export function clear() {
  return {
    type: 'SORTEIO_CLEAR'
  }
}

export function clearAll() {
  return {
    type: 'SORTEIO_CLEAR_ALL'
  }
}

export function handleOnChangeProps(props, value) {
  return {
    type: 'SORTEIO_HANDLE_ON_CHANGE',
    props: props,
    value: value
  }
}

export function editSorteiosDetails(sorteio) {
  return [
    { type: 'SORTEIO_DETAIL', sorteio },
  ]
}

export function updatedSorteioInfo() {
  return {
    type: 'SORTEIO_UPDATED'
  }
}

export function createSorteioInfo() {
  return {
    type: 'SORTEIO_CREATED_SUCCESSFULLY'
  }
}

export function deleteSorteiosDetails() {
  return {
    type: 'DELETED_SORTEIO_DETAILS'
  }
}
export function isLoading() {
  return {
    type: 'SORTEIO_ISLOADING'
  }
}

export function notIsLoading() {
  return {
    type: 'SORTEIO_NOTISLOADING'
  }
}
