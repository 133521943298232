const initialState = {
  isLoading: false,
  totalRegistros: 0,
  certificado: [],
  id: 0,
  nome: '',
  dataValidade: '',
  idLoja: 0,
  senha: '',
  importado: false,
  excluido: false,
  loja: null,
  page: 0,
  limit: 10,
  order: 'dataValidade',
  direction: 'asc',
  term: '',
  status: '',
  statusImportacao: ''
}

export function certificado(state = initialState, action) {
  switch (action.type) {
    case 'FETCHED_ALL_CERTIFICADO':
      return {
        ...state,
        certificado: action.certificado,
        totalRegistros: action.totalRegistros
      }
    case 'CERTIFICADO_DETAIL':
      return {
        ...state,
        isLoading: false,
        id: action.certificado.id,
        nome: action.certificado.nome,
        dataValidade: action.certificado.dataValidade,
        idLoja: action.certificado.idLoja,
        senha: action.certificado.senha,
        importado: action.certificado.importado,
        excluido: action.certificado.excluido,
      }
    case 'CERTIFICADO_UPDATED':
      return state
    case 'CERTIFICADO_CLEAR':
      return {
        ...initialState,
        page: state.page,
        limit: state.limit,
        order: state.order,
        direction: state.direction,
        term: state.term,
        status: state.status,
        statusImportacao: state.statusImportacao
      }
    case 'CERTIFICADO_CLEAR_ALL':
      return {
        ...state,
        certificado: []
      }
    case 'CERTIFICADO_HANDLE_ON_CHANGE':
      return {
        ...state,
        [action.props]: action.value
      }
    case 'CERTIFICADO_ISLOADING':
      return {
        ...state,
        isLoading: true
      }
    case 'CERTIFICADO_NOTISLOADING':
      return {
        ...state,
        isLoading: false
      }
    default:
      return state
  }
}
