import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/styles'
import { Typography, CardContent, Grid, } from '@material-ui/core'
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import Avatar from "@material-ui/core/Avatar";

const useStyles = makeStyles((theme) => ({
  root: {
    alignItems: 'center',
    justifyContent: 'center',
    alignContent: 'center',
    marginLeft: 10,
    marginRight: 10,
    [theme.breakpoints.down('md')]: {
      height: 100
    }
  },
  title: {
    fontWeight: 600,
    display: 'flex',
    fontSize: 17,
    color: theme.palette.primary.main,
    [theme.breakpoints.down('md')]: {
      width: '100%',
      flexDirection: 'column',
      fontSize: 16
    }
  },
  subheader: {
    fontWeight: 400,
    fontSize: 16,
    marginTop: 20,
    marginLeft: 10
  },
  avatar: {
    backgroundColor: theme.palette.error.main,
    height: 60,
    width: 60,
    display: 'flex',
    flex: 1,
    [theme.breakpoints.down('sm')]: {
      height: 45,
      width: 45
    }
  },
  item: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1
  }
}));

const CardModule = (props) => {
  const { className, title, subtitle, icon, ...rest } = props

  const classes = useStyles();

  return (
    <Card {...rest} className={clsx(classes.root, className)}>
      <CardActionArea>
        <CardContent>
          <Grid container justifyContent="space-between">
            <Grid item className={classes.item}>
              <Typography
                className={classes.title}
                gutterBottom
                variant="body2"
              >
                {title}
              </Typography>
              <Typography variant="body2">{subtitle}</Typography>
            </Grid>
            <Grid item>
              <Avatar className={classes.avatar}>
                {icon}
              </Avatar>
            </Grid>
          </Grid>
        </CardContent>
      </CardActionArea>
    </Card>
  );
}

CardModule.propTypes = {
  className: PropTypes.string,
  subtitle: PropTypes.string,
  title: PropTypes.string.isRequired,
  icon: PropTypes.element
}

export default CardModule
