const initialState = {
  isLoading: false,
  totalRegistros: 0,
  checklistTotvsInsumoUnidade: [],
  open: false,
  id: 0,
  idChecklist: 0,
  idTotvsInsumoUnidade: 0,
  ordem: 0,
  indisponivel: false,
  excluido: false
}

export function checklistTotvsInsumoUnidade(state = initialState, action) {
  switch (action.type) {
    case 'FETCHED_ALL_CHECKLIST_TOTVS_INSUMO_UNIDADE':
      return {
        ...state,
        checklistTotvsInsumoUnidade: action.checklistTotvsInsumoUnidade,
        totalRegistros: action.totalRegistros
      }    
    case 'CHECKLIST_TOTVS_INSUMO_UNIDADE_CLEAR':
      return initialState
    case 'CHECKLIST_TOTVS_INSUMO_UNIDADE_CLEAR_ALL':
      return {
        ...state,
        checklistTotvsInsumoUnidade: []
      }
    case 'CHECKLIST_TOTVS_INSUMO_UNIDADE_HANDLE_ON_CHANGE':
      return {
        ...state,
        [action.props]: action.value
      }
    case 'CHECKLIST_TOTVS_INSUMO_UNIDADE_ISLOADING':
      return {
        ...state,
        isLoading: true
      }
    case 'CHECKLIST_TOTVS_INSUMO_UNIDADE_NOTISLOADING':
      return {
        ...state,
        isLoading: false
      }
    default:
      return state
  }
}
