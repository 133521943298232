import { cnpjMask } from '../utils'
import { crudService } from '../_services'

export const fornecedorInfoAction = {
  getInfoModal,
  clear
}

function getInfoModal(cnpj) {
  return (dispatch) => {
    let apiEndpoint = 'FornecedoresInfos/' + cnpj
    dispatch(isLoading())
    crudService
      .get(apiEndpoint)
      .then((response) => {
        if (response?.data) {
          dispatch(changeFornecedorInfo(response.data))
        } else {
          dispatch(clear())
        }
        dispatch(notIsLoading())
      })
      .catch((err) => {
        console.log(err)
        dispatch(notIsLoading())
      })
  }
}

export function changeFornecedorInfo(fornecedorInfo) {
  return {
    type: 'FETCHED_FORNECEDOR_INFO',
    fornecedorInfo: fornecedorInfo
  }
}

export function clear() {
  return {
    type: 'FORNECEDOR_INFO_CLEAR'
  }
}
export function isLoading() {
  return {
    type: 'FORNECEDOR_INFO_ISLOADING'
  }
}

export function notIsLoading() {
  return {
    type: 'FORNECEDOR_INFO_NOTISLOADING'
  }
}
