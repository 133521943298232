import React, { } from 'react';
import GoogleMapReact from 'google-map-react';
import { Box } from '@material-ui/core'

const HeatMapPrecoMedio = ({ data }) => {

  return (
    <Box style={{ display: 'flex', width: '100%', height: '100%' }}>
      <GoogleMapReact
        bootstrapURLKeys={{
          key: ['AIzaSyAQCdFXjUZcYlFI6pcW9yPs0MJTQtrqhaM'],
          libraries: ['visualization']
        }}
        zoom={5}
        center={{ lat: data[0]?.lat, lng: data[0]?.lng }}
        heatmapLibrary={true}
        heatmap={{
          positions: data,
          options: {
            radius: 50
          }
        }}
        yesIWantToUseGoogleMapApiInternals
      />
    </Box>
  )
}

export default HeatMapPrecoMedio
