import React, { useState } from 'react'
import { Grid, Typography, Button } from '@material-ui/core'
import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'
import { makeStyles } from '@material-ui/styles'
import { campanhaAction } from '../_actions'
import clsx from 'clsx'
import { useDebounce } from 'use-debounce';
import { AccessControl } from '../components/Utils/AccessControl'

const useStyles = makeStyles(() => ({
  root: {}
}))

const TitleContentParticipacao = (props) => {
  const { className, ...rest } = props
  const classes = useStyles()
  const dispatch = useDispatch()
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [term, setTerm] = useState('');
  const [order] = useState('nome');
  const [direction] = useState('asc');
  const [debounceTerm] = useDebounce(term, 1000);

  const handleExportar = () => {
    let filterModel;

    const fetchData = (page, limit, term) => {

      filterModel = {
        Page: page,
        Limit: limit,
        Term: term,
        Order: order,
        Direction: direction,
      };

      dispatch(campanhaAction.getCampanhaArquivo(props.idCampanha, true));
    };

    fetchData(page, limit, debounceTerm, order, direction);

    dispatch(campanhaAction.getCampanha(filterModel, true));
  }

  return (
    <div {...rest} className={clsx(classes.root, className)}>
      <Grid alignItems="flex-end" container justifyContent="space-between" spacing={3}>
        <Grid item>
          <Typography component="h2" gutterBottom variant="overline">
            {props.subTitle}
          </Typography>
          <Typography component="h1" variant="h3">
            {props.title}
          </Typography>
        </Grid>
        <AccessControl
          rule={props.rule} //permissão necessária para acessar conteúdo
          yes={() => (
            <Grid item>
              <Grid item style={{
                display: 'flex',
                alignItems: 'right',
                flexDirection: 'row',
                textAlgin: 'right',
                justifyContent: 'right',
                spacing: 5
              }}>
                <Button
                  variant="contained"
                  color="default"
                  onClick={() => handleExportar()}
                >
                  EXPORTAR
                </Button>
              </Grid>
            </Grid>
          )}
        />
      </Grid>
    </div>
  )
}

TitleContentParticipacao.propTypes = {
  title: PropTypes.string.isRequired,
  subTitle: PropTypes.string,
  rule: PropTypes.string
}

export default TitleContentParticipacao
