const initialState = {
  anchor: 'left',
  estado: [],
  open: false,
  id: '',
  nome: '',
  uf: '',
  ibge: '',
  paisId: '',
  ddd: '',
  pais: []
}

export function estado(state = initialState, action) {
  switch (action.type) {
    case 'FETCHED_ALL_ESTADO':
      return {
        ...state,
        estado: action.estado
      }
    case 'ESTADO_DETAIL':
      return {
        ...state,
        id: action.id,
        nome: action.nome,
        uf: action.uf,
        ibge: action.ibge,
        paisId: action.paisId,
        ddd: action.ddd,
        pais: action.pais
      }
    case 'ESTADO_UPDATED':
      return state
    case 'HANDLE_ON_CHANGE':
      return {
        ...state,
        [action.props]: action.value
      }
    default:
      return state
  }
}
