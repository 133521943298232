import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { v1 as uuid } from 'uuid'
import { makeStyles } from '@material-ui/styles'
import {
  Card,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  Typography,
  Tooltip
} from '@material-ui/core'
import ArrowForwardIcon from '@material-ui/icons/ArrowForwardOutlined'
import PaymentIcon from '@material-ui/icons/PaymentOutlined'
import NotificationsActiveOutlinedIcon from '@material-ui/icons/NotificationsActiveOutlined'

const useStyles = makeStyles((theme) => ({
  root: {},
  value: {
    fontWeight: theme.typography.fontWeightMedium
  },
  type: {
    fontWeight: theme.typography.fontWeightMedium
  }
}))

const Summary = (props) => {
  const { className, ...rest } = props

  const classes = useStyles()
  const summary = [
    {
      id: uuid(),
      value: 2,
      type: 'ações',
      message: 'que precisam de sua atenção'
    },
    {
      id: uuid(),
      value: 3,
      type: 'pagamentos',
      message: 'que precisam de sua confirmação'
    }
  ]

  const icons = {
    ações: <NotificationsActiveOutlinedIcon />,
    pagamentos: <PaymentIcon />
  }

  return (
    <Card {...rest} className={clsx(classes.root, className)}>
      <List>
        {summary.map((notification, i) => (
          <ListItem divider={i < summary.length - 1} key={notification.id}>
            <ListItemIcon>{icons[notification.type]}</ListItemIcon>
            <ListItemText>
              <Typography variant="body1">
                <span className={classes.value}>{notification.value}</span>{' '}
                <span className={classes.type}>{notification.type}</span>{' '}
                {notification.message}
              </Typography>
            </ListItemText>
            <ListItemSecondaryAction>
              <Tooltip title="Ver">
                <IconButton edge="end" size="small">
                  <ArrowForwardIcon />
                </IconButton>
              </Tooltip>
            </ListItemSecondaryAction>
          </ListItem>
        ))}
      </List>
    </Card>
  )
}

Summary.propTypes = {
  className: PropTypes.string
}

export default Summary
