import React, { useState } from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/styles'
import {
  Box,
  Card,
  CardContent,
  CardActionArea,
  Checkbox,
  CircularProgress,
  Grid,
  IconButton,
  Tooltip,
  Typography,
} from '@material-ui/core'
import EditIcon from '@material-ui/icons/Edit'
import PrintIcon from '@material-ui/icons/Print'
import { history } from '../../../../utils'
import moment from 'moment'

const useStyles = makeStyles((theme) => ({
  root: {
    alignItems: 'center',
    justifyContent: 'center',
    marginLeft: 10,
    marginRight: 10
  },
  title: {
    fontWeight: 600,
    fontSize: 17,
    color: theme.palette.primary.main
  },
  subheader: {
    fontWeight: 400,
    flex: 1,
    fontSize: 16,
    marginTop: 20,
    marginLeft: 10
  },
  avatar: {
    backgroundColor: theme.palette.error.main,
    height: 60,
    width: 60
  }
}));

const CardChecklist = (props) => {
  const { className, name, id, type, progresso, printGroup, handleCheck, handleClear, isChecked, ...rest } = props
  const classes = useStyles();

  const [checked, setChecked] = useState(isChecked)

  const handleEdit = (id) => {
    if (id != undefined) {
      history.push(`/respostaChecklistOperacao/checklistOperacao/${id}`)
    }
  }

  const handlePrint = (id) => {

    var dataInicio = moment().startOf('week').add('days', 1).format('YYYY-MM-DD');
    var dataFim = moment().endOf('week').add('days', 1).format('YYYY-MM-DD');

    window.open(`/relatorioCheckListOperacao/${id}/${dataInicio}/${dataFim}`, '_blank');
  }

  const handleChangeCheck = () => {
    const currentCheck = !checked

    setChecked(currentCheck)
    handleCheck(currentCheck, id)
  }

  return (
    <Card {...rest} className={clsx(classes.root, className)}>
      <CardActionArea>
        <CardContent>
          <Grid container justifyContent="space-between">
            <Grid item lg={10} sm={10} xl={10} xs={10}>
              <Typography
                className={classes.title}
                gutterBottom
                variant="body2"
              >
                {name}
              </Typography>
              <Typography variant="body2">{type}</Typography>
            </Grid>
            <Grid item lg={2} sm={2} xl={2} xs={2}>
              <Box position="relative" display="inline-flex">
                <CircularProgress variant="determinate" value={progresso} style={{ 'color': (progresso >= 100 ? '#000' : '#BF1E21') }} />
                <Box
                  top={0}
                  left={0}
                  bottom={0}
                  right={0}
                  position="absolute"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"

                >
                  <Typography variant="caption" component="div" color="textSecondary">{`${Math.round(
                    progresso,
                  )}%`}</Typography>
                </Box>
              </Box>
            </Grid>
            <Grid item lg={12} sm={12} xl={12} xs={12} style={{
              display: 'flex',
              alignItems: 'right',
              flexDirection: 'row',
              textAlgin: 'right',
              justifyContent: 'right'
            }}>
              <Box position="relative" display="inline-flex">
                <IconButton
                  aria-label="Edit"
                  component="a"
                  onClick={() => handleEdit(id)}
                  size="small"
                >
                  <Tooltip title="Editar">
                    <Tooltip >
                      <EditIcon />
                    </Tooltip>
                  </Tooltip>
                </IconButton>
                {!printGroup ?
                  <IconButton
                    aria-label="Edit"
                    component="a"
                    onClick={() => handlePrint(id)}
                    size="small"
                  >
                    <Tooltip title="Imprimir">
                      <Tooltip >
                        <PrintIcon />
                      </Tooltip>
                    </Tooltip>
                  </IconButton>
                  :
                  <Checkbox
                    checked={checked}
                    onChange={handleChangeCheck}
                    size="small"
                  />
                }
              </Box>
            </Grid>
          </Grid>
        </CardContent>
      </CardActionArea>
    </Card>
  );
}

CardChecklist.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string,
  type: PropTypes.string,
  remove: PropTypes.element
}

export default CardChecklist
