import React from 'react'
// import { makeStyles } from '@material-ui/styles';
import { Grid, Typography, Button } from '@material-ui/core'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/styles'
import { history } from '../utils'
import clsx from 'clsx'
import { AccessControl } from '../components/Utils/AccessControl'

const useStyles = makeStyles(() => ({
  root: {}
}))

const TitleContentInformation = (props) => {
  const { className, ...rest } = props
  const classes = useStyles()
  return (
    <div {...rest} className={clsx(classes.root, className)}>
      <Grid alignItems="flex-end" container justifyContent="space-between" spacing={3}>
        <Grid item>
          <Typography component="h2" gutterBottom variant="overline">
            {props.subTitle}
          </Typography>
          <Typography component="h1" variant="h3">
            {props.title}
          </Typography>
        </Grid>
      </Grid>
    </div>
  )
}

TitleContentInformation.propTypes = {
  title: PropTypes.string.isRequired,
  subTitle: PropTypes.string,
  buttonOnClick: PropTypes.object.isRequired,
  rule: PropTypes.string
}
export default TitleContentInformation
