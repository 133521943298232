import axios from 'axios'
import { errorHandler } from './errorHandler'
import config from '../config/config'
import Resizer from "react-image-file-resizer";

export async function uploadImage(payload) {
  const data = new FormData()
  var compress_image = await resizeImage(payload.file)
  data.append('file', compress_image, payload.name)
  data.append('doEncrypt', false)

  return sendData(data)
}

export async function uploadFile(file, folderName, onlyAccepts) {
  const data = new FormData()
  data.append('file', file, file.name)
  data.append('objectName', folderName)
  data.append('doEncrypt', true)
  data.append('onlyAccepts', onlyAccepts ?? '')

  return sendData(data)
}

async function sendData(data) {
  return axios
    .post(config.baseUrl + 'uploads/S3', data, getOptions())
    .then((response) => {
      return response.data.url
    })
    .catch((error) => {
      console.log('error', error)
      return errorHandler(error)
    })
}

const resizeImage = (file) =>
  new Promise((resolve) => {
    Resizer.imageFileResizer(
      file,
      1000,
      1000,
      "JPEG",
      97,
      0,
      (uri) => {
        resolve(uri);
      },
      "blob"
    );
  }); 

function getOptions() {
  let options = {}
  if (localStorage.getItem('token')) {
    options.headers = {
      Authorization: 'Bearer ' + localStorage.getItem('token')
    }
  }
  return options
}
