import { history } from 'utils'
import { crudService } from '../_services'
import { stringUtils } from 'utils'
import { toast } from 'react-toastify'
import { authActions } from '../_actions/'

export const checklistAction = {
  getChecklist,
  addChecklist,
  getChecklistById,
  onChangeProps,
  editChecklistInfo,
  editChecklistsDetails,
  duplicateChecklist,
  createChecklist,
  deleteChecklistById,
  clear,
  clearAll,
  clearChecklistsLojas,
  clearChecklistsTotvsInsumosUnidades
}

let filterModel = {
  Page: 0,
  Limit: 10,
  Term: '',
  Order: 'titulo',
  Direction: 'asc'
};

function getChecklist(filterModel, showWaiting) {
  return (dispatch) => {
    let apiEndpoint = 'checklists' + stringUtils.serialize(filterModel);
    crudService
      .get(apiEndpoint)
      .then((response) => {
        dispatch(changeChecklistsList(response.data.items, response.data.totalRows))
      })
      .catch((err) => {
        console.log(err.response);
        return err.response;
      })
  }
}

function createChecklist(payload, props) {
  return (dispatch) => {
    dispatch(isLoading())
    let apiEndpoint = 'checklists/'
    crudService.post(apiEndpoint, payload)
      .then((response) => {
        if (response.request != undefined && response.request.status === 200) {
          //var ret = JSON.parse(response.request.responseText);
          dispatch(createChecklistInfo())
          toast.success('Feito! Cadastro realizado com sucesso! 😎')
          dispatch(clear())
          dispatch(notIsLoading())
          history.push('/checklists') // retorna para a lista e atualiza
        } else {
          toast.err('Oops! Erro ao cadastrar planilha de contagem! 😥')
        }
      }).catch((err) => {
        //  console.log(err.response);
        dispatch(notIsLoading())
        toast.error('Oops! Erro ao cadastrar planilha de contagem! 😥')
        return err.response;
      })
  }
}

function duplicateChecklist(id, redirect) {
  return (dispatch) => {
    let apiEndpoint = 'checklists/duplicar/' + id
    crudService.post(apiEndpoint).then((response) => {
      dispatch(createChecklistInfo())
      toast.success('Feito! Planilha de contagem duplicada com sucesso! 😎')
      dispatch(clear())
      dispatch(notIsLoading())
      if (redirect) {
        var ret = JSON.parse(response.request.responseText);
        history.push('/checklist/' + ret.key) // retorna para a lista e atualiza
      }
    })
  }
}


function getChecklistById(id) {
  return (dispatch) => {
    let apiEndpoint = 'checklists/' + id
    crudService.get(apiEndpoint).then((response) => {
      dispatch(editChecklistsDetails(response.data))
    })
  }
}

function onChangeProps(props, event) {
  return (dispatch) => {
    if (props === 'checklistsTotvsInsumosUnidades') {
      dispatch(handleOnChangeProps(props, event))
    } else {
      if (event.target.type === 'checkbox') {
        dispatch(handleOnChangeProps(props, event.target.checked))
      } else {
        dispatch(handleOnChangeProps(props, event.target.value))
      }
    }
  }
}

function editChecklistInfo(id, payload, redirect) {
  return (dispatch) => {
    dispatch(isLoading())
    let apiEndpoint = 'checklists/' + id
    crudService.put(apiEndpoint, payload)
      .then((response) => {
        if (response.request != undefined && response.request.status === 200) {
          var ret = JSON.parse(response.request.responseText);
          dispatch(updatedChecklistInfo())
          dispatch(clear())
         dispatch(notIsLoading())
          history.push('/checklists')
        }// retorna para a lista e atualiza        }
      }).catch((err) => {
        //  console.log(err.response);
        dispatch(notIsLoading())
        toast.error('Oops! Erro ao atualizar planilha de contagem! 😥')
        return err.response;
      })
  }
}

function deleteChecklistById(id) {
  return (dispatch) => {
    dispatch(isLoading())
    let apiEndpoint = 'checklists/' + id
    crudService.deleteDetail(apiEndpoint).then(() => {
      dispatch(deleteChecklistsDetails())
      dispatch(getChecklist(filterModel))
      dispatch(notIsLoading())
    })
  }
}

export function addChecklist(payload, props) {
  return [{ type: 'ADD_CHECKLIST', checklist: payload },
  clear()
  ]
}

export function changeChecklistsList(checklist, totalRows) {
  return {
    type: 'FETCHED_ALL_CHECKLIST',
    checklist: checklist,
    totalRegistros: totalRows
  }
}

export function clear() {
  return {
    type: 'CHECKLIST_CLEAR'
  }
}

export function clearAll() {
  return {
    type: 'CHECKLIST_CLEAR_ALL'
  }
}

export function clearChecklistsLojas() {
  return {
    type: 'CHECKLIST_CLEAR_LOJAS'
  }
}

export function clearChecklistsTotvsInsumosUnidades() {
  return {
    type: 'CHECKLIST_CLEAR_TOTVS_INSUMOS_UNIDADES'
  }
}

export function handleOnChangeProps(props, value) {
  return {
    type: 'CHECKLIST_HANDLE_ON_CHANGE',
    props: props,
    value: value
  }
}

export function editChecklistsDetails(checklist) {
  return {
    type: 'CHECKLIST_DETAIL',
    id: checklist.id,
    descricao: checklist.descricao,
    idCategoriaInsumo: checklist.idCategoriaInsumo,
    tipo: checklist.tipo,
    checklistsLojas: checklist.checklistsLojas,
    checklistsTotvsInsumosUnidades: checklist.checklistsTotvsInsumosUnidades,
    excluido: checklist.excluido,
  }
}

export function updatedChecklistInfo() {
  return {
    type: 'CHECKLIST_UPDATED'
  }
}

export function createChecklistInfo() {
  return {
    type: 'CHECKLIST_CREATED_SUCCESSFULLY'
  }
}

export function deleteChecklistsDetails() {
  return {
    type: 'DELETED_CHECKLIST_DETAILS'
  }
}

export function isLoading() {
  return {
    type: 'CHECKLIST_ISLOADING'
  }
}

export function notIsLoading() {
  return {
    type: 'CHECKLIST_NOTISLOADING'
  }
}
