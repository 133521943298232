import React, { useEffect, useState } from 'react'
import { connect, useDispatch, useSelector } from 'react-redux'
import { categoriaInsumoAction } from '../../_actions'
import PropTypes from 'prop-types'
import {
  withStyles,
  Typography,
  Grid
} from '@material-ui/core'
import { Skeleton } from "@material-ui/lab";
import clsx from 'clsx'
import { withRouter } from 'react-router-dom'
import { history } from '../../utils'
import { useConfirm } from 'material-ui-confirm'
import { AccessControl } from '../../components/Utils/AccessControl'
import AccessDenied from '../Errors/AccessDenied'
import { Page } from 'components'
import { useDebounce } from 'use-debounce'
import {
  CardChecklistCategoriaInsumo
} from './components'
import moment from 'moment'
import { toast } from 'react-toastify'

const styles = (theme) => ({
  root: {
    padding: theme.spacing(3, 2, 2, 2)
  },
  gridCards: {
    justifyContent: 'center',
    flexDirection: 'row',
    padding: theme.spacing(2),
    marginTop: theme.spacing(2)
  },
  card: {
    marginBottom: theme.spacing(2),
    "&:hover": {
      background: "#efefef",
      transition: '1s'
    },
    minHeight: 130
  }
})

const ChecklistOperacaoCategoriasInsumosCards = (props) => {

  const confirm = useConfirm()
  const dispatch = useDispatch()
  const { classes } = props
  const { match: { params } } = props;
  //const { totalRegistros } = props.categoriaInsumo

  const { categoriaInsumo, isLoading } = useSelector(
    (state) => state.categoriaInsumo
  )

  const { className, ...rest } = props

  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [term, setTerm] = useState('');
  const [debounceTerm] = useDebounce(term, 1000);
  const [order] = useState('nome');
  const [direction] = useState('asc');
  const [ready, setReady] = React.useState(false);

  const { idLoja } = useSelector(
    (state) => state.usuarioLoja
  )
  useEffect(() => {

    dispatch(categoriaInsumoAction.getCategoriaInsumoSelectChecklistOperacao())

  }, []);

  //useEffect(() => {

  //}, [totalRegistros]);

  useEffect(() => {

  }, [ready, isLoading])

  const handleEdit = (id) => {
    if (id != undefined) {
      history.push(`/checklistsOperacoesCards/categoria/${id}`)
    } else {
      toast.error('Informe uma categoria!')
    }
  }

  return (
    <Page className={classes.root} title="Checklists de Operação">
      <AccessControl
        rule={'checklistsOperacoes'} //permissão necessária para acessar conteúdo
        yes={() => (
          <div {...rest} className={clsx(classes.root, className)}>

            <div {...rest} className={clsx(classes.root, className)}>
              <Grid alignItems="flex-end" container justifyContent="space-between" spacing={3}>
                <Grid item>
                  <Typography component="h2" gutterBottom variant="overline">
                    GERÊNCIA DE CHECKLIST DE OPERAÇÃO
                  </Typography>
                  <Typography component="h1" variant="h3">
                    Selecione uma Categoria de Checklists de Operação
                  </Typography>
                </Grid>
              </Grid>
              <br></br>
              {/*<Typography variant="body2" align="left" gutterBottom>*/}
              {/*  <b>{'Confira o andamento dos Checklists de Operação para o dia de hoje: ' + moment().format("DD/MM/YYYY")}</b>*/}
              {/*</Typography>*/}
            </div>

            <Grid alignItems="center" container justifyContent="space-between" spacing={3}>
              <Grid container className={classes.gridCards} spacing={2}>
                {!isLoading ?
                  undefined !== categoriaInsumo && categoriaInsumo.length
                    ? categoriaInsumo.map((n) => {
                      return (
                        <Grid container lg={4} sm={4} xl={3} xs={6} spacing={2} key={n.id}>
                          <Grid item lg={12} sm={12} xl={12} xs={12}>
                            <CardChecklistCategoriaInsumo
                              variant="elevation"
                              className={classes.card}
                              onClick={() => handleEdit(n.value)}
                              name={<Typography component="h1" gutterBottom variant="h4">
                                {(n.text != undefined ? n.text : '')}
                              </Typography>}
                            />
                          </Grid>
                        </Grid>
                      )
                    })
                    : null
                  :
                  Array.from(new Array(3)).map((_, index) => (
                    <Grid item lg={4} sm={4} xl={4} xs={12}>
                      <Skeleton className={classes.card} animation="wave" height={200} marginTop={-140} />
                    </Grid>
                  ))
                }
              </Grid>
            </Grid>
          </div>
        )
        }
        no={() => <AccessDenied />}
      />
    </Page >
  )
}
ChecklistOperacaoCategoriasInsumosCards.propTypes = {
  classes: PropTypes.object.isRequired
}
const mapStateToProps = (state) => {
  return {
    checklistOperacao: state.checklistOperacao
  }
}
const connectedChecklistOperacaoCategoriasInsumosCardsPage = withRouter(
  connect(mapStateToProps, null, null, {
    pure: false
  })(withStyles(styles)(ChecklistOperacaoCategoriasInsumosCards))
)
export { connectedChecklistOperacaoCategoriasInsumosCardsPage as ChecklistOperacaoCategoriasInsumosCards }
