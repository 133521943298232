import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import { Bar } from 'react-chartjs-2'
import { makeStyles } from '@material-ui/styles'
import {
  Card,
  CardHeader,
  CardContent,
  Divider,
} from '@material-ui/core'
import { options } from './options'
import palette from 'theme/palette'
import { useSelector } from 'react-redux'
import { dashboardAction } from '../../../../_actions'
import { Skeleton } from '@material-ui/lab'
import moment from 'moment'

const useStyles = makeStyles(() => ({
  root: {},
  chartContainer: {
    height: 400,
    position: 'relative'
  },
  actions: {
    justifyContent: 'flex-end'
  }
}))

const GraficoClientesFaixaEtariaChartCard = (props) => {

  const dispatch = useDispatch()

  const { className, idLoja, dataInicio, dataFim, ...rest } = props
  const graficoClientesFaixaEtaria = useSelector((state) => (state.dashboard ? (state.dashboard.dashboardLoja ? state.dashboard.dashboardLoja.graficoClientesFaixaEtaria : undefined) : undefined))
  const { isLoading } = useSelector((state) => state.dashboard)
  const classes = useStyles()

  useEffect(() => {
    let filterModel = {
      IdLoja: idLoja,
      DataInicio: moment(dataInicio).format("YYYY-MM-DD"),
      DataFim: moment(dataFim).format("YYYY-MM-DD")
    };

    dispatch(dashboardAction.getDashboardLojaClientesSexo(filterModel, true));


  }, [dataInicio, dataFim, idLoja]);

  const generateData = () => {
    if (graficoClientesFaixaEtaria) {
      const data = {
        labels: graficoClientesFaixaEtaria.options,
        datasets: [
          {
            label: 'Clientes cadastrados',
            yAxisID: 'A',
            backgroundColor: '#2196F3',
            data: graficoClientesFaixaEtaria.series[0]
          }
        ]
      }
      return data
    } else {
      return []
    }
  }

  return (
    <Card {...rest} className={clsx(classes.root, className)}>
      <CardHeader
        title="CLIENTES CADASTRADOS POR FAIXA ETÁRIA"
      />
      <Divider />
      <CardContent className={classes.chartContainer}>
        {isLoading ? (
          <Skeleton animation="wave" style={{ marginTop: -140 }} height={600} />
        ) : (
          <Bar data={generateData} options={options} />
        )}
      </CardContent>
      <Divider />
    </Card>
  )
}

GraficoClientesFaixaEtariaChartCard.propTypes = {
  className: PropTypes.string
}

export default GraficoClientesFaixaEtariaChartCard
