const initialState = {
  isLoading: false,
  totalRegistros: 0,
  subcategoriaCompra: [],
  id: 0,
  idCategoriaCompra: 0,
  nome: '',
  excluido: false,
  loja: null,
  page: 0,
  limit: 10,
  order: 'nome',
  direction: 'asc',
  term: ''
}

export function subcategoriaCompra(state = initialState, action) {
  switch (action.type) {
    case 'FETCHED_ALL_SUBCATEGORIACOMPRA':
      return {
        ...state,
        subcategoriaCompra: action.subcategoriaCompra,
        totalRegistros: action.totalRegistros
      }
    case 'SUBCATEGORIACOMPRA_DETAIL':
      return {
        ...state,
        isLoading: false,
        id: action.subcategoriaCompra.id,
        idCategoriaCompra: action.subcategoriaCompra.idCategoriaCompra,
        nome: action.subcategoriaCompra.nome,
        excluido: action.subcategoriaCompra.excluido,
      }
    case 'SUBCATEGORIACOMPRA_UPDATED':
      return state
    case 'SUBCATEGORIACOMPRA_CLEAR':
      return {
        ...initialState,
        page: state.page,
        limit: state.limit,
        order: state.order,
        direction: state.direction,
        term: state.term,
      }
    case 'SUBCATEGORIACOMPRA_CLEAR_ALL':
      return {
        ...state,
        subcategoriaCompra: []
      }
    case 'SUBCATEGORIACOMPRA_HANDLE_ON_CHANGE':
      return {
        ...state,
        [action.props]: action.value
      }
    case 'SUBCATEGORIACOMPRA_ISLOADING':
      return {
        ...state,
        isLoading: true
      }
    case 'SUBCATEGORIACOMPRA_NOTISLOADING':
      return {
        ...state,
        isLoading: false
      }
    default:
      return state
  }
}
