import React, { useEffect, useState } from 'react'
import { connect, useDispatch, useSelector } from 'react-redux'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Divider,
  Button,
  Grid,
  Backdrop,
  CircularProgress,
  Tooltip,
  Switch,
  FormControlLabel,
  ButtonGroup,
  ClickAwayListener,
  Grow,
  Paper,
  Popper,
  MenuItem,
  MenuList
} from '@material-ui/core'
import {
  ValidatorForm,
  TextValidator
} from 'react-material-ui-form-validator'
import { produtoAction } from '../../_actions'
import { withRouter } from 'react-router-dom'
import { history, stringUtils } from '../../utils'
import { Page, ImageUploader } from 'components'
import SaveIcon from '@material-ui/icons/Save';
import { useConfirm } from 'material-ui-confirm'
import { uploadImage } from '_services'
import { errorsUploadImage } from 'utils'
import getInitials from 'utils/getInitials'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';

const styles = (theme) => ({
  root: {
    padding: theme.spacing(2)
  },
  content: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    textAlgin: 'center'
  },
  nome: {
    marginTop: theme.spacing(1)
  },
  uploadButton: {
    marginRight: theme.spacing(2),
    justifyContent: 'space-between'
  },
  avatar: {
    height: 200,
    width: 200,
    borderRadius: '10px',
    webkitBorderRadius: '10px',
    mozBorderRadius: '10px',
    cursor: 'pointer'
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff'
  }
})

const ProdutoEdit = (props) => {

  const dispatch = useDispatch()
  const { classes, produto, className, ...rest } = props
  const { match: { params } } = props;
  const formRef = React.createRef(null);

  const [images, setImages] = useState([])
  const [open, setOpen] = useState(false)
  const confirm = useConfirm()
  const [imagem, setImagem] = useState('')

  const { idLoja } = useSelector(
    (state) => state.usuarioLoja
  )

  const [openButton, setOpenButton] = React.useState(false);
  const anchorRef = React.useRef(null);

  const options = ['Atualizar Todos', 'Por Nome', 'Por Código'];

  useEffect(() => {

    if (params.id) {
      dispatch(produtoAction.getProdutoById(params.id))
    } else {
      dispatch(produtoAction.clear())
      setImagem(null)
    }

  }, []);

  useEffect(() => {

    let filterModel = {
      IdLoja: (idLoja && idLoja !== '' ? idLoja : 0)
    };

    dispatch(produtoAction.getProduto(filterModel, true));

  }, []);

  const removeImage = () => {
    confirm({
      title: 'Deseja remover a imagem?',
      description: '',
      confirmationText: 'Sim, quero remover!',
      cancellationText: 'Não'
    }).then(() => {
      let payload = {
        ...produto,
        caminhoImagem: null
      }
      dispatch(
        produtoAction.editProdutoInfo(produto.id, payload, false)
      )
      var event2 = {
        type: 'select',
        target: {
          value: null
        }
      };
      dispatch(produtoAction.onChangeProps('caminhoImagem', event2))
      setImagem(null)
    })
  }

  const onChangeImage = (imageList) => {
    // data for submit
    setOpen(true)
    setImages(imageList)
    uploadImage(imageList[0])
      .then((response) => {

        let payload = {
          ...produto,
          caminhoImagem: response.toString()
        }

        if (produto.id !== 0) {
          dispatch(
            produtoAction.editProdutoInfo(produto.id, payload, false)
          )
        }

        setTimeout(function () {

          var event2 = {
            type: 'select',
            target: {
              value: response.toString()
            }
          };
          dispatch(produtoAction.onChangeProps('caminhoImagem', event2))

          setImagem(response)
          setOpen(false)

        }, 1000);

      })
      .catch((err) => {
        console.log(err)
        setOpen(false)
      })
  }

  const handleChange = (prop) => (event) => {
    dispatch(produtoAction.onChangeProps(prop, event))
  }

  useEffect(() => {
    setImagem(produto.caminhoImagem)

  }, [produto.caminhoImagem, open]);


  const handleSubmit = (event, porIdProdutoReferencia) => {

    let payload = {
      id: params.id,
      nome: props.produto.nome,
      nomeOriginal: props.produto.nome,
      descricao: props.produto.descricao,
      idProdutoReferencia: props.produto.idProdutoReferencia,
      trocavel: props.produto.trocavel,
      novidade: props.produto.novidade,
      caminhoImagem: props.produto.caminhoImagem,
      pontos: (props.produto.trocavel ? props.produto.pontos : null),
      fatorPontuacao: (props.produto.fatorPontuacao != 0 ? props.produto.fatorPontuacao : null),
      preco: (props.produto.preco != 0 ? props.produto.preco : null),
      limiteTrocaClientes: (props.produto.limiteTrocaClientes != 0 ? props.produto.limiteTrocaClientes : null),
      idLoja: idLoja
    }

    if (params.id) {
      dispatch(produtoAction.editProdutoInfo(params.id, payload, true))
    } else {
      dispatch(produtoAction.createProduto(payload))
    }

  };

  const handleSubmitTodos = (event, porIdProdutoReferencia) => {

    let payload = {
      id: params.id,
      nome: props.produto.nome,
      descricao: props.produto.descricao,
      idProdutoReferencia: props.produto.idProdutoReferencia,
      trocavel: props.produto.trocavel,
      novidade: props.produto.novidade,
      caminhoImagem: props.produto.caminhoImagem,
      pontos: (props.produto.trocavel ? props.produto.pontos : null),
      fatorPontuacao: (props.produto.fatorPontuacao != 0 ? props.produto.fatorPontuacao : null),
      preco: (props.produto.preco != 0 ? props.produto.preco : null),
      limiteTrocaClientes: (props.produto.limiteTrocaClientes != 0 ? props.produto.limiteTrocaClientes : null),
      porIdProdutoReferencia: porIdProdutoReferencia,
      idLoja: idLoja
    }

    if (params.id) {
      dispatch(produtoAction.editProdutoInfoTodos(params.id, payload, true))
    }

  };

  const handleMenuItemClick = (event, index) => {

    setOpenButton(false);

    if (index == 1) {
      handleSubmitTodos(event, false);
    }
    else if (index == 2) {
      handleSubmitTodos(event, true);
    }

  };

  const handleToggle = () => {
    setOpenButton((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpenButton(false);
  };

  function InsertText() {
    return (
      <Typography variant="h4">
        <b>{'Novo Produto'}</b>
      </Typography>
    )
  }

  function EditText() {
    return (
      <Typography variant="h4">
        <b>{'Editar Produto'}</b>
      </Typography>
    )
  }
  function SegHeader() {
    if (params.id) {
      return <EditText />
    }
    return <InsertText />
  }
  return (
    <Page
      className={classes.root}
      title={
        params.id
          ? 'Gerência de Produtos - Editar Produto'
          : 'Gerência de Produtos - Novo Produto'
      }
    >
      <Grid
        {...rest}
        className={clsx(classes.root, className)}
        container
        spacing={3}
      >
        <Grid item lg={4} md={6} xl={3} xs={12}>
          <Grid item md={12} xs={12}>
            <Backdrop className={classes.backdrop} open={open}>
              <Grid
                container
                spacing={3}
                direction="column"
                justifyContent="center"
                alignItems="center"
                alignContent="center"
                wrap="nowrap"
              >
                <Grid item>
                  <CircularProgress color="primary" />
                </Grid>
                <Grid item>
                  <Typography variant="h4" color="textPrimary">
                    Enviando imagem...
                  </Typography>
                </Grid>
              </Grid>
            </Backdrop>
            <ImageUploader
              images={images}
              onChangeImage={onChangeImage}
              onError={errorsUploadImage}
              acceptType={['jpeg', 'jpg']}
              maxFileSize={500000}
              resolutionWidth={1100}
              resolutionHeight={1100}
              resolutionType={"less"}
              removeImage={removeImage}
              props={props}
              imagem={imagem}
              getInitials={getInitials}
              classes={classes}
              rest={rest}
              className={className}
            />

          </Grid>

        </Grid>
        <Grid item lg={8} md={6} xl={9} xs={12}>
          <Grid item md={12} xs={12}>
            <Card>
              <ValidatorForm
                className={classes.form}
                ref={formRef}
                id="formProduto"
                onSubmit={(event) => handleSubmit(event)}
              >
                <CardHeader
                  subheader="Cadastro de Produtos"
                  title={<SegHeader />}
                />
                <Divider />
                <CardContent lg={12} md={12} xl={12} xs={12} >
                  <Grid container spacing={2}>
                    <Grid item lg={9} md={8} xl={7} xs={12}>
                      <TextValidator
                        className={classes.textField}
                        fullWidth
                        id="nome"
                        label="Nome *"
                        margin="normal"
                        onChange={handleChange('nome')}
                        value={props.produto.nome}
                        variant="outlined"
                        onInput={stringUtils.toInputUppercase}
                        validators={['required']}
                        errorMessages={['Campo obrigatório!']}
                      />
                    </Grid>
                    <Grid item lg={3} md={4} xl={5} xs={12}>
                      <TextValidator
                        className={classes.textField}
                        fullWidth
                        id="idProdutoReferencia"
                        label="ID Referência (TOTVS) *"
                        margin="normal"
                        onChange={handleChange('idProdutoReferencia')}
                        value={props.produto.idProdutoReferencia}
                        variant="outlined"
                        onInput={stringUtils.toInputUppercase}
                        validators={['required']}
                        errorMessages={['Campo obrigatório!']}
                      />
                    </Grid>
                    <Grid item lg={12} md={12} xl={12} xs={12}>
                      <TextValidator
                        className={classes.textField}
                        fullWidth
                        id="descricao"
                        label="Descritivo"
                        margin="normal"
                        onInput={stringUtils.toInputUppercase}
                        onChange={handleChange('descricao')}
                        value={props.produto.descricao}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item lg={12} md={12} xl={12} xs={12}>
                      <FormControlLabel
                        control={
                          <Switch
                            checked={props.produto.trocavel}
                            onChange={handleChange('trocavel')}
                            color="primary"
                          />
                        }
                        label="Disponível para troca"
                      />
                      <FormControlLabel
                        control={
                          <Switch
                            checked={props.produto.novidade}
                            onChange={handleChange('novidade')}
                            color="primary"
                          />
                        }
                        label="Novidade"
                      />
                    </Grid>
                    <Grid item lg={3} md={4} xl={2} xs={12}>
                      <TextValidator
                        InputLabelProps={{ shrink: true }}
                        className={classes.textField}
                        fullWidth
                        id="pontos"
                        label="Pontos para Troca"
                        margin="normal"
                        onChange={handleChange('pontos')}
                        value={props.produto.pontos}
                        variant="outlined"
                        disabled={!props.produto.trocavel}
                      />
                    </Grid>
                    <Grid item lg={3} md={4} xl={2} xs={12}>
                      <TextValidator
                        InputLabelProps={{ shrink: true }}
                        className={classes.textField}
                        fullWidth
                        id="fatorPontuacao"
                        label="Fator Pontuação"
                        margin="normal"
                        onChange={handleChange('fatorPontuacao')}
                        value={props.produto.fatorPontuacao}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item lg={3} md={4} xl={2} xs={12}>
                      <TextValidator
                        InputLabelProps={{ shrink: true }}
                        className={classes.textField}
                        fullWidth
                        id="preco"
                        label="Preço"
                        margin="normal"
                        onChange={handleChange('preco')}
                        value={props.produto.preco}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item lg={3} md={4} xl={2} xs={12}>
                      <TextValidator
                        InputLabelProps={{ shrink: true }}
                        className={classes.textField}
                        fullWidth
                        id="limite"
                        label="Limite de Trocas"
                        type="number"
                        margin="normal"
                        onChange={handleChange('limiteTrocaClientes')}
                        value={props.produto.limiteTrocaClientes}
                        variant="outlined"
                        disabled={!props.produto.trocavel}
                      />
                    </Grid>
                  </Grid>

                </CardContent>
                <Divider />
                <CardActions style={{ display: 'block' }}>
                  <Grid item lg={12} md={12} xl={12} xs={12}>

                    <Typography style={{ fontSize: "10px", paddingLeft: "6px" }}>
                      * Campos obrigatórios
                    </Typography>

                  </Grid>
                  <Grid item lg={12} md={12} xl={12} xs={12} style={{
                    display: 'flex',
                    alignItems: 'right',
                    flexDirection: 'row',
                    textAlgin: 'right',
                    justifyContent: 'right',
                    gap: '5px'
                  }}>
                    <Button
                      color="default"
                      onClick={() => history.push('/produtos')}
                      variant="contained"
                    >
                      Cancelar
                    </Button>
                    {(params.id ?
                      <>
                        <ButtonGroup variant="contained" color="primary" ref={anchorRef} aria-label="split button">
                          <Button
                            color="primary"
                            onClick={handleToggle}
                            variant="contained"
                            disabled={props.produto.isLoading}
                            startIcon={<SaveIcon />}
                            endIcon={props.produto.isLoading && <CircularProgress size={24} />}
                          >
                            {options[0]}
                          </Button>
                          <Button
                            color="primary"
                            size="small"
                            aria-controls={openButton ? 'split-button-menu' : undefined}
                            aria-expanded={openButton ? 'true' : undefined}
                            aria-label="select merge strategy"
                            aria-haspopup="menu"
                            onClick={handleToggle}
                          >
                            <ArrowDropDownIcon />
                          </Button>
                        </ButtonGroup>
                        <Popper open={openButton} anchorEl={anchorRef.current} role={undefined} transition disablePortal>
                          {({ TransitionProps, placement }) => (
                            <Grow
                              {...TransitionProps}
                              style={{
                                transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
                              }}
                            >
                              <Paper>
                                <ClickAwayListener onClickAway={handleClose}>
                                  <MenuList id="split-button-menu">
                                    {options.map((option, index) => (
                                      <MenuItem
                                        key={option}
                                        selected={index === 0}
                                        disabled={index === 0}
                                        onClick={(event) => handleMenuItemClick(event, index)}
                                      >
                                        {option}
                                      </MenuItem>
                                    ))}
                                  </MenuList>
                                </ClickAwayListener>
                              </Paper>
                            </Grow>
                          )}
                        </Popper>
                      </>
                      :
                      null
                    )}
                    <Button
                      color="primary"
                      type="submit"
                      variant="contained"
                      disabled={props.produto.isLoading}
                      startIcon={<SaveIcon />}
                      endIcon={props.produto.isLoading && <CircularProgress size={24} />}
                    >
                      {params.id ? (props.produto.isLoading ? 'Atualizando produto...' : 'Atualizar') : (props.produto.isLoading ? 'Salvando produto...' : 'Salvar')}
                    </Button>
                  </Grid>
                </CardActions>
              </ValidatorForm>
            </Card>
          </Grid>
        </Grid>
      </Grid>

    </Page>
  )
}

ProdutoEdit.propTypes = {
  classes: PropTypes.object.isRequired
}
const mapStateToProps = (state) => {
  return state
}

const connectedProdutoEditPage = withRouter(
  connect(mapStateToProps, null, null, {
    pure: false
  })(withStyles(styles)(ProdutoEdit))
)
export { connectedProdutoEditPage as ProdutoEdit }
