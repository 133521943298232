const initialState = {
  isLoading: false,
  totalRegistros: 0,
  unidade: [],
  open: false,
  id: 0,
  nome: '',
  abreviacao: '',
  arredondado: false,
  excluido: false,
  page: 0,
  limit: 10,
  order: 'nome',
  direction: 'asc',
  term: ''
}

export function unidade(state = initialState, action) {
  switch (action.type) {
    case 'FETCHED_ALL_UNIDADE':
      return {
        ...state,
        unidade: action.unidade,
        totalRegistros: action.totalRegistros
      }
    case 'UNIDADE_DETAIL':
      return {
        ...state,
        isLoading: false,
        id: action.unidade.id,
        nome: action.unidade.nome,
        abreviacao: action.unidade.abreviacao,
        arredondado: action.unidade.arredondado,
        excluido: action.unidade.excluido
      }
    case 'UNIDADE_UPDATED':
      return state
    case 'UNIDADE_CLEAR':
      return {
        ...initialState,
        page: state.page,
        limit: state.limit,
        order: state.order,
        direction: state.direction,
        term: state.term
      }
    case 'UNIDADE_CLEAR_ALL':
      return {
        ...state,
        unidade: []
      }
    case 'UNIDADE_HANDLE_ON_CHANGE':
      return {
        ...state,
        [action.props]: action.value
      }
    case 'UNIDADE_ISLOADING':
      return {
        ...state,
        isLoading: true
      }
    case 'UNIDADE_NOTISLOADING':
      return {
        ...state,
        isLoading: false
      }
    default:
      return state
  }
}
