import React from 'react'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import { Bar } from 'react-chartjs-2'
import { makeStyles } from '@material-ui/styles'
import {
  Card,
  CardHeader,
  CardContent,
  Divider,
} from '@material-ui/core'
import moment from 'moment'
import palette from 'theme/palette'
import { useSelector } from 'react-redux'
import { options } from './options'
import { Skeleton } from '@material-ui/lab'

const useStyles = makeStyles(() => ({
  root: {},
  chartContainer: {
    height: 400,
    position: 'relative'
  },
  actions: {
    justifyContent: 'flex-end'
  }
}))

const HistoricoPrecoMedioChartCard = (props) => {
  const { className, ...rest } = props
  const { idLoja } = useSelector(state => state.usuarioLoja)
  const graficoHistoricoPrecoMedio = useSelector((state) => (state.dashboardCompra ? (state.dashboardCompra.dashboardLoja ? state.dashboardCompra.dashboardLoja.graficoHistoricoPrecoMedioLoja : undefined) : undefined))
  const graficoHistoricoPrecoMedioNoEstado = useSelector((state) => (state.dashboardCompra ? (state.dashboardCompra.dashboardLoja ? state.dashboardCompra.dashboardLoja.graficoHistoricoPrecoMedioNoEstado : undefined) : undefined))
  const graficoHistoricoPrecoMedioGeral = useSelector((state) => (state.dashboardCompra ? (state.dashboardCompra.dashboardLoja ? state.dashboardCompra.dashboardLoja.graficoHistoricoPrecoMedioGeral : undefined) : undefined))
  const { isLoading } = useSelector((state) => state.dashboardCompra)
  const classes = useStyles()

  const innerOptions = {
    ...options,
    legend: { display: !!idLoja },
    callbacks: {
      label: (tooltipItems, data) => `${data.datasets[tooltipItems.datasetIndex]?.label}: ${tooltipItems.yLabel.toLocaleString('pt-br', { minimumFractionDigits: 2 })}`
    },
    scales: {
      ...options.scales,
      yAxes: [
        {
          ...options.scales?.yAxes[0],
          ticks: {
            fontColor: palette.text.secondary,
            beginAtZero: false,
            stepSize: 0.1
          }

        }
      ]
    }
  }

  const generateData = () => {

    if (graficoHistoricoPrecoMedio) {
      const data = {
        labels: graficoHistoricoPrecoMedioGeral.options.map(d => moment(d).format('DD/MM/YYYY')),
        datasets: idLoja ? [
          {
            label: 'Preço Médio Loja',
            yAxisID: 'A',
            borderColor: palette.error.main,
            backgroundColor: '#FFF',
            data: graficoHistoricoPrecoMedio.series[0],
            type: 'line'
          },
          {
            label: 'Preço Médio No Estado',
            yAxisID: 'A',
            borderColor: '#000',
            backgroundColor: '#FFF',
            data: graficoHistoricoPrecoMedioNoEstado.series[0],
            type: 'line'
          },
          {
            label: 'Preço Médio Geral',
            yAxisID: 'A',
            borderColor: '#080',
            backgroundColor: '#FFF',
            data: graficoHistoricoPrecoMedioGeral.series[0],
            type: 'line'
          }
        ] : [
          {
            label: 'Preço Médio Geral',
            yAxisID: 'A',
            borderColor: '#080',
            backgroundColor: '#FFF',
            data: graficoHistoricoPrecoMedioGeral.series[0],
            type: 'line'
          }
        ]

      }
      return data
    } else {
      return []
    }
  }

  return (
    <Card {...rest} className={clsx(classes.root, className)}>
      <CardHeader
        // action={
        //   <Button
        //     size="small"
        //     variant="text"
        //   >
        //     Last 7 days <ArrowDropDownIcon />
        //   </Button>
        // }
        title="HISTÓRICO DE PREÇO MÉDIO"
      />
      <Divider />
      <CardContent className={classes.chartContainer}>
        {isLoading ? (
          <Skeleton animation="wave" style={{ marginTop: -140 }} height={600} />
        ) : (
          <Bar data={generateData} options={innerOptions} />
        )}
      </CardContent>
      <Divider />
    </Card>
  )
}

HistoricoPrecoMedioChartCard.propTypes = {
  className: PropTypes.string
}

export default HistoricoPrecoMedioChartCard
