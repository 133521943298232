import React from 'react'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/styles'
import { Card, CardContent, Grid, Typography, Avatar, Box, Tooltip } from '@material-ui/core'
import GpsFixedTwoToneIcon from '@material-ui/icons/GpsFixedTwoTone'
import PinDropTwoToneIcon from '@material-ui/icons/PinDropTwoTone'
import StoreTwoToneIcon from '@material-ui/icons/StoreTwoTone'
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'
import RemoveIcon from '@material-ui/icons/Remove'
import { useSelector } from 'react-redux'
import { Skeleton } from '@material-ui/lab'

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%'
  },
  content: {
    alignItems: 'center',
    display: 'flex'
  },
  title: {
    fontWeight: 700
  },
  avatar: {
    backgroundColor: theme.palette.error.main,
    height: 46,
    width: 46
  },
  icon: {
    height: 32,
    width: 32
  },
  difference: {
    marginTop: theme.spacing(2),
    display: 'flex',
    alignItems: 'center'
  },
  differenceIcon: {
    color: theme.palette.success.dark
  },
  differenceValue: {
    color: theme.palette.success.dark,
    marginRight: theme.spacing(1)
  }
}))

const CardComparacaoPrecoMedio = (props) => {
  const { className, type, ...rest } = props
  const classes = useStyles()

  const { mediaPrecoEstado, mediaPrecoLoja, mediaPrecoRede, unidadeInsumo, unidadeInsumoUnidade } = useSelector((state) => state.dashboardCompra.dashboardLoja)

  const { isLoading } = useSelector((state) => state.dashboardCompra)

  const computedTitle = () => {
    let title = '';
    switch (type) {
      case 'loja': {
        title = 'PREÇO MÉDIO DA LOJA';
        break;
      }
      case 'estado': {
        title = 'PREÇO MÉDIO DO ESTADO';
        break;
      }
      case 'rede': {
        title = 'PREÇO MÉDIO DA REDE';
        break;
      }
    }
    return title;
  }

  const computedValue = () => Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL',
    maximumFractionDigits: 5
  }).format(type == 'loja' ? mediaPrecoLoja : type == 'estado' ? mediaPrecoEstado : mediaPrecoRede);

  const handleVariant = () => {
    let value = type == 'estado' ? mediaPrecoEstado : mediaPrecoRede;
    let variant = (100 - (value / mediaPrecoLoja * 100)).toFixed(2);

    return (
      <Tooltip arrow title={variant > 0 ? `${variant}% abaixo do preço médio da loja.` : variant < 0 ? `${variant.replace('-', '')}% acima do preço médio da loja.` : 'Equivalente ao preço médio da loja'}>
        <Box display="flex" flexDirection="row" alignItems="center" justifyContent="flex-start" style={{ marginLeft: '20px' }}>
          {variant > 0 ? <ArrowDropDownIcon style={{ color: '#880000' }} /> : variant < 0 ? <ArrowDropUpIcon style={{ color: '#008800' }} /> : <RemoveIcon style={{ color: '#777' }} />}
          <Typography variant="h5" style={{ color: '#777' }}>{isLoading ? <Skeleton /> : variant.replace('-', '') + '%'}</Typography>
        </Box>
      </Tooltip>
    )
  }

  return (
    <Card {...rest} className={clsx(classes.root, className)}>
      <CardContent>
        <Grid container justifyContent="space-between">
          <Grid item style={{ flex: 1, marginRight: '20px' }}>
            <Box display="flex" flexDirection="row" alignItems="center" justifyContent="flex-start">
              <Typography
                className={classes.title}
                color="textSecondary"
                gutterBottom
                variant="body2"
              >
                {computedTitle()}
              </Typography>
              <Typography
                color="textSecondary"
                gutterBottom
                variant="body2"
                style={{ marginLeft: '5px' }}
              >
                ({(unidadeInsumoUnidade ? unidadeInsumoUnidade : (unidadeInsumo ? unidadeInsumo : ''))})
              </Typography>
            </Box>
            <Box display="flex" flexDirection="row" alignItems="center" justifyContent="flex-start">
              <Typography variant="h3">{isLoading ? <Skeleton /> : computedValue()}</Typography>
              {type != 'loja' && mediaPrecoLoja > 0 && handleVariant()}
            </Box>
          </Grid>
          <Grid item>
            <Avatar className={classes.avatar}>
              {type == 'rede' ? <StoreTwoToneIcon className={classes.icon} /> : type == 'estado' ? <PinDropTwoToneIcon className={classes.icon} /> : <GpsFixedTwoToneIcon className={classes.icon} />}
            </Avatar>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  )
}

CardComparacaoPrecoMedio.propTypes = {
  className: PropTypes.string
}

export default CardComparacaoPrecoMedio
