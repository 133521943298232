import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/styles'
import { Typography, CardContent, Grid, CircularProgress, Box } from '@material-ui/core'
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import LocalBarRoundedIcon from '@material-ui/icons/LocalBarRounded'

const useStyles = makeStyles((theme) => ({
  root: {
    alignItems: 'center',
    justifyContent: 'center',
    marginLeft: 10,
    marginRight: 10
  },
  title: {
    fontWeight: 600,
    fontSize: 17,
    color: theme.palette.primary.main
  },
  subheader: {
    fontWeight: 400,
    flex: 1,
    fontSize: 16,
    marginTop: 20,
    marginLeft: 10
  },
  avatar: {
    backgroundColor: theme.palette.error.main,
    height: 60,
    width: 60
  }
}));

const CardChecklistCategoriaInsumo = (props) => {
  const { className, name, type, progresso, ...rest } = props

  const classes = useStyles();

  return (
    <Card {...rest} className={clsx(classes.root, className)}>
      <CardActionArea>
        <CardContent>
          <Grid container justifyContent="space-between">
            <Grid item>
              <Typography
                className={classes.title}
                gutterBottom
                variant="body2"
              >
                {name}
              </Typography>
            </Grid>
            <Grid item>
              <Box position="relative" display="inline-flex">
                {/*<LocalBarRoundedIcon />*/}
              </Box>
            </Grid>
          </Grid>
        </CardContent>
      </CardActionArea>
    </Card>
  );
}

CardChecklistCategoriaInsumo.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string,
  type: PropTypes.string,
  remove: PropTypes.element
}

export default CardChecklistCategoriaInsumo
