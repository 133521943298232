import { history } from 'utils'
import { crudService } from '../_services'
import { stringUtils } from 'utils'
import { toast } from 'react-toastify'

export const tipoAjustePontuacaoAction = {
  getTipoAjustePontuacao,
  addTipoAjustePontuacao,
  getTipoAjustePontuacaoSelect,
  onChangeProps,
  editTipoAjustePontuacaoInfo,
  editTipoAjustePontuacoesDetails,
  clear,
  clearAll
}

let filterModel = {
  Page: 0,
  Limit: 10,
  Term: ''
};

function getTipoAjustePontuacao(filterModel, showWaiting) {
  return (dispatch) => {
    let apiEndpoint = 'tipo_ajuste_pontuacoes' + stringUtils.serialize(filterModel);
    crudService
      .get(apiEndpoint)
      .then((response) => {
        dispatch(changeTipoAjustePontuacoesList(response.data.items, response.data.totalRows))
      })
      .catch((err) => {
        console.log(err)
      })
  }
}

function getTipoAjustePontuacaoSelect() {
  return (dispatch) => {
    let apiEndpoint = 'tiposAjustesPontuacoes/select'
    crudService
      .get(apiEndpoint)
      .then((response) => {
        if (response.data) {
          dispatch(changeTipoAjustePontuacoesList(response.data))
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }
}

function onChangeProps(props, event) {
  return (dispatch) => {
    if (event.target.type === 'checkbox') {
      dispatch(handleOnChangeProps(props, event.target.checked))
    } else {
      dispatch(handleOnChangeProps(props, event.target.value))
    }
  }
}

function editTipoAjustePontuacaoInfo(id, payload, redirect) {
  return (dispatch) => {
    dispatch(isLoading())
    let apiEndpoint = 'tipo_ajuste_pontuacoes/' + id
    crudService.put(apiEndpoint, payload)
      .then((response) => {
        if (response.request != undefined && response.request.status === 200) {
          var ret = JSON.parse(response.request.responseText);
          dispatch(updatedTipoAjustePontuacaoInfo())
          dispatch(clear())
          if (redirect) {
            dispatch(notIsLoading())
            history.push('/tipo_ajuste_pontuacoes')
          }
        }
      }).catch((err) => {
        dispatch(notIsLoading())
        toast.error('Oops! Erro ao atualizar tipoAjustePontuacao! 😥')
        //  console.log(err.response);
        return err.response;
      }
      )
  }
}

export function addTipoAjustePontuacao(payload) {
  return [{ type: 'ADD_TIPO_AJUSTE_PONTUACAO', tipoAjustePontuacao: payload }, clear()]
}

export function changeTipoAjustePontuacoesList(tipoAjustePontuacao, totalRows) {
  return {
    type: 'FETCHED_ALL_TIPO_AJUSTE_PONTUACAO',
    tipoAjustePontuacao: tipoAjustePontuacao,
    totalRegistros: totalRows
  }
}

export function clear() {
  return {
    type: 'TIPO_AJUSTE_PONTUACAO_CLEAR'
  }
}

export function clearAll() {
  return {
    type: 'TIPO_AJUSTE_PONTUACAO_CLEAR_ALL'
  }
}

export function handleOnChangeProps(props, value) {
  return {
    type: 'TIPO_AJUSTE_PONTUACAO_HANDLE_ON_CHANGE',
    props: props,
    value: value
  }
}

export function editTipoAjustePontuacoesDetails(tipoAjustePontuacao) {
  return [
    { type: 'TIPO_AJUSTE_PONTUACAO_DETAIL', tipoAjustePontuacao },
  ]
}

export function updatedTipoAjustePontuacaoInfo() {
  return {
    type: 'TIPO_AJUSTE_PONTUACAO_UPDATED'
  }
}

export function createTipoAjustePontuacaoInfo() {
  return {
    type: 'TIPO_AJUSTE_PONTUACAO_CREATED_SUCCESSFULLY'
  }
}

export function deleteTipoAjustePontuacoesDetails() {
  return {
    type: 'DELETED_TIPO_AJUSTE_PONTUACAO_DETAILS'
  }
}

export function isLoading() {
  return {
    type: 'TIPO_AJUSTE_PONTUACAO_ISLOADING'
  }
}

export function notIsLoading() {
  return {
    type: 'TIPO_AJUSTE_PONTUACAO_NOTISLOADING'
  }
}
