const initialState = {
  isLoading: false,
  totalRegistros: 0,
  meta: [],
  id: 0,
  tipoMeta: 0,
  dataInicio: '',
  dataTermino: '',
  valor: 0,
  excluido: false,
  metasLojas: [],
  page: 0,
  limit: 10,
  order: 'dataInicio',
  direction: 'desc',
  term: ''
}

export function meta(state = initialState, action) {
  switch (action.type) {
    case 'FETCHED_ALL_META':
      return {
        ...state,
        meta: action.meta,
        totalRegistros: action.totalRegistros
      }
    case 'META_DETAIL':
      return {
        ...state,
        isLoading: false,
        id: action.meta.id,
        tipoMeta: action.meta.tipoMeta,
        dataInicio: action.meta.dataInicio,
        dataTermino: action.meta.dataTermino,
        valor: action.meta.valor,
        excluido: action.meta.excluido,
        metasLojas: action.meta.metasLojas
      }
    case 'META_UPDATED':
      return state
    case 'META_CLEAR':
      return {
        ...initialState,
        metasLojas: [],
        page: state.page,
        limit: state.limit,
        order: state.order,
        direction: state.direction,
        term: state.term
      }
    case 'META_CLEAR_ALL':
      return {
        ...state,
        meta: []
      }
    case 'META_HANDLE_ON_CHANGE':
      return {
        ...state,
        [action.props]: action.value
      }
    case 'META_ISLOADING':
      return {
        ...state,
        isLoading: true
      }
    case 'META_NOTISLOADING':
      return {
        ...state,
        isLoading: false
      }
    default:
      return state
  }
}
