import React, { Component } from 'react';
//import './App.css';
import { Map, GoogleApiWrapper, Marker } from 'google-maps-react';

export class MapContainer extends Component {
 
  constructor(props) {
    super(props);
  
    this.state = {
      stores: [
        { latitude: props.latitude, longitude: props.longitude, local: props.nome }
      ]
    }
   
  }

  displayMarkers = () => {
    return this.state.stores.map((store, index) => {
      return <Marker key={index} id={index}
        position={{ lat: store.latitude, lng: store.longitude }}
        icon={require('../../img/logo_vermelho(35x35).png')}
        draggable={true}
        coordinate={this.state.x}
        onDragEnd={(e) => this.setState({ latLon: e.nativeEvent.coordinate })}
        tracksViewChanges={false}
      />
      })
  }

  render() {
    return (

      <Map        
        google={this.props.google}
        style={{ height: "300px", width: "100%", borderRadius: "10px", alignContent: 'center' }}
        zoom={13}
        initialCenter={{ lat: this.state.stores[0].latitude, lng: this.state.stores[0].longitude }}
      >
        {this.displayMarkers()}
      </Map>

    );
  }
}

export default GoogleApiWrapper(
  (props) => ({
    apiKey: 'AIzaSyAQCdFXjUZcYlFI6pcW9yPs0MJTQtrqhaM',
  }
  ))(MapContainer)
