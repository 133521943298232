import React, { useEffect } from 'react'
import { connect, useDispatch } from 'react-redux'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import { pedidoCompraAction } from '../../_actions'
import { withRouter } from 'react-router-dom'
import { Page } from 'components'
import { makeStyles } from '@material-ui/styles'
import {
  Button,
  useTheme,
  useMediaQuery
} from '@material-ui/core'
import { Link as RouterLink } from 'react-router-dom'

const styles = (theme) => ({
  root: {
    padding: theme.spacing(3),
    paddingTop: '10vh',
    display: 'flex',
    flexDirection: 'column',
    alignContent: 'center'
  },
  imageContainer: {
    marginTop: theme.spacing(6),
    display: 'flex',
    justifyContent: 'center'
  },
  image: {
    maxWidth: '100%',
    width: 560,
    maxHeight: 300,
    height: 'auto'
  },
  buttonContainer: {
    marginTop: theme.spacing(6),
    display: 'flex',
    justifyContent: 'center'
  },
  content: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    textAlgin: 'center'
  },
  nome: {
    marginTop: theme.spacing(1)
  },
  uploadButton: {
    marginRight: theme.spacing(2),
    justifyContent: 'space-between'
  },
  avatar: {
    height: 90,
    width: 90,
    borderRadius: '10px',
    webkitBorderRadius: '10px',
    mozBorderRadius: '10px',
    cursor: 'pointer'
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff'
  },
  lightTooltip: {
    backgroundColor: theme.palette.common.white
  },
  variant60: {
    width: '60%',
    fontSize: 11,
  },
  variant50: {
    width: '50%',
    fontSize: 12,
  },
  variant43: {
    width: '43%',
    fontSize: 12,
  },
  fontTypography: {
    fontSize: 6.5
  },
  stickyCell: {
    position: 'sticky',
    left: 0,
    zIndex: 800
  },
  stickyButtons: {
    bottom: 0,
    position: '-webkit-sticky',
    position: 'sticky',
    zIndex: 800,
    backgroundColor: '#FFFFFF',
    border: '1px solid #E0E2E3',
    borderBottomLeftRadius: 5,
    borderBottomRightRadius: 5
  },
  buttonGrid: {
    paddingBottom: 8,
    paddingRight: 8,
    [theme.breakpoints.down('xs')]: {
      display: 'block',
      paddingRight: 0
    },
    [theme.breakpoints.up('xs')]: {
      flexDirection: 'row',
      alignItems: 'right',
      textAlign: 'right',
      justifyContent: 'right',
    }
  },
  button: {
    [theme.breakpoints.down('xs')]: {
      width: '100%'
    }
  }
})

const PedidoCompraRecebidoEdit = (props) => {

 //const classes = useStyles()
  const theme = useTheme()
  const mobileDevice = useMediaQuery(theme.breakpoints.down('sm'))

  const dispatch = useDispatch()
  const { classes, pedidoCompra, className, ...rest } = props
  const { match: { params } } = props;  

  useEffect(() => {

    if (params.id) {
      dispatch(pedidoCompraAction.markAsReceivedPedidoCompra(params.id))
    }

  }, [params.id]);

  return (
    <Page className={classes.root} title="Pedido de Compra">
      <Typography align="center" variant={mobileDevice ? 'h4' : 'h1'}>
        Caro fornecedor, 
      </Typography>
      <Typography align="center" variant="subtitle2">
        Obrigado por confirmar o recebimento do pedido!
      </Typography>
      <div className={classes.imageContainer}>
        {/*
        <img
          alt="Under development"
          className={classes.image}
          src="/images/coruja-error.svg"
        />
        */}
      </div>
      <div className={classes.buttonContainer}>
        <Button
          color="primary"
          component={RouterLink}
          to="/login"
          variant="outlined"
        >
          Voltar para login
        </Button>
      </div>
    </Page>
  )
}

PedidoCompraRecebidoEdit.propTypes = {
  classes: PropTypes.object.isRequired
}
const mapStateToProps = (state) => {
  return state
}

const connectedPedidoCompraRecebidoEditPage = withRouter(
  connect(mapStateToProps, null, null, {
    pure: false
  })(withStyles(styles)(PedidoCompraRecebidoEdit))
)
export { connectedPedidoCompraRecebidoEditPage as PedidoCompraRecebidoEdit }

//export default PedidoCompraRecebidoEdit
