import React, { useEffect, useState } from 'react'
import { connect, useDispatch } from 'react-redux'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import {
  Card,
  CardHeader,
  CardContent,
  Divider,
  Grid,
  Backdrop,
  CircularProgress,
  Table,
  TableContainer,
  TableBody,
  TableCell,
  TableRow,
  TableHead
} from '@material-ui/core'
import {
  ValidatorForm
} from 'react-material-ui-form-validator'
import { blingAction } from '../../_actions'
import { withRouter } from 'react-router-dom'
import { Page } from 'components'
import moment from 'moment'
import ImageUploading from 'react-images-uploading'
import { errorsUploadImage } from 'utils'
import SemFoto from '../../img/sem_foto.png'

const styles = (theme) => ({
  root: {
    padding: theme.spacing(2)
  },
  content: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    textAlign: 'center'
  },
  nome: {
    marginTop: theme.spacing(1)
  },
  uploadButton: {
    marginRight: theme.spacing(2),
    justifyContent: 'space-between'
  },
  imagem: {
    height: 220,
    objecFit: 'cover',
    display: 'flex',
    alignItems: 'center'
  },
  icone: {
    height: 220,
    alignItems: 'center',
    padding: 60
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff'
  }
})

const ProdutoCompraEdit = (props) => {

  const dispatch = useDispatch()
  const { classes, bling, className, ...rest } = props
  const { match: { params } } = props;
  const formRef = React.createRef(null);

  const [imagem, setImagem] = useState('');
  const [images, setImages] = useState('');
  const [open, setOpen] = useState(false);
  const [, forceUpdate] = React.useState(0);

  useEffect(() => {

    if (params.id) {
      dispatch(blingAction.getBlingProdutoByCodigo(params.id))    

    } else {
      dispatch(blingAction.clear())
      setImagem(null)
    }

  }, []);

  useEffect(() => {
    if (props.bling.imagem.length) {
      setImagem(props.bling.imagem[0].link)
    } else {
      setImagem('');
    }
  }, [props.bling]);

  useEffect(() => {
    forceUpdate(n => !n);
  }, [props.bling.imagem[0]]);

  function InsertText() {
    return (
      <Typography variant="h4">
        <b>{'Produto de Compra'}</b>
      </Typography>
    )
  }

  function EditText() {
    return (
      <Typography variant="h4">
        <b>{'Produto de Compra'}</b>
      </Typography>
    )
  }

  function SegHeader() {
    if (params.id) {
      return <EditText />
    }
    return <InsertText />
  }
  return (
    <Page
      className={classes.root}
      title={
        params.id
          ? 'Gerência de Produtos de Compra - Editar Produto'
          : 'Gerência de Produtos de Compra - Novo Produto'
      }
    >
      <Grid
        {...rest}
        className={clsx(classes.root, className)}
        container
        spacing={3}
      >
        <Grid item lg={4} md={6} xl={3} xs={12}>
          <Grid item md={12} xs={12}>
            <Backdrop className={classes.backdrop} open={open}>
              <Grid
                container
                spacing={3}
                direction="column"
                justifyContent="center"
                alignItems="center"
                alignContent="center"
                wrap="nowrap"
              >
                <Grid item>
                  <CircularProgress color="primary" />
                </Grid>
               
              </Grid>
            </Backdrop>
            <ImageUploading
              maxFileSize={2000000}
              onError={(errors) => errorsUploadImage(errors)}
              value={images}
              dataURLKey="data_url"
            >
              {({ isDragging, dragProps }) => (
                <Card {...rest} className={clsx(classes.root, className)}>
                  <CardContent {...dragProps} className={classes.content}>
                    {imagem.length ?
                      <img
                        src={imagem}
                        alt={imagem}
                        className={classes.imagem}
                      />
                      :
                      <img
                        src={SemFoto}
                        alt={SemFoto}
                        className={classes.icone}
                      />
                    }
                  </CardContent>
                </Card>
              )}
            </ImageUploading>
          </Grid>

        </Grid>
        <Grid item lg={8} md={6} xl={9} xs={12}>
          <Grid item md={12} xs={12}>
            <Card>
              <ValidatorForm
                className={classes.form}
                ref={formRef}
                id="formProdutoCompra"                
              >
                <CardHeader
                  subheader="Características do Produto de Compra"
                  title={<SegHeader />}
                />
                <Divider />
                <CardContent lg={12} md={12} xl={12} xs={12} >                                 
                    <TableContainer >
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell align="left">Código</TableCell>
                          <TableCell align="left" colSpan={2}>Descrição</TableCell>
                          <TableCell align="left">Unidade</TableCell>
                        </TableRow>
                      </TableHead>
                        <TableBody>
                          <TableRow
                            hover
                            key={(props.bling ? (props.bling.codigo || '').toUpperCase() : '')}>                            
                            <TableCell align="left">
                              {(props.bling ? (props.bling.codigo || '').toUpperCase() : '')}
                            </TableCell>                           
                            <TableCell align="left" colSpan={2}>
                              {(props.bling ? (props.bling.descricao || '').toUpperCase() : '')}
                            </TableCell>
                          <TableCell align="left">
                              {(props.bling ? (props.bling.unidade || '').toUpperCase() : '')}
                             </TableCell>
                          </TableRow>
                       </TableBody>                  
                      <TableHead>
                        <TableRow>
                          <TableCell align="left">Unidade de medida</TableCell>
                          <TableCell align="left">Data de inclusão</TableCell>
                          <TableCell align="left">Data de alteração</TableCell>
                          <TableCell align="left">Preço</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow
                          hover
                          key={(props.bling ? (props.bling.unidadeMedida || '').toUpperCase() : '')}>
                          <TableCell align="left">
                            {(props.bling ? (props.bling.unidadeMedida || '').toUpperCase() : '')}
                          </TableCell>
                          <TableCell align="left">
                            {(props.bling ? moment(props.bling.dataInclusao).format("DD/MM/YYYY") : '')}
                          </TableCell>
                          <TableCell align="left">
                            {(props.bling ? moment(props.bling.dataAlteracao).format("DD/MM/YYYY") : '')}
                          </TableCell>
                          <TableCell align="left">
                            {(props.bling ? 'R$' + parseFloat(props.bling.preco).toLocaleString('pt-br', { minimumFractionDigits: 2 }) : '')}
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                    </TableContainer>
                </CardContent>
                <Divider />               
              </ValidatorForm>
            </Card>
          </Grid>
        </Grid>
      </Grid>

    </Page>
  )
}

ProdutoCompraEdit.propTypes = {
  classes: PropTypes.object.isRequired
}
const mapStateToProps = (state) => {
  return state
}

const connectedProdutoCompraEditPage = withRouter(
  connect(mapStateToProps, null, null, {
    pure: false
  })(withStyles(styles)(ProdutoCompraEdit))
)
export { connectedProdutoCompraEditPage as ProdutoCompraEdit }
