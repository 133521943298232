import React, { useEffect, useState } from 'react'
import { connect, useDispatch, useSelector } from 'react-redux'
import { checklistAction } from '../../_actions'
import PropTypes from 'prop-types'
import {
  withStyles,
  Table,
  TableContainer,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Tooltip,
  Input,
  Grid,
  TablePagination 
} from '@material-ui/core'
import DeleteIcon from '@material-ui/icons/Delete'
import FileCopyIcon from '@material-ui/icons/FileCopy'
import EditIcon from '@material-ui/icons/Edit'
import PrintIcon from '@material-ui/icons/Print'
import { withRouter } from 'react-router-dom'
import { history } from '../../utils'
import TitleContent from '../TitleContent'
import { useConfirm } from 'material-ui-confirm'
import { AccessControl } from '../../components/Utils/AccessControl'
import AccessDenied from '../Errors/AccessDenied'
import { Page, PaginationActions } from 'components'
import { useDebounce } from 'use-debounce'
import { toast } from 'react-toastify'

const styles = (theme) => ({
  root: {
    padding: theme.spacing(3, 2, 2, 2)
  }
})

const Checklist = (props) => {

  const confirm = useConfirm()
  const dispatch = useDispatch()
  const { classes } = props
  const { checklist } = props.checklist
  const { totalRegistros } = props.checklist
  const { idLoja } = useSelector(state => state.usuarioLoja)
  const { page, limit, term, direction, order } = useSelector(state => state.checklist);
  const [debounceTerm] = useDebounce(term, 1000)

  useEffect(() => {
    fetchData();
  }, [page, limit, debounceTerm]);

  useEffect(() => {
    handleChangePage(null, 0);
  }, [totalRegistros])

  useEffect(() => {
    handleChangePage(null, page);
    fetchData();
  }, [idLoja]);

  const fetchData = () => {
    let filterModel = {
      Page: page || 0,
      Limit: limit || 10,
      Term: debounceTerm || '',
      Order: order || 'nome',
      Direction: direction || 'asc',
      IdLoja: (idLoja && idLoja !== '') ? idLoja : 0
    };

    dispatch(checklistAction.getChecklist(filterModel, true));
  };

  const handleChange = prop => event => {
    dispatch(checklistAction.onChangeProps(prop, event))
  };

  const handleChangePage = (_, value) => {
    const fakeEvent = {
      type: 'text',
      target: {
        value
      }
    }
    dispatch(checklistAction.onChangeProps('page', fakeEvent))
  }

  const handleChangeTerm = (value) => {
    const fakeEvent = {
      type: 'text',
      target: {
        value
      }
    }
    props.checklist.page = 0;
    dispatch(checklistAction.onChangeProps('term', fakeEvent))
  }

  const handleClick = (id) => {
    confirm({
      title: 'Você deseja excluir esta Planilha de Contagem?',
      description: 'Essa operação é irreversível',
      disabled: props.checklist.isLoading,
      confirmationText: props.checklist.isLoading ? 'Excluindo Planilhas de Contagem...' : 'Sim, excluir!',
      cancellationText: 'Não!'
      
    }).then(() => {
      dispatch(checklistAction.deleteChecklistById(id))
    })
  }

  const handleDuplicate = (id) => {
    confirm({
      title: 'Você deseja duplicar esta Planilha de Contagem?',
      description: 'Essa operação é irreversível',
      confirmationText: 'Sim, duplicar!',
      cancellationText: 'Não!'
    }).then(() => {
      dispatch(checklistAction.duplicateChecklist(id, true))
    })
  }

  const handlePrint = (n) => {

    if (idLoja === '' || idLoja === undefined) {
      toast.error('Selecione uma Loja');
    }
    else {

      if (n.tipo == 'D') {
        window.open('/relatorioCheckListDiario/' + n.id, '_blank');
      } else {
        window.open('/relatorioCheckList/' + n.id, '_blank');
      }

    }

  }

  return (
    <Page className={classes.root} title="Planilhas de Contagem">
      <AccessControl
        rule={'checklists'} //permissão necessária para acessar conteúdo
        yes={() => (
          <div>
            {undefined !== checklist && checklist.length > 0 ? (
              <TitleContent
                rule={'checklists.create'}
                length={checklist.length}
                subTitle={'GERÊNCIA DE PLANILHA DE CONTAGEM'}
                title={'Lista de Planilhas de Contagem'}
                href={'/checklist'}
              />
            ) : (
              <TitleContent
                rule={'checklists.create'}
                length={0}
                subTitle={'GERÊNCIA DE PLANILHA DE CONTAGEM'}
                title={'Lista de Planilhas de Contagem'}
                href={'/checklist'}
              />
            )}
            <Grid item xl={4} lg={4} md={6} xs={12}>
              <Input
                id="term"
                label="term"
                placeholder="Filtro"
                value={term}
                onChange={(event) => handleChangeTerm(event.target.value)}
                fullWidth
              />
            </Grid>
            <br />
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Descrição</TableCell>
                    <TableCell>Tipo</TableCell>
                    <TableCell align="right">Ações</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {undefined !== checklist && checklist.length
                    ? checklist.map((n) => {
                        return (
                          <TableRow hover key={n.id}>
                            <TableCell component="th" scope="row">
                              {n.descricao}
                            </TableCell>
                            <TableCell component="th" scope="row">
                              {(n.tipo == 'D' ? 'DIÁRIO' : 'NORMAL')}
                            </TableCell>
                            <TableCell align="right">
                              <AccessControl
                                rule={'checklists.edit'} //permissão necessária para acessar conteúdo
                                yes={() => (
                                  <IconButton
                                    aria-label="Duplicate"
                                    component="a"
                                    onClick={() => handleDuplicate(n.id)}
                                    size="small"
                                  >
                                    <Tooltip title="Duplicar e editar">
                                      <Tooltip>
                                        <FileCopyIcon />
                                      </Tooltip>
                                    </Tooltip>
                                  </IconButton>
                                )}
                              />
                              <AccessControl
                                rule={'checklists.print'} //permissão necessária para acessar conteúdo
                                yes={() => (
                                  <IconButton
                                    aria-label="Edit"
                                    component="a"
                                    onClick={() => handlePrint(n)}
                                    size="small"
                                  >
                                    <Tooltip title="Imprimir Planilha de Contagem">
                                      <Tooltip >
                                        <PrintIcon />
                                      </Tooltip>
                                    </Tooltip>
                                  </IconButton>
                                )}
                              />
                              <AccessControl
                                rule={'checklists.edit'} //permissão necessária para acessar conteúdo
                                yes={() => (
                                  <IconButton
                                    aria-label="Edit"
                                    component="a"
                                    onClick={() =>
                                      history.push(`/checklist/${n.id}`)
                                    }
                                    size="small"
                                  >
                                    <Tooltip title="Editar">
                                      <Tooltip >
                                        <EditIcon />
                                      </Tooltip>
                                    </Tooltip>
                                  </IconButton>
                                )}
                              />
                              <AccessControl
                                rule={'checklists.delete'} //permissão necessária para acessar conteúdo
                                yes={() => (
                                  <IconButton
                                    aria-label="Delete"
                                    onClick={() => handleClick(n.id)}
                                    size="small"
                                  >
                                    <Tooltip
                                      title="Excluir"
                                      >
                                      <DeleteIcon />
                                    </Tooltip>
                                  </IconButton>
                                )}
                              />
                            </TableCell>
                          </TableRow>
                        )
                      })
                    : null}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              component="div"
              count={totalRegistros}
              page={page}
              onPageChange={handleChangePage}
              rowsPerPage={limit}
              onRowsPerPageChange={handleChange('limit')}
              ActionsComponent={PaginationActions}
            />
          </div>
        )}
        no={() => <AccessDenied />}
      />
    </Page>
  )
}
Checklist.propTypes = {
  classes: PropTypes.object.isRequired
}
const mapStateToProps = (state) => {
  return {
    checklist: state.checklist
  }
}
const connectedChecklistPage = withRouter(
  connect(mapStateToProps, null, null, {
    pure: false
  })(withStyles(styles)(Checklist))
)
export { connectedChecklistPage as Checklist }
