import React from 'react'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/styles'
import { Card, CardContent, Grid, Typography, Avatar } from '@material-ui/core'
import ConfirmationNumberTwoToneIcon from '@material-ui/icons/ConfirmationNumberTwoTone'
import { useSelector } from 'react-redux'
import { Skeleton } from '@material-ui/lab'
var pluralize = require('pluralize')

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText
  },
  content: {
    alignItems: 'center',
    display: 'flex'
  },
  title: {
    fontWeight: 700
  },
  avatar: {
    backgroundColor: theme.palette.white,
    color: theme.palette.primary.main,
    height: 46,
    width: 46
  },
  icon: {
    height: 32,
    width: 32
  }
}))

const TrocasChartCard = (props) => {
  const { className, ...rest } = props
  const classes = useStyles()

  const trocas = useSelector((state) => (state.dashboard ? (state.dashboard.dashboardLoja ? state.dashboard.dashboardLoja.trocas : undefined) : undefined))
  const rankingTrocas = useSelector((state) => (state.dashboard ? (state.dashboard.dashboardLoja ? state.dashboard.dashboardLoja.rankingTrocas : undefined) : undefined))
  const totalLojas = useSelector((state) => (state.dashboard ? (state.dashboard.dashboardLoja ? state.dashboard.dashboardLoja.totalLojas : undefined) : undefined))
  const pontosTrocas = useSelector((state) => (state.dashboard ? (state.dashboard.dashboardLoja ? state.dashboard.dashboardLoja.pontosTrocas : undefined) : undefined))
  const { isLoading } = useSelector((state) => state.dashboard)

  return (
    <Card {...rest} className={clsx(classes.root, className)}>
      <CardContent>
        <Grid container justifyContent="space-between">
          <Grid item>
            <Typography
              className={classes.title}
              color="inherit"
              gutterBottom
              variant="body2"
            >
              TROCAS
            </Typography>
            <Typography color="inherit" variant="h3">
              {isLoading ? (
                <Skeleton />
              ) : (
                  trocas
              )}
              <Typography color="inherit" variant="body2">
                TOTALIZANDO
              </Typography>
            </Typography>
            <Typography color="inherit" variant="h3">
              {isLoading ? (
                <Skeleton />
              ) : (
                pluralize('pts', pontosTrocas, true)
              )}
            </Typography>
          </Grid>
          <Grid item>
            <Avatar className={classes.avatar}>
              <ConfirmationNumberTwoToneIcon className={classes.icon} />
            </Avatar>
            <Typography color="inherit" variant="h5" style={{ marginTop: 5 }}>
              {isLoading ? (
                <Skeleton />
              ) : (
                  (rankingTrocas != null ? rankingTrocas + 'º / ' + totalLojas : '')
              )}
            </Typography>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  )
}

TrocasChartCard.propTypes = {
  className: PropTypes.string
}

export default TrocasChartCard
