import { history } from 'utils'
import { crudService } from '../_services'
import { stringUtils } from 'utils'
import { toast } from 'react-toastify'

export const subcategoriaCompraAction = {
  getSubcategoria,
  getSubcategoriasByCategoriaId,
  getSubcategoriaSelect,
  addSubcategoria,
  getSubcategoriaById,
  onChangeProps,
  editSubcategoriaInfo,
  editSubcategoriasDetails,
  createSubcategoria,
  deleteSubcategoriaById,
  clear,
  clearAll
}

let filterModel = {
  Page: 0,
  Limit: 10,
  Term: ''
};

function getSubcategoria(filterModel, showWaiting) {
  return (dispatch) => {
    let apiEndpoint = 'subcategoriasCompras' + stringUtils.serialize(filterModel);
    crudService
      .get(apiEndpoint)
      .then((response) => {
        dispatch(changeSubcategoriasList(response.data.items, response.data.totalRows))
      })
      .catch((err) => {
        console.log(err)
      })
  }
}

function getSubcategoriasByCategoriaId(id) {
  return (dispatch) => {
    let apiEndpoint = 'subcategoriasCompras/categoria/' + id + stringUtils.serialize(filterModel);
    crudService
      .get(apiEndpoint)
      .then((response) => {
        dispatch(changeSubcategoriasList(response.data.items, response.data.totalRows))
      })
      .catch((err) => {
        console.log(err)
      })
  }
}

function getSubcategoriaSelect() {
  return (dispatch) => {
    let apiEndpoint = 'subcategoriasCompras/select';
    crudService
      .get(apiEndpoint)
      .then((response) => {
        dispatch(changeSubcategoriasList(response.data, 0))
      })
      .catch((err) => {
        console.log(err.response);
        return err.response;
      })
  }
}

function createSubcategoria(payload) {
  return (dispatch) => {
    dispatch(isLoading())

    let apiEndpoint = 'subcategoriasCompras/'
    crudService.post(apiEndpoint, payload)
      .then((response) => {
        if (response.request != undefined && response.request.status === 200) {
          var ret = JSON.parse(response.request.responseText);
          dispatch(createSubcategoriaInfo())
          toast.success('Feito! Cadastro realizado com sucesso! 😎')
          dispatch(clear())
          dispatch(notIsLoading())
          dispatch(getSubcategoriasByCategoriaId(payload.idCategoriaCompra))
          history.push('/categoriaCompra/' + payload.idCategoriaCompra)
        } else {
          toast.err('Oops! Erro ao cadastrar Subcategoria de Compra! 😥')
        }
      }).catch((err) => {
        dispatch(notIsLoading())
        toast.error('Oops! Erro ao cadastrar Subcategoria de Compra! 😥')
        return err.response;
      })
  }
}

function getSubcategoriaById(id) {
  return (dispatch) => {
    let apiEndpoint = 'subcategoriasCompras/' + id
    crudService.get(apiEndpoint).then((response) => {
      dispatch(editSubcategoriasDetails(response.data))
    })
  }
}

function onChangeProps(props, event) {
  return (dispatch) => {
    if (event.target.type === 'checkbox') {
      dispatch(handleOnChangeProps(props, event.target.checked))
    } else {
      dispatch(handleOnChangeProps(props, event.target.value))
    }
  }
}

function editSubcategoriaInfo(id, payload, redirect) {
  return (dispatch) => {
    dispatch(isLoading())
    let apiEndpoint = 'subcategoriasCompras/' + id
    crudService.put(apiEndpoint, payload)
      .then((response) => {
        if (response.request != undefined && response.request.status === 200) {
          var ret = JSON.parse(response.request.responseText);
          dispatch(updatedSubcategoriaInfo())
          dispatch(clear())
          if (redirect) {
            dispatch(notIsLoading())
            dispatch(getSubcategoriasByCategoriaId(payload.idCategoriaCompra))
            history.push('/categoriaCompra/' + payload.idCategoriaCompra)
          }
        }
      }).catch((err) => {
        dispatch(notIsLoading())
        toast.error('Oops! Erro ao atualizar Subcategoria de Compra! 😥')
        return err.response;
      }
      )
  }
}

function deleteSubcategoriaById(id, idCategoria) {
  return (dispatch) => {
    dispatch(isLoading())
    let apiEndpoint = 'subcategoriasCompras/' + id
    crudService.deleteDetail(apiEndpoint).then(() => {
      dispatch(deleteSubcategoriasDetails())
      dispatch(getSubcategoriasByCategoriaId(idCategoria))
      dispatch(notIsLoading())
      history.push('/categoriaCompra/' + idCategoria)
    })
  }
}

export function addSubcategoria(payload) {
  return [{ type: 'ADD_SUBCATEGORIACOMPRA', subcategoriaCompra: payload }, clear()]
}

export function changeSubcategoriasList(subcategoriaCompra, totalRows) {
  return {
    type: 'FETCHED_ALL_SUBCATEGORIACOMPRA',
    subcategoriaCompra: subcategoriaCompra,
    totalRegistros: totalRows
  }
}

export function clear() {
  return {
    type: 'SUBCATEGORIACOMPRA_CLEAR'
  }
}

export function clearAll() {
  return {
    type: 'SUBCATEGORIACOMPRA_CLEAR_ALL'
  }
}

export function handleOnChangeProps(props, value) {
  return {
    type: 'SUBCATEGORIACOMPRA_HANDLE_ON_CHANGE',
    props: props,
    value: value
  }
}

export function editSubcategoriasDetails(subcategoriaCompra) {
  return [
    { type: 'SUBCATEGORIACOMPRA_DETAIL', subcategoriaCompra },
  ]
}

export function updatedSubcategoriaInfo() {
  return {
    type: 'SUBCATEGORIACOMPRA_UPDATED'
  }
}

export function createSubcategoriaInfo() {
  return {
    type: 'SUBCATEGORIACOMPRA_CREATED_SUCCESSFULLY'
  }
}

export function deleteSubcategoriasDetails() {
  return {
    type: 'DELETED_SUBCATEGORIACOMPRA_DETAILS'
  }
}
export function isLoading() {
  return {
    type: 'SUBCATEGORIACOMPRA_ISLOADING'
  }
}

export function notIsLoading() {
  return {
    type: 'SUBCATEGORIACOMPRA_NOTISLOADING'
  }
}
