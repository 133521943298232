export const stringUtils = {
  serialize,
  toInputUppercase,
  toInputLowerCase,
  getFileNameFromUrl
};

function serialize(obj) {
  let str = '?' + Object.keys(obj).reduce(function (a, k) {
    a.push(k + '=' + encodeURIComponent(obj[k]));
    return a;
  }, []).join('&');
  return str;
}

function toInputUppercase(e) {
  var start = e.target.selectionStart;
  var end = e.target.selectionEnd;
  e.target.value = e.target.value.toUpperCase();
  e.target.setSelectionRange(start, end);
};

function toInputLowerCase(e) {
  var start = e.target.selectionStart;
  var end = e.target.selectionEnd;
  e.target.value = e.target.value.toLowerCase();
  e.target.setSelectionRange(start, end);
};

function getFileNameFromUrl(url) {
  var split = url.split('/')

  return split[split.length - 1]
}
