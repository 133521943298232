const initialState = {
  id: 0,
  isLoading: false,
  idCupom: 0,
  idProduto: 0,
  cupomProduto: [],
  idProdutoReferencia: 0,
  quantidade: 0,
  valor: ''
}

export function cupomProduto(state = initialState, action) {
  switch (action.type) {     
    case 'EDIT_CUPOMPRODUTO_REQUEST':
      return {
        ...state,
        loading: true
      }
    case 'EDIT_CUPOMPRODUTO_SUCCESS':
      return {
        ...state,
        loading: false,
        id: action.cupomProduto.id,
        idCupom: action.cupomProduto.idCupom,
        idProduto: action.cupomProduto.idProduto,
        idProdutoReferencia: action.cupomProduto.idProdutoReferencia,
        quantidade: action.cupomProduto.quantidade,
        valor: action.cupomProduto.valor
      }
    case 'EDIT_CUPOMPRODUTO_FAILURE':
      return {
        ...state,
        loading: false,
        error: action.error
      }
    case 'FETCHED_ALL_CUPOMPRODUTO':
      return {
        ...state,
        cupomProduto: action.cupomProduto,
        totalRegistros: action.totalRegistros
      }
    case 'CUPOMPRODUTO_DETAIL':
      return {
        ...state,
        id: action.id,
        isLoading: false,
        idCupom: action.idCupom,
        idProduto: action.idProduto,
        idProdutoReferencia: action.idProdutoReferencia,
        quantidade: action.quantidade,
        valor: action.valor
      }
    case 'CUPOMPRODUTO_CLEAR':
      return initialState
    case 'CUPOMPRODUTO_CLEAR_ALL':
      return {
        ...state,
        cupomProduto: []
      }
    case 'CUPOMPRODUTO_ESTABELECIMENTO_ADD_SUCCESS':
      return {
        ...state,
        cupomProduto: [...state.cupomProduto, action.data.cupomProduto]
      }
    case 'CUPOMPRODUTO_HANDLE_ON_CHANGE':
      return {
        ...state,
        [action.props]: action.value
      }
    case 'CUPOMPRODUTO_ISLOADING':
      return {
        ...state,
        isLoading: true
      }
    case 'CUPOMPRODUTO_NOTISLOADING':
      return {
        ...state,
        isLoading: false
      }
    default:
      return state
  }
}
