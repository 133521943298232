import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/styles'
import {
  Card,
  Typography,
  Grid
} from '@material-ui/core'

import { Skeleton } from '@material-ui/lab'

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: 10
  },
  content: {
    padding: 0
  },
  item: {
    padding: theme.spacing(2),
    textAlign: 'center',
    [theme.breakpoints.up('md')]: {
      '&:not(:last-of-type)': {
        borderRight: `1px solid ${theme.palette.divider}`
      }
    },
    [theme.breakpoints.down('sm')]: {
      '&:not(:last-of-type)': {
        borderBottom: `1px solid ${theme.palette.divider}`
      }
    }
  },
  titleWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  label: {
    marginLeft: theme.spacing(1),
    marginTop: theme.spacing(1)
  },
  h2: {
    paddingTop: theme.spacing(1)
  },
  header: {
    marginBottom: theme.spacing(-7)
  }
}))

const localeFormat = (value) => {
  if (!value) {
    value = 0;
  }
  return value.toLocaleString('pt-br', { minimumFractionDigits: 2 })  
}

const StatisticsCustos = (props) => {
  const { className, dashboardHome, idLoja, isLoading, ...rest } = props
  const classes = useStyles()

  const alertMessage = idLoja === '' ? 'Selecione uma loja para visualizar seu relatório' :
                     (!dashboardHome ? 'Não há registros de compras cadastrados para essa loja no momento' : '')

  return (
    <>
      {idLoja !== '' && dashboardHome ? (
        <Card {...rest} className={clsx(classes.root, className)}>
          <Grid alignItems="center" container justifyContent="space-between">
            <Grid className={classes.item} item md={3} sm={6} xs={12}>
              <Typography gutterBottom variant="overline">
                Compras
              </Typography>
              <Typography className={classes.h2} variant="h2">
                {isLoading ? <Skeleton /> : 'R$ ' + localeFormat(dashboardHome.comprasTotal)}
              </Typography>
            </Grid>
            <Grid className={classes.item} item md={3} sm={6} xs={12}>
              <Typography gutterBottom variant="overline">
                Faturamento
              </Typography>
              <Typography className={classes.h2} variant="h2">
                {isLoading ? <Skeleton /> : 'R$ ' + localeFormat(dashboardHome.faturamento)}
              </Typography>
            </Grid>
            <Grid className={classes.item} item md={3} sm={6} xs={12}>
              <Typography gutterBottom variant="overline">
                CPV Realizado
              </Typography>
              <Typography className={classes.h2} variant="h2">
                {isLoading ? <Skeleton /> : localeFormat(dashboardHome.cpvRealizado) + '%'}
              </Typography>
            </Grid>
            <Grid className={classes.item} item md={3} sm={6} xs={12}>
              <Typography gutterBottom variant="overline">
                CPV Teórico
              </Typography>
              <Typography className={classes.h2} variant="h2">
                {isLoading ? <Skeleton /> : 'Não definido'}
              </Typography>
            </Grid>
            <Grid className={classes.item} item md={3} sm={6} xs={12}>
              <Typography gutterBottom variant="overline">
                Oportunidade de Economia<br/>(Preço Médio no estado)
              </Typography>
              <Typography className={classes.h2} variant="h2">
                {isLoading ? <Skeleton /> : 'R$ ' + localeFormat(dashboardHome.mediaPrecoEstado)}
              </Typography>
            </Grid>
            <Grid className={classes.item} item md={3} sm={6} xs={12}>
              <Typography gutterBottom variant="overline">
                Oportunidade de Economia<br/>(Menor Preço no estado)
              </Typography>
              <Typography className={classes.h2} variant="h2">
                {isLoading ? <Skeleton /> : 'R$ ' + localeFormat(dashboardHome.menorPrecoEstado)}
              </Typography>
            </Grid>
            <Grid className={classes.item} item md={3} sm={6} xs={12}>
              <Typography gutterBottom variant="overline">
                Impacto no Lucro Operacional
              </Typography>
              <Typography className={classes.h2} variant="h2">
                {isLoading ? <Skeleton /> : localeFormat(dashboardHome.impactoLucroOperacional) + '%'}
              </Typography>
            </Grid>
            <Grid className={classes.item} item md={3} sm={6} xs={12}>
              <Typography gutterBottom variant="overline">
                Projetado no Resultado Anual
              </Typography>
              <Typography className={classes.h2} variant="h2">
                {isLoading ? <Skeleton /> : 'R$ ' + localeFormat(dashboardHome.resultadoAnualProjetado)}
              </Typography>
            </Grid>
          </Grid>
        </Card>
      ) : (
        <Card {...rest} className={clsx(classes.root, className)}>
          <Grid alignItems="center" container>
            <Grid className={classes.item} item md={12} sm={12} xs={12}>
              <Typography className={classes.h2} variant="h2">
                {alertMessage}
              </Typography>
            </Grid>
          </Grid>
        </Card>
      )}
    </>
  )
}

StatisticsCustos.propTypes = {
  className: PropTypes.string,
  dashboardHome: PropTypes.object.isRequired
}

export default StatisticsCustos
