import { crudService } from '../_services'
import { toast } from 'react-toastify'
import { compraAction, totvsInsumoAction } from '../../src/_actions'

export const totvsInsumoConversaoAction = {
  getTotvsInsumoConversaoById,
  createTotvsInsumoConversao,
  editTotvsInsumoConversaoInfo,
  deleteTotvsInsumoConversaoById,
  onChangeProps,
  clear,
  clearTotvsInsumoUnidadeConversoesFornecedores,
  clearAll
}

let filterModel = {
  Page: 1,
  Limit: 10,
  Term: ''
};

function getTotvsInsumoConversaoById(id) {
  return (dispatch) => {
    let apiEndpoint = 'totvsInsumosConversoes/' + id
    crudService.get(apiEndpoint).then((response) => {
      dispatch(editTotvsInsumoConversoesDetails(response.data))
    })
  }
}

function createTotvsInsumoConversao(payload, idCompra) {
  return (dispatch) => {
    dispatch(isLoading())
    const idToast = toast.loading("Atualizando conversões...")
    let apiEndpoint = 'totvsInsumosConversoes/'
    crudService.post(apiEndpoint, payload)
      .then((response) => {
        if (response.request != undefined && response.request.status === 200) {
          var ret = JSON.parse(response.request.responseText);
          toast.dismiss(idToast);
          dispatch(createTotvsInsumoConversaoInfo())
          toast.success('Feito! Cadastro realizado com sucesso! 😎')
          if (idCompra) {
            dispatch(compraAction.getCompraById(idCompra))
          }
          dispatch(totvsInsumoAction.getTotvsInsumoById(payload.idTotvsInsumo))
          dispatch(notIsLoading())
        }
      }).catch((err) => {
        toast.dismiss(idToast);
        dispatch(notIsLoading())
        toast.error('Oops! Erro ao cadastrar conversão! 😥')
        return err.response;
      })
  }
}

function editTotvsInsumoConversaoInfo(id, payload, redirect) {
  return (dispatch) => {
    if (redirect) {
      dispatch(isLoading())
    }
    const idToast = toast.loading("Atualizando conversões...")
    let apiEndpoint = 'totvsInsumosConversoes/' + id
    crudService.put(apiEndpoint, payload)
      .then((response) => {
        if (response.request != undefined && response.request.status === 200) {
          var ret = JSON.parse(response.request.responseText);

          toast.dismiss(idToast);
          dispatch(updatedTotvsInsumoConversaoInfo())

          if (redirect) {
            dispatch(clear())
            dispatch(notIsLoading())
          }
        }
      }).catch((err) => {
        toast.dismiss(idToast);
        dispatch(notIsLoading())
        toast.error('Oops! Erro ao atualizar conversão! 😥')
        return err.response;
      })
  }
}

function deleteTotvsInsumoConversaoById(id, idTotvsInsumo) {
  return (dispatch) => {
    dispatch(isLoading())
    let apiEndpoint = 'totvsInsumosConversoes/' + id
    crudService.deleteDetail(apiEndpoint).then(() => {
      dispatch(deleteTotvsInsumoConversoesDetails())
      dispatch(totvsInsumoAction.getTotvsInsumoById(idTotvsInsumo))
      dispatch(notIsLoading())
    })
  }
}

function onChangeProps(props, event) {
  return (dispatch) => {
    if (event.target.type === 'checkbox') {
      dispatch(handleOnChangeProps(props, event.target.checked))
    } else {
      dispatch(handleOnChangeProps(props, event.target.value))
    }
  }
}

export function addTotvsInsumoConversao(payload) {
  return [{ type: 'ADD_TOTVS_INSUMO_CONVERSAO', totvsInsumoConversao: payload }, clear()]
}

export function changeTotvsInsumoConversoesList(totvsInsumoConversao, totalRows) {
  return {
    type: 'FETCHED_ALL_TOTVS_INSUMO_CONVERSAO',
    totvsInsumoConversao: totvsInsumoConversao,
    totalRegistros: totalRows
  }
}

export function clear() {
  return {
    type: 'TOTVS_INSUMO_CONVERSAO_CLEAR'
  }
}

export function clearTotvsInsumoUnidadeConversoesFornecedores() {
  return {
    type: 'TOTVS_INSUMO_CONVERSAO_CLEAR_TOTVS_INSUMO_CONVERSAOS_FORNECEDORES'
  }
}

export function clearAll() {
  return {
    type: 'TOTVS_INSUMO_CONVERSAO_CLEAR_ALL'
  }
}

export function handleOnChangeProps(props, value) {
  return {
    type: 'TOTVS_INSUMO_CONVERSAO_HANDLE_ON_CHANGE',
    props: props,
    value: value
  }
}

export function editTotvsInsumoConversoesDetails(totvsInsumoConversao) {
  return {
    type: 'TOTVS_INSUMO_CONVERSAO_DETAIL',
    id: totvsInsumoConversao.id,
    idTotvsInsumo: totvsInsumoConversao.idTotvsInsumo,
    idCategoriaCompra: totvsInsumoConversao.idCategoriaCompra,
    idSubcategoriaCompra: totvsInsumoConversao.idSubcategoriaCompra,
    totvsInsumo: totvsInsumoConversao.totvsInsumo,
    campo: totvsInsumoConversao.campo,
    tipo: totvsInsumoConversao.tipo,
    texto: totvsInsumoConversao.texto,
    campo2: totvsInsumoConversao.campo2,
    tipo2: totvsInsumoConversao.tipo2,
    texto2: totvsInsumoConversao.texto2,
    conversao: totvsInsumoConversao.conversao,
    prioridade: totvsInsumoConversao.prioridade,
    tipoValor: totvsInsumoConversao.tipoValor,
    excluido: totvsInsumoConversao.excluido,
  }
}

export function updatedTotvsInsumoConversaoInfo() {
  return {
    type: 'TOTVS_INSUMO_CONVERSAO_UPDATED'
  }
}

export function createTotvsInsumoConversaoInfo() {
  return {
    type: 'TOTVS_INSUMO_CONVERSAO_CREATED_SUCCESSFULLY'
  }
}

export function deleteTotvsInsumoConversoesDetails() {
  return {
    type: 'DELETED_TOTVS_INSUMO_CONVERSAO_DETAILS'
  }
}
export function isLoading() {
  return {
    type: 'TOTVS_INSUMO_CONVERSAO_ISLOADING'
  }
}

export function notIsLoading() {
  return {
    type: 'TOTVS_INSUMO_CONVERSAO_NOTISLOADING'
  }
}

export function isModalOpen() {
  return {
    type: 'TOTVS_INSUMO_CONVERSAO_ISMODALOPEN'
  }
}

export function notIsModalOpen() {
  return {
    type: 'TOTVS_INSUMO_CONVERSAO_NOTISMODALOPEN'
  }
}
