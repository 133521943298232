const initialState = {
  isLoading: false,
  totalRegistros: 0,
  respostaChecklistOperacao: [],
  id: 0,
  idChecklistOperacao: 0,
  nomeChecklistOperacao: '',
  nomeUsuario: '',
  dataHora: '',
  idUsuario: 0,
  idLoja: 0,
  excluido: false,
  respostasChecklistsOperacaoProducao: [],
  respostasChecklistsOperacaoTemperatura: [],
  respostasChecklistsOperacaoCheck: []
}

export function respostaChecklistOperacao(state = initialState, action) {
  switch (action.type) {
    case 'FETCHED_ALL_RESPOSTA_CHECKLIST_OPERACAO':
      return {
        ...state,
        respostaChecklistOperacao: action.respostaChecklistOperacao,
        totalRegistros: action.totalRegistros
      }
    case 'RESPOSTA_CHECKLIST_OPERACAO_DETAIL':
      return {
        ...state,
        isLoading: false,
        id: action.id,
        idChecklistOperacao: action.idChecklistOperacao,
        nomeChecklistOperacao: action.nomeChecklistOperacao,
        nomeUsuario: action.nomeUsuario,
        dataHora: action.dataHora,
        idUsuario: action.idUsuario,
        idLoja: action.idLoja,
        excluido: action.excluido,
        respostasChecklistsOperacaoProducao: action.respostasChecklistsOperacaoProducao,
        respostasChecklistsOperacaoTemperatura: action.respostasChecklistsOperacaoTemperatura,
        respostasChecklistsOperacaoCheck: action.respostasChecklistsOperacaoCheck
      }
    case 'RESPOSTA_CHECKLIST_OPERACAO_UPDATED':
      return state
    case 'RESPOSTA_CHECKLIST_OPERACAO_CLEAR':
      return initialState
    case 'RESPOSTA_CHECKLIST_OPERACAO_CLEAR_ALL':
      return {
        ...state,
        respostaChecklistOperacao: []
      }
    case 'RESPOSTA_CHECKLIST_OPERACAO_CLEAR_RESPOSTASCHECKLISTSOPERACAOPRODUCAO':
      return {
        ...state,
        respostasChecklistsOperacaoProducao: []
      }
    case 'RESPOSTA_CHECKLIST_OPERACAO_CLEAR_RESPOSTASCHECKLISTSOPERACAOTEMPERATURA':
      return {
        ...state,
        respostasChecklistsOperacaoTemperatura: []
      }
    case 'RESPOSTA_CHECKLIST_OPERACAO_CLEAR_RESPOSTASCHECKLISTSOPERACAOCHECK':
      return {
        ...state,
        respostasChecklistsOperacaoCheck: []
      }
    case 'RESPOSTA_CHECKLIST_OPERACAO_HANDLE_ON_CHANGE':
      return {
        ...state,
        [action.props]: action.value
      }
    case 'RESPOSTA_CHECKLIST_OPERACAO_ISLOADING':
      return {
        ...state,
        isLoading: true
      }
    case 'RESPOSTA_CHECKLIST_OPERACAO_NOTISLOADING':
      return {
        ...state,
        isLoading: false
      }
    default:
      return state
  }
}
