const initialState = {
  isLoading: false,
  totalRegistros: 0,
  checklist: [],
  open: false,
  id: 0,
  descricao: '',
  idCategoriaInsumo: 0,
  tipo: '',
  checklistsLojas: [],
  checklistsTotvsInsumosUnidades: [],
  excluido: false,
  page: 0,
  limit: 10,
  order: 'nome',
  direction: 'asc',
  term: ''
}

export function checklist(state = initialState, action) {
  switch (action.type) {
    case 'FETCHED_ALL_CHECKLIST':
      return {
        ...state,
        checklist: action.checklist,
        totalRegistros: action.totalRegistros
      }
    case 'CHECKLIST_DETAIL':
      return {
        ...state,
        isLoading: false,
        id: action.id,
        descricao: action.descricao,
        idCategoriaInsumo: action.idCategoriaInsumo,
        tipo: action.tipo,
        checklistsLojas: action.checklistsLojas,
        checklistsTotvsInsumosUnidades: action.checklistsTotvsInsumosUnidades,
        excluido: action.excluido,
      }
    case 'CHECKLIST_UPDATED':
      return state
    case 'CHECKLIST_CLEAR':
      return {
        ...initialState,
        page: state.page,
        limit: state.limit,
        order: state.order,
        direction: state.direction,
        term: state.term
      }
    case 'CHECKLIST_CLEAR_ALL':
      return {
        ...state,
        checklist: []
      }
    case 'CHECKLIST_CLEAR_LOJAS':
      return {
        ...state,
        checklistsLojas: []
      }
    case 'CHECKLIST_CLEAR_TOTVS_INSUMOS_UNIDADES':
      return {
        ...state,
        checklistsTotvsInsumosUnidades: []
      }
    case 'CHECKLIST_HANDLE_ON_CHANGE':
      return {
        ...state,
        [action.props]: action.value
      }
    case 'CHECKLIST_ISLOADING':
      return {
        ...state,
        isLoading: true
      }
    case 'CHECKLIST_NOTISLOADING':
      return {
        ...state,
        isLoading: false
      }
    default:
      return state
  }
}
