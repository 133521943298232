const initialState = {
  totalRegistros: 0,
  fidelizacao: [],
  open: false,
  id: 0,
  dataHora: '',
  idCliente: 0,
  idCupom: 0,
  telefone: '',
  excluido: false,
  cupom: null
}

export function fidelizacao(state = initialState, action) {
  switch (action.type) {
    case 'FETCHED_ALL_FIDELIZACAO':
      return {
        ...state,
        fidelizacao: action.fidelizacao,
        totalRegistros: action.totalRegistros
      }
    case 'FIDELIZACAO_DETAIL':
      return action.fidelizacao
    case 'FIDELIZACAO_UPDATED':
      return state
    case 'FIDELIZACAO_CLEAR':
      return initialState
    case 'FIDELIZACAO_CLEAR_ALL':
      return {
        ...state,
        fidelizacao: []
      }
    case 'FIDELIZACAO_HANDLE_ON_CHANGE':
      return {
        ...state,
        [action.props]: action.value
      }
    default:
      return state
  }
}
