const initialState = {
  totalRegistros: 0,
  isModalOpen: false,
  isLoading: false,
  cliente: [],
  open: false,
  id: '',
  usuario: null,
  idEstado: null,
  idCidade: null,
  cep: '',
  bairro: '',
  caminhoImagem: '',
  dataNascimento: '',
  clientesTelefones: null,
  cpf: '',
  sexo: '',
  pontuacao: 0,
  pontuacaoTotal: 0,
  openModalClientesTransferirPontos: false,
  openModalClientesAdicionarPontosManualmente: false,
  page: 0,
  limit: 10,
  order: 'nome',
  direction: 'asc',
  term: '',
  chaveCupomTerm: ''
}

export function cliente(state = initialState, action) {
  switch (action.type) {
    case 'CREATE_CLIENTE_REQUEST':
      return {
        ...state,
        isLoading: true
      }
    case 'CREATE_CLIENTE_SUCCESS':
      return {
        ...state,
        isLoading: false,
        id: action.cliente.id,
        usuario: action.cliente.usuario,
        idEstado: action.cliente.idEstado,
        idCidade: action.cliente.idCidade,
        imagem: action.cliente.imagem,
        caminhoImagem: action.cliente.caminhoImagem,
        cep: action.cliente.cep,
        bairro: action.cliente.bairro,
        clientesTelefones: action.cliente.clientesTelefones,
        dataNascimento: action.cliente.dataNascimento,
        cpf: action.cliente.cpf,
        sexo: action.sexo,
        pontuacao: action.cliente.pontuacao,
        pontuacaoTotal: action.cliente.pontuacaoTotal
      }
    case 'CREATE_CLIENTE_FAILURE':
      return {
        ...state,
        isLoading: false,
        error: action.error
      }
    case 'CLIENTE_UPDATED':
      return state
    case 'EDIT_CLIENTE_REQUEST':
      return {
        ...state,
        isLoading: true
      }
    case 'EDIT_CLIENTE_SUCCESS':
      return {
        ...state,
        isLoading: false,
        id: action.cliente.id,
        nome: action.cliente.usuario.nome,
        sobrenome: action.cliente.usuario.sobrenome,
        email: action.cliente.usuario.email,
        idEstado: action.cliente.idEstado,
        idCidade: action.cliente.idCidade,
        caminhoImagem: action.cliente.usuario.caminhoImagem,
        cep: action.cliente.cep,
        bairro: action.cliente.bairro,
        clientesTelefones: action.cliente.clientesTelefones,
        dataNascimento: action.cliente.dataNascimento,
        cpf: action.cliente.cpf,
        sexo: action.sexo,
        pontuacao: action.cliente.pontuacao,
        pontuacaoTotal: action.cliente.pontuacaoTotal
      }
    case 'EDIT_CLIENTE_FAILURE':
      return {
        ...state,
        isLoading: false,
        error: action.error
      }
    case 'FETCHED_ALL_CLIENTE':
      return {
        ...state,
        cliente: action.cliente,
        totalRegistros: action.totalRegistros
      }
    case 'CLIENTE_DETAIL':
      return {
        ...state,
        id: action.id,
        isLoading: false,
        usuario: action.usuario,
        idCidade: action.idCidade,
        idEstado: action.idEstado,
        endereco: action.endereco,
        cep: action.cep,
        bairro: action.bairro,
        caminhoImagem: action.caminhoImagem,
        clientesTelefones: action.clientesTelefones,
        dataNascimento: action.dataNascimento,
        cpf: action.cpf,
        sexo: action.sexo,
        pontuacao: action.pontuacao,
        pontuacaoTotal: action.pontuacaoTotal
      }
    case 'CLIENTE_CLEAR':
      return {
        ...initialState,
        page: state.page,
        limit: state.limit,
        order: state.order,
        direction: state.direction,
        term: state.term,
        chaveCupomTerm: state.chaveCupomTerm
      }
    case 'CLIENTE_ESTABELECIMENTO_ADD_SUCCESS':
      return {
        ...state,
        cliente: [...state.cliente, action.data.cliente]
      }
    case 'CLIENTE_ISLOADING':
      return {
        ...state,
        isLoading: true
      }
    case 'OPEN_MODAL_CLIENTES_TRANSFERIR_PONTOS':
      return {
        ...state,
        openModalClientesTransferirPontos: action.open
      }
    case 'OPEN_MODAL_CLIENTES_ADICIONAR_PONTOS_MANUALMENTE':
      return {
        ...state,
        openModalClientesAdicionarPontosManualmente: action.open
      }
    case 'OPEN_MODAL_CLIENTES_ADICIONAR_TROCAS_MANUALMENTE':
      return {
        ...state,
        openModalClientesAdicionarTrocasManualmente: action.open
      }
    case 'CLIENTE_NOTISLOADING':
      return {
        ...state,
        isLoading: false
      }
    case 'CLIENTE_HANDLE_ON_CHANGE':
      return {
        ...state,
        [action.props]: action.value
      }
    case 'CLIENTE_ISMODALOPEN':
      return {
        ...state,
        isModalOpen: true
      }
    case 'CLIENTE_NOTISMODALOPEN':
      return {
        ...state,
        isModalOpen: false
      }
    default:
      return state
  }
}
