import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Tooltip,
  Typography
} from '@material-ui/core'
import {
  TextValidator,
  ValidatorForm
} from 'react-material-ui-form-validator'
import { metaAction } from '../../_actions'
import EmailIcon from '@material-ui/icons/Email';
import HelpIcon from '@material-ui/icons/Help'
import { StickyContainer, Sticky } from 'react-sticky';
import { AccessControl } from '../../components/Utils/AccessControl'
import moment from 'moment'

const styles = {
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4
};

export const MetaModal = ({ modal, toggle, props, parametro }) => {
  const dispatch = useDispatch()

  // Obter o primeiro dia do mês atual
  const primeiroDiaDoMes = new Date();
  primeiroDiaDoMes.setDate(1);
  const primeiroDiaFormatado = primeiroDiaDoMes.toISOString().split('T')[0];

  // Obter o dia de ontem
  const ontem = new Date();
  ontem.setDate(ontem.getDate() - 1);
  const ontemFormatado = ontem.toISOString().split('T')[0];

  const [dataInicio, setDataInicio] = useState(primeiroDiaFormatado)
  const [dataTermino, setDataTermino] = useState(ontemFormatado)
  const [email, setEmail] = useState("")

  const handleChangeDataInicio = (e) => {
    setDataInicio(e.target.value)
  }

  const handleChangeDataTermino = (e) => {
    setDataTermino(e.target.value)
  }

  const handleChangeEmail = (e) => {
    setEmail(e.target.value)
  }

  const isLoadingMetaModal = useSelector(
    (state) => state.meta.isLoading
  )

  const handleSubmit = (event) => {
    let payload = {
      DataInicio: moment(dataInicio).format("YYYY-MM-DD"),
      DataTermino: moment(dataTermino).format("YYYY-MM-DD"),
      Email: email ?? ''
    };

    dispatch(metaAction.sendMetaEmail(payload));
  };

  return (
    <div>
      <Dialog
        open={modal}
        onClose={toggle}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogTitle>
          <Grid alignItems="flex-end" container justifyContent="space-between" spacing={3}>
            <Grid item>
              <Typography style={{ fontSize: "16px", fontWeight: 'bold', textAlign: "left", marginRight: 5 }}>
                ENVIAR E-MAIL DE METAS
              </Typography>
            </Grid>
          </Grid>
        </DialogTitle>
        <ValidatorForm
          id="formMetaEmail"
          onSubmit={(event) => handleSubmit(event)}
        >
          <DialogContent sx={styles}>
            <Grid container spacing={2}>
              <Grid item lg={12} md={12} xl={12} xs={12}>
              
                  <Grid container spacing={2}>
                    <Grid item lg={6} md={6} xl={6} xs={12}>
                      <TextValidator
                        InputLabelProps={{ shrink: true }}
                        fullWidth
                        id="dataInicio"
                        label="Data Inicial *"
                        margin="normal"
                        type="date"
                        value={dataInicio}
                        onChange={handleChangeDataInicio}
                        validators={['required']}
                        errorMessages={['Campo obrigatório!']}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item lg={6} md={6} xl={6} xs={12}>
                      <TextValidator
                        InputLabelProps={{ shrink: true }}
                        fullWidth
                        id="dataTermino"
                        label="Data Término *"
                        margin="normal"
                        type="date"
                        value={dataTermino}
                        onChange={handleChangeDataTermino}
                        validators={['required']}
                        errorMessages={['Campo obrigatório!']}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item lg={11} md={11} xl={11} xs={11}>
                      <TextValidator
                        fullWidth
                        id="email"
                        label="E-mail"
                        margin="normal"
                        value={email}
                        onChange={handleChangeEmail}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item lg={1} md={1} xl={1} xs={1} style={{ display: 'flex', alignItems: 'center' }}>
                      <Tooltip
                        title="Ao não preencher o campo E-mail, o destinatário será o e-mail da própria loja."
                      >
                        <HelpIcon />
                      </Tooltip>
                    </Grid>
                  </Grid>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <StickyContainer>
              <Sticky relative={false} isSticky={true}>
                {({ style }) => (
                  <Grid>
                    <AccessControl
                      rule={'metas.create'} //permissão necessária para acessar conteúdo
                      yes={() => (
                        <Grid item lg={12} md={12} xl={12} xs={12} style={{
                          display: 'flex',
                          alignItems: 'center',
                          flexDirection: 'row',
                          paddingRight: 0,
                          marginRight: 0
                        }}>
                          <Button
                            color="default"
                            onClick={toggle}
                            variant="contained"
                            style={{
                              justifyContent: 'right'
                            }}
                          >
                            Cancelar
                          </Button>
                          <Button
                            color="primary"
                            type="submit"
                            variant="contained"
                            style={{
                              justifyContent: 'right',
                              marginRight: 0
                            }}
                            disabled={isLoadingMetaModal}
                            startIcon={<EmailIcon />}
                            endIcon={isLoadingMetaModal && <CircularProgress size={24} />}
                          >
                            {isLoadingMetaModal ? 'Enviando...' : 'Enviar'}
                          </Button>
                        </Grid>
                      )}
                    />
                  </Grid>
                )}
              </Sticky>
            </StickyContainer>
          </DialogActions>
        </ValidatorForm>
      </Dialog>
    </div>
  );
}
