import React, { useEffect } from 'react';
import { connect, useDispatch, useSelector } from "react-redux";
import { withRouter } from 'react-router-dom';
import { PDFViewer, Document, Page, View, Text, StyleSheet } from '@react-pdf/renderer';
import { Header, Footer } from '../../_reports';
import moment from 'moment'

const styles = StyleSheet.create({
  page: {
    padding: 20,
    paddingBottom: 25
  },
  row: {
    flexDirection: 'row',
    fontSize: 7,
    fontFamily: 'Roboto-Regular',
    borderTop: 1,
    borderLeft: 1,
    borderRight: 1,
    borderBottom: 1
  },
  header: {
    borderRight: 1,
    padding: 5,
    fontFamily: 'Roboto-Bold',
    textAlign: 'center'
  },
  cell: {
    borderRight: 1,
    padding: 3,
    textAlign: 'center'
  }
});

const RelatorioTrocaPrint = (props) => {

  const dispatch = useDispatch();
  const [ready, setReady] = React.useState(false);
  const { match: { params } } = props;
  const { relatorio } = props.relatorio

  useEffect(() => {
  
  }, [props.relatorio]);

  useEffect(() => {
    setTimeout(() => {
      setReady(true);
    }, 1100);
  }, [])

  const onRendered = () => {
  };

  return (
    (ready &&
      <div style={{ width: '100%', height: '100%' }}>
        <PDFViewer height="100%" width="100%" >
          <Document onRender={onRendered}>
            <Page orientation="landscape" size="A4" style={styles.page} wrap={true} >

              <Header title='Relatório de Trocas por Período' />

              <View style={[styles.row, { marginTop: 40 }]} key={0} >
                <View style={[styles.header, { width: 100 }]}>
                  <Text>Data/Hora</Text>
                </View>
                <View style={[styles.header, { width: 200 }]}>
                  <Text>Loja</Text>
                </View>
                <View style={[styles.header, { width: 80 }]}>
                  <Text>Voucher</Text>
                </View>
                <View style={[styles.header, { width: 150 }]}>
                  <Text>Produto</Text>
                </View>
                <View style={[styles.header, { width: 80 }]}>
                  <Text>Status</Text>
                </View>
                <View style={[styles.header, { width: 220 }]}>
                  <Text>Nome do Cliente</Text>
                </View>
              </View>

              {
                relatorio.length > 0 ? relatorio.map(n => {
                  var retorno = (
                    <View style={[styles.row]} key={n.id} >
                      <View style={[styles.cell, { width: 100 }]}>
                        <Text>{n.data ? moment(n.data).format("DD/MM/YYYY") : ''}</Text>
                      </View>
                      <View style={[styles.cell, { width: 200 }]}>
                        <Text>{n.loja ? n.loja : ''}</Text>
                      </View>
                      <View style={[styles.cell, { width: 80 }]}>
                        <Text>{n.voucher ? n.voucher : ''}</Text>
                      </View>
                      <View style={[styles.cell, { width: 150 }]}>
                        <Text>{n.descricaoProduto ? n.descricaoProduto : ''}</Text>
                      </View>
                      <View style={[styles.cell, { width: 80 }]}>
                        <Text>{n.status && n.status == true || n.status == null ? 'PROCESSADO' : 'PENDENTE'}</Text>
                      </View>                     
                      <View style={[styles.cell, { width: 220 }]}>
                        <Text>{n.nomeCliente ? n.nomeCliente : ''}</Text>
                      </View>
                    </View>
                  );
                  return retorno;
                })
                  : <View style={[styles.row]} key={0} >
                      <View style={[styles.cell, { width: 800 }]}>
                      <Text>Nenhum registro encontrado</Text>
                      </View>
                    </View>
              }
              <Footer />
            </Page>
          </Document>
        </PDFViewer>
      </div>
    )
  );
}

const mapStateToProps = (state) => {
  return state;
};

const connectedRelatorioTrocaPrintPage = withRouter(connect(mapStateToProps, null, null, {
  pure: false
})(RelatorioTrocaPrint));
export { connectedRelatorioTrocaPrintPage as RelatorioTrocaPrint };
