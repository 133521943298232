const initialState = {
  isLoading: false,
  totalRegistros: 0,
  troca: [],
  open: false,
  id: 0,
  dataHora: '',
  voucher: '',
  idCliente: 0,
  idProduto: 0,
  idLoja: 0,
  excluido: false,
  loja: null,
  produto: null
}

export function troca(state = initialState, action) {
  switch (action.type) {
    case 'FETCHED_ALL_TROCA':
      return {
        ...state,
        troca: action.troca,
        totalRegistros: action.totalRegistros
      }
    case 'TROCA_DETAIL':
      return action.troca
    case 'TROCA_UPDATED':
      return state
    case 'TROCA_CLEAR':
      return initialState
    case 'TROCA_CLEAR_ALL':
      return {
        ...state,
        troca: []
      }
    case 'TROCA_HANDLE_ON_CHANGE':
      return {
        ...state,
        [action.props]: action.value
      }
    case 'TROCA_ISLOADING':
      return {
        ...state,
        isLoading: true
      }
    case 'TROCA_NOTISLOADING':
      return {
        ...state,
        isLoading: false
      }
    default:
      return state
  }
}
