import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Typography from '@material-ui/core/Typography'
import clsx from 'clsx'
import {
  Backdrop,
  Badge,
  Button,
  Card,
  CardContent,
  Grid,
  Table,
  TableContainer,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from '@material-ui/core'
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import { totvsInsumoUnidadeAction, fornecedorAction } from '../../_actions'
import CloseIcon from '@material-ui/icons/Close';
import { useConfirm } from 'material-ui-confirm'
import SemFoto from '../../img/sem_foto.png'

const styles = (theme) => ({
  // bgcolor: 'background.paper',
  // border: '2px solid #000',
  //  boxShadow: 24,
  p: 2,
  backdrop: {
    //  zIndex: theme.zIndex.drawer + 1,
    //  color: '#fff'
  },
});

export const InsumoUnidadeModal = ({ modal, toggle, props, id }) => {

  const confirm = useConfirm()
  const dispatch = useDispatch()
  const { classes, className, ...rest } = props
  const { match: { params } } = props;

  const [open, setOpen] = useState(false)
  const [imagem, setImagem] = useState('')
  const [, forceUpdate] = React.useState(0);
  const [ready, setReady] = React.useState(false);

  useEffect(() => {

    dispatch(totvsInsumoUnidadeAction.clearTotvsInsumoUnidadesFornecedores())

    if (id) {
      dispatch(totvsInsumoUnidadeAction.getTotvsInsumoUnidadeVisualizacao(id))

      dispatch(fornecedorAction.getFornecedorSelect())

    } else {
      dispatch(totvsInsumoUnidadeAction.clear())
      dispatch(totvsInsumoUnidadeAction.clearTotvsInsumoUnidadesFornecedores())
    }

  }, []);

  useEffect(() => {

  }, [ready, props.totvsInsumoUnidade.isLoading])

  useEffect(() => {
    setImagem(props.totvsInsumoUnidade.caminhoImagem)
  }, [props.totvsInsumoUnidade.caminhoImagem, open]);

  useEffect(() => {
    forceUpdate(n => !n);
  }, [open, imagem]);

  const handleClose = () => {
    toggle();
  }

  return (
    <Dialog
      open={modal}
      fullWidth
      maxWidth='md'
      onClose={toggle}
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
    >
      <DialogTitle>
        <Grid alignItems="flex-end" container justifyContent="space-between" spacing={1}>
          <Grid item>
            <Typography style={{ fontSize: "16px", fontWeight: 'bold', textAlign: "left", marginRight: 5 }}>
              CARACTERÍSTICAS DA UNIDADE DE INSUMO
            </Typography>
          </Grid>
          <Grid item>
            <Grid item xs={6} align="right">
              <Button
                color="default"
                variant="contained"
                endIcon={<CloseIcon />}
                onClick={() => handleClose()}
              >
                Fechar
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item lg={2} md={2} xl={2} xs={12}>
            <Grid {...rest} className={clsx(classes.root, className)}>
              <Grid className={classes.content}>
                {imagem != null ?
                  <img
                    src={imagem}
                    alt={imagem}
                    style={{ width: 70 }}
                  />
                  :
                  <img
                    src={SemFoto}
                    alt={SemFoto}
                    style={{ width: 70 }}
                  />
                }
              </Grid>
            </Grid>
          </Grid>
          <Grid item lg={10} md={10} xl={10} xs={12}>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell align="left">Nome</TableCell>
                    <TableCell align="left">Categoria</TableCell>
                    <TableCell align="left">Insumo</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow
                    hover
                    key={(props.totvsInsumoUnidade ? (props.totvsInsumoUnidade.id || '') : '')}>
                    <TableCell align="left">
                      {(props.totvsInsumoUnidade ? (props.totvsInsumoUnidade.nome || '').toUpperCase() : '-')}
                    </TableCell>
                    <TableCell align="left">
                      {(props.totvsInsumoUnidade.categoriaInsumo ? (props.totvsInsumoUnidade.categoriaInsumo.nome || '') : '-')}
                    </TableCell>
                    <TableCell align="left">
                      {(props.totvsInsumoUnidade.totvsInsumo ? (props.totvsInsumoUnidade.totvsInsumo.nome || '') : '-')}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell align="left">Marca Obrigatória</TableCell>
                    <TableCell align="left">Observações</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow
                    hover
                    key={(props.totvsInsumoUnidade ? (props.totvsInsumoUnidade.id || '-') : '')}>
                    <TableCell align="left">
                      {(props.totvsInsumoUnidade ? (props.totvsInsumoUnidade.marcaObrigatoria || '-') : '-')}
                    </TableCell>
                    <TableCell align="left">
                      {(props.totvsInsumoUnidade ? (props.totvsInsumoUnidade.observacoes || '-') : '-')}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow style={{ backgroundColor: '#FFF' }}>
                    <TableCell colspan={3} style={{ paddingLeft: '25%' }}>
                      <Typography color="textPrimary">
                        <b>PEDIDO</b>
                      </Typography>
                    </TableCell>
                    <TableCell colspan={2} style={{ paddingLeft: '15%' }}>
                      <Typography color="textPrimary">
                        <b>CONTAGEM</b>
                      </Typography>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableHead>
                  <TableRow>
                    <TableCell align="left">Unidade</TableCell>
                    <TableCell align="left">Itens por caixa/unidade</TableCell>
                    <TableCell align="left" style={{ paddingLeft: 0, marginLeft: 0 }}>Pedido mínimo</TableCell>
                    <TableCell align="left">Unidade</TableCell>
                    <TableCell align="left">Itens por caixa/unidade</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow
                    hover
                    key={(props.totvsInsumoUnidade ? (props.totvsInsumoUnidade.id || '') : '')}>
                    <TableCell align="left">
                      {(props.totvsInsumoUnidade && props.totvsInsumoUnidade.unidade ? (props.totvsInsumoUnidade.unidade.abreviacao || '').toUpperCase() : '')}
                    </TableCell>
                    <TableCell align="left">
                      {(props.totvsInsumoUnidade ? (props.totvsInsumoUnidade.quantidadeUnidade || '') : '')}
                    </TableCell>
                    <TableCell align="left" style={{ paddingLeft: 0, marginLeft: 0 }}>
                      {(props.totvsInsumoUnidade ? (props.totvsInsumoUnidade.pedidoMinimo ? props.totvsInsumoUnidade.pedidoMinimo : '-' || '') : '')}
                    </TableCell>
                    <TableCell align="left">
                      {(props.totvsInsumoUnidade && props.totvsInsumoUnidade.unidadeContagem ? (props.totvsInsumoUnidade.unidadeContagem.abreviacao || '').toUpperCase() : '')}
                    </TableCell>
                    <TableCell align="left">
                      {(props.totvsInsumoUnidade ? (props.totvsInsumoUnidade.quantidadeUnidadeContagem || '') : '')}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell align="left" width='100%'>Fornecedores vinculados</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow
                    key={(props.totvsInsumoUnidade ? (props.totvsInsumoUnidade.id || '') : '')}>
                    <div className="selectgroup selectgroup-pills" style={{ marginLeft: '5px', display: 'flex-row', alignItems: 'top' }}>
                      <Grid item lg={12} md={12} xl={12} xs={12}>
                        {
                          props.fornecedor.fornecedor != null && props.fornecedor.fornecedor.length > 0 &&
                          props.fornecedor.fornecedor.map(n => {
                            return (
                              (n.id != - '' && props.totvsInsumoUnidade.totvsInsumosUnidadesFornecedores.map(x => {
                                return (
                                  x.idFornecedor == n.value && x.excluido != true ?
                                    <Button style={{ color: '#000', cursor: 'default' }} key={'button' + n.id}>
                                      <Badge style={{ fontSize: 15, paddingLeft: 7, paddingRight: 7 }}>
                                        {n.text}
                                      </Badge>
                                    </Button>
                                    : null)
                              })
                              )
                            )
                          }
                          )
                        }
                      </Grid>
                    </div>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog >
  )
}
