import { history } from 'utils'
import { crudService } from '../_services'
import { stringUtils } from 'utils'
import { toast } from 'react-toastify'

export const pontuacaoAction = {
  getPontuacao,
  addPontuacao,
  createPontuacao,
  getPontuacaoById,
  onChangeProps,
  editPontuacoesDetails,
  deletePontuacaoById,
  clear,
  clearAll
}

let filterModel = {
  Page: 0,
  Limit: 10,
  Term: ''
};

function getPontuacao(filterModel, showWaiting) {
  return (dispatch) => {
    let apiEndpoint = 'pontuacoes' + stringUtils.serialize(filterModel);
    crudService
      .get(apiEndpoint)
      .then((response) => {
        dispatch(changePontuacoesList(response.data.items, response.data.totalRows))
      })
      .catch((err) => {
        console.log(err)
      })
  }
}

function createPontuacao(payload) {
  return (dispatch) => {
    let apiEndpoint = 'pontuacoes/'
    crudService.post(apiEndpoint, payload)
      .then((response) => {
        if (response.request != undefined && response.request.status === 200) {
          var ret = JSON.parse(response.request.responseText);
          dispatch(createPontuacaoInfo())
          toast.success('Feito! Pontos contabilizados com sucesso! 😎')

          let filterModel = {
            Page: 0,
            Limit: 10,
            Term: '',
            Direction: 'desc',
            IdCliente: payload.IdCliente
          };

          dispatch(getPontuacao(filterModel))
          dispatch(clear())
        } else {
          toast.err('Oops! Erro ao gerar pontuação! 😥')
        }
      }).catch((err) => {
        toast.err('Oops! Erro ao gerar pontuação! 😥')
        return err.response;
      })
  }
}

function getPontuacaoById(id) {
  return (dispatch) => {
    let apiEndpoint = 'pontuacoes/' + id
    crudService.get(apiEndpoint).then((response) => {
      // console.log('response.data', response.data)
      dispatch(editPontuacoesDetails(response.data))
    })
  }
}

function onChangeProps(props, event) {
  return (dispatch) => {
    if (event.target.type === 'checkbox') {
      dispatch(handleOnChangeProps(props, event.target.checked))
    } else {
      dispatch(handleOnChangeProps(props, event.target.value))
    }
  }
}

function deletePontuacaoById(filterModel, id) {
  return (dispatch) => {
    let apiEndpoint = 'pontuacoes/' + id
    crudService.deleteDetail(apiEndpoint).then(() => {
      dispatch(deletePontuacoesDetails())
      dispatch(getPontuacao(filterModel))
    })
  }
}

export function addPontuacao(payload) {
  return [{ type: 'ADD_PONTUACAO', pontuacao: payload }, clear()]
}

export function changePontuacoesList(pontuacao, totalRows) {
  return {
    type: 'FETCHED_ALL_PONTUACAO',
    pontuacao: pontuacao,
    totalRegistros: totalRows
  }
}

export function clear() {
  return {
    type: 'PONTUACAO_CLEAR'
  }
}

export function clearAll() {
  return {
    type: 'PONTUACAO_CLEAR_ALL'
  }
}

export function handleOnChangeProps(props, value) {
  return {
    type: 'PONTUACAO_HANDLE_ON_CHANGE',
    props: props,
    value: value
  }
}

export function editPontuacoesDetails(pontuacao) {
  return [
    { type: 'PONTUACAO_DETAIL', pontuacao },
  ]
}

export function updatedPontuacaoInfo() {
  return {
    type: 'PONTUACAO_UPDATED'
  }
}

export function createPontuacaoInfo() {
  return {
    type: 'PONTUACAO_CREATED_SUCCESSFULLY'
  }
}

export function deletePontuacoesDetails() {
  return {
    type: 'DELETED_PONTUACAO_DETAILS'
  }
}
