import React, { useEffect } from 'react'
import { connect, useDispatch, useSelector } from 'react-redux'
import { relatorioAction, totvsInsumoUnidadeAction, totvsInsumoConversaoAction } from '../../_actions'
import PropTypes from 'prop-types'
import {
  Button,
  Card,
  CardContent,
  CircularProgress,
  withStyles,
  Table,
  TableContainer,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Paper,
  Input,
  MenuItem,
  Grid
} from '@material-ui/core'
import {
  SelectValidator,
  TextValidator,
  ValidatorForm
} from 'react-material-ui-form-validator'
import Skeleton from "@material-ui/lab/Skeleton"
import { withRouter } from 'react-router-dom'
import TitleContentInformation from '../TitleContentInformation'
import { AccessControl } from '../../components/Utils/AccessControl'
import AccessDenied from '../Errors/AccessDenied'
import { Page } from 'components'
import SearchIcon from '@material-ui/icons/Search'
import ConversoesCollapsableRow from './conversoesCollapsableRow.component'
import { apenasNumero, cnpjMask } from '../../utils'

const styles = (theme) => ({
  root: {
    padding: theme.spacing(2)
  }
})

const RelatorioConversoes = (props) => {

  const dispatch = useDispatch()
  const { classes, className, ...rest } = props
  const { relatorio, isLoading } = props.relatorio
  const { totalRows } = relatorio

  const totvsInsumoConversao = useSelector(state => state.totvsInsumoConversao)
  const { idLoja } = useSelector(state => state.usuarioLoja)

  useEffect(() => {
    dispatch(relatorioAction.clear());
    dispatch(totvsInsumoUnidadeAction.getTotvsInsumoUnidadeSelect())
  }, [])

  const handleChange = (prop) => (event) => {
    dispatch(totvsInsumoConversaoAction.onChangeProps(prop, event))
  }

  const handleSubmit = (event) => {

    dispatch(relatorioAction.clear());

    if (totvsInsumoConversao.campo2 || totvsInsumoConversao.tipo2 || totvsInsumoConversao.texto2) {

      let payload = {
        idTotvsInsumo: 0,
        campo: totvsInsumoConversao.campo,
        tipo: totvsInsumoConversao.tipo,
        texto: totvsInsumoConversao.campo == 'CNPJFornecedor' ? apenasNumero(totvsInsumoConversao.texto) : totvsInsumoConversao.texto,
        campo2: totvsInsumoConversao.campo2 || null,
        tipo2: totvsInsumoConversao.tipo2 || null,
        texto2: totvsInsumoConversao.campo2 == 'CNPJFornecedor' ? apenasNumero(totvsInsumoConversao.texto2) || null : totvsInsumoConversao.texto2 || null,
        conversao: totvsInsumoConversao.conversao ?? 0
      }

      dispatch(relatorioAction.getRelatorioConversoes(payload))

    } else {

      let payload = {
        idTotvsInsumo: 0,
        campo: totvsInsumoConversao.campo,
        tipo: totvsInsumoConversao.tipo,
        texto: totvsInsumoConversao.campo == 'CNPJFornecedor' ? apenasNumero(totvsInsumoConversao.texto) : totvsInsumoConversao.texto,
        conversao: totvsInsumoConversao.conversao ?? 0
      }

      dispatch(relatorioAction.getRelatorioConversoes(payload))

    }

  };

  const handleClean = () => {
    dispatch(totvsInsumoConversaoAction.clear());
    dispatch(relatorioAction.clear());
  }

  return (
    <Page className={classes.root} title="Relatório Conversoes">
      <AccessControl
        rule={'relatorios.list'} //permissão necessária para acessar conteúdo
        yes={() => (
          <Grid
            {...rest}
            container
            spacing={2}
          >
            <Grid item lg={12} md={12} xl={12} xs={12}>
              <Card style={{ marginBottom: 10, marginTop: 0 }}>
                <ValidatorForm
                  id="formRelatorioConversoes"
                  onSubmit={(event) => handleSubmit(event)}
                >
                  <CardContent>
                    <TitleContentInformation
                      subTitle={'RELATÓRIO'}
                      title={'Conversões'}
                    />
                    <Grid container spacing={2} style={{ alignItems: 'center' }}>
                      <Grid item lg={4} md={4} xl={4} xs={12}>
                        <SelectValidator
                          validators={['required']}
                          errorMessages={['Campo obrigatório!']}
                          className={classes.textField}
                          label="Campo *"
                          labelId="campo"
                          value={totvsInsumoConversao.campo}
                          onChange={handleChange('campo')}
                          name="campo"
                          fullWidth
                          InputLabelProps={{
                            shrink: true
                          }}
                          inputProps={{
                            name: "Campo",
                            id: "campo"
                          }}
                          input={<Input id="campo" />}
                          margin="normal"
                          variant="outlined"
                          id="campo"
                        >
                          <MenuItem disabled value={''}>Campo</MenuItem>
                          <MenuItem value={'codigoProduto'}>CÓDIGO INTERNO DO PRODUTO</MenuItem>
                          <MenuItem value={'CNPJFornecedor'}>CNPJ DO FORNECEDOR</MenuItem>
                          <MenuItem value={'descricao'}>DESCRIÇÃO DO PRODUTO</MenuItem>
                          <MenuItem value={'GTIN/CEAN'}>GTIN/CEAN COMERCIAL</MenuItem>
                          <MenuItem value={'GTIN/CEANTributavel'}>GTIN/CEAN TRIBUTÁVEL</MenuItem>
                          <MenuItem value={'NCM'}>NCM</MenuItem>
                        </SelectValidator>
                      </Grid>
                      <Grid item lg={4} md={4} xl={4} xs={12}>
                        <SelectValidator
                          validators={['required']}
                          errorMessages={['Campo obrigatório!']}
                          className={classes.textField}
                          label="Tipo *"
                          labelId="tipo"
                          value={totvsInsumoConversao.tipo}
                          onChange={handleChange('tipo')}
                          name="tipo"
                          fullWidth
                          InputLabelProps={{
                            shrink: true
                          }}
                          inputProps={{
                            name: "Tipo",
                            id: "tipo"
                          }}
                          input={<Input id="tipo" />}
                          margin="normal"
                          variant="outlined"
                          id="tipo"
                        >
                          <MenuItem disabled value={''}>Tipo</MenuItem>
                          <MenuItem value={'igual'}>IGUAL</MenuItem>
                          <MenuItem value={'contem'}>CONTÉM</MenuItem>
                        </SelectValidator>
                      </Grid>
                      <Grid item lg={4} md={4} xl={4} xs={12}>
                        <TextValidator
                          className={classes.textField}
                          fullWidth
                          InputLabelProps={{
                            shrink: true
                          }}
                          id="texto"
                          label="Texto *"
                          validators={['required']}
                          errorMessages={['Campo obrigatório!']}
                          margin="normal"
                          onChange={handleChange('texto')}
                          value={totvsInsumoConversao.campo == 'CNPJFornecedor' ? cnpjMask(totvsInsumoConversao.texto) || '' : totvsInsumoConversao.texto || ''}
                          variant="outlined"
                        />
                      </Grid>
                      <Grid item lg={4} md={4} xl={4} xs={12}>
                        <SelectValidator
                          className={classes.textField}
                          label="Campo 2"
                          labelId="campo2"
                          value={totvsInsumoConversao.campo2}
                          onChange={handleChange('campo2')}
                          name="campo2"
                          fullWidth
                          InputLabelProps={{
                            shrink: true
                          }}
                          inputProps={{
                            name: "Campo 2",
                            id: "campo2"
                          }}
                          input={<Input id="campo2" />}
                          margin="normal"
                          variant="outlined"
                          id="campo2"
                        >
                          <MenuItem disabled value={''}>Campo 2</MenuItem>
                          <MenuItem value={'codigoProduto'}>CÓDIGO INTERNO DO PRODUTO</MenuItem>
                          <MenuItem value={'CNPJFornecedor'}>CNPJ DO FORNECEDOR</MenuItem>
                          <MenuItem value={'descricao'}>DESCRIÇÃO DO PRODUTO</MenuItem>
                          <MenuItem value={'GTIN/CEAN'}>GTIN/CEAN COMERCIAL</MenuItem>
                          <MenuItem value={'GTIN/CEANTributavel'}>GTIN/CEAN TRIBUTÁVEL</MenuItem>
                          <MenuItem value={'NCM'}>NCM</MenuItem>
                        </SelectValidator>
                      </Grid>
                      <Grid item lg={4} md={4} xl={4} xs={12}>
                        <SelectValidator
                          validators={(totvsInsumoConversao.campo2 ? ['required'] : null)}
                          errorMessages={(totvsInsumoConversao.campo2 ? ['Campo obrigatório!'] : null)}
                          className={classes.textField}
                          label="Tipo 2"
                          labelId="tipo2"
                          value={totvsInsumoConversao.tipo2}
                          onChange={handleChange('tipo2')}
                          name="tipo2"
                          fullWidth
                          InputLabelProps={{
                            shrink: true
                          }}
                          inputProps={{
                            name: "Tipo 2",
                            id: "tipo2"
                          }}
                          input={<Input id="tipo2" />}
                          margin="normal"
                          variant="outlined"
                          id="tipo2"
                        >
                          <MenuItem disabled value={''}>Tipo 2</MenuItem>
                          <MenuItem value={'igual'}>IGUAL</MenuItem>
                          <MenuItem value={'contem'}>CONTÉM</MenuItem>
                        </SelectValidator>
                      </Grid>
                      <Grid item lg={4} md={4} xl={4} xs={12}>
                        <TextValidator
                          validators={(totvsInsumoConversao.campo2 ? ['required'] : null)}
                          errorMessages={(totvsInsumoConversao.campo2 ? ['Campo obrigatório!'] : null)}
                          className={classes.textField}
                          fullWidth
                          InputLabelProps={{
                            shrink: true
                          }}
                          id="texto2"
                          label="Texto 2"
                          margin="normal"
                          onChange={handleChange('texto2')}
                          value={totvsInsumoConversao.texto2 || ''}
                          variant="outlined"
                        />
                      </Grid>
                      <Grid item lg={12} md={12} xl={12} xs={12} style={{
                        display: 'flex',
                        alignItems: 'right',
                        flexDirection: 'row',
                        textAlign: 'right',
                        justifyContent: 'right',
                        gap: 10
                      }}>
                        <Button
                          color="default"
                          onClick={() => handleClean()}
                          variant="contained"
                        >
                          Limpar
                        </Button>
                        <Button
                          color="primary"
                          type="submit"
                          variant="contained"
                          disabled={props.relatorio.isLoading}
                          startIcon={<SearchIcon />}
                          endIcon={props.relatorio.isLoading && <CircularProgress size={24} />}
                        >
                          {props.relatorio.isLoading ? 'Pesquisando...' : 'Pesquisar'}
                        </Button>
                      </Grid>
                    </Grid>
                  </CardContent>
                </ValidatorForm>
              </Card>
              <Card>
                <TableContainer component={Paper} style={{ overflowX: 'auto' }}>
                  <Table>
                    <TableHead>
                      {totalRows > 0 &&
                        <TableRow>
                          <TableCell align="center" colSpan={idLoja ? 7 : 6}>
                            Foram encontrados {totalRows} registros correspondentes
                          </TableCell>
                        </TableRow>
                      }
                      <TableRow>
                        <TableCell>Data/Hora</TableCell>
                        {!idLoja && <TableCell>Loja</TableCell>}
                        <TableCell>CNPJ Fornecedor</TableCell>
                        <TableCell>Nome Fornecedor</TableCell>
                        <TableCell>Fornecedor</TableCell>
                        <TableCell>Valor</TableCell>
                        <TableCell key="collapseIcon"></TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {relatorio && relatorio?.items?.length ?
                        relatorio.items.map((n) => <ConversoesCollapsableRow row={n} idLoja={idLoja} key={n.id} />)
                        :
                        (isLoading ?
                          Array.apply(null, { length: 6 }).map((e, i) => (
                            <TableRow hover>
                              <TableCell component="th" scope="row">
                                <Skeleton animation="wave" />
                              </TableCell>
                              <TableCell component="th" scope="row">
                                <Skeleton animation="wave" />
                              </TableCell>
                              <TableCell component="th" scope="row" align="right">
                                <Skeleton animation="wave" />
                              </TableCell>
                              <TableCell component="th" scope="row" align="right">
                                <Skeleton animation="wave" />
                              </TableCell>
                              <TableCell component="th" scope="row">
                                <Skeleton animation="wave" />
                              </TableCell>
                              <TableCell component="th" scope="row" align="right">
                                <Skeleton animation="wave" />
                              </TableCell>
                              <TableCell component="th" scope="row" align="right">
                                <Skeleton animation="wave" />
                              </TableCell>
                            </TableRow>
                          ))
                          :
                          <TableRow>
                            <TableCell rowSpan={3} colSpan={idLoja ? 6 : 7} align="center">Nenhum registro encontrado</TableCell>
                          </TableRow>
                        )
                      }
                    </TableBody>
                  </Table>
                </TableContainer>
              </Card>
            </Grid>
          </Grid>
        )
        }
        no={() => <AccessDenied />}
      />
    </Page >
  )
}
RelatorioConversoes.propTypes = {
  classes: PropTypes.object.isRequired
}
const mapStateToProps = (state) => {
  return {
    relatorio: state.relatorio
  }
}
const connectedRelatorioConversoesPage = withRouter(
  connect(mapStateToProps, null, null, {
    pure: false
  })(withStyles(styles)(RelatorioConversoes))
)
export { connectedRelatorioConversoesPage as RelatorioConversoes }
