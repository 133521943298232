import React, { useState, useEffect } from 'react'
import { connect, useDispatch, useSelector } from 'react-redux'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import { toast } from 'react-toastify'
import {
  Badge,
  Button,
  Card,
  CardHeader,
  CardContent,
  CardActions,
  CircularProgress,
  Divider,
  Grid,
  MenuItem,
  Link,
  Table,
  TableContainer,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Input,
  Tabs,
  Tab
} from '@material-ui/core'
import {
  ValidatorForm,
  TextValidator,
  SelectValidator
} from 'react-material-ui-form-validator'
import { listaPrecoAction, lojaAction, fornecedorAction, totvsInsumoUnidadeAction } from '../../_actions'
import { withRouter } from 'react-router-dom'
import { history, stringUtils } from '../../utils'
import { Page } from 'components'
import SaveIcon from '@material-ui/icons/Save';
import { isEmpty } from 'lodash'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus, faTrashAlt } from '@fortawesome/free-solid-svg-icons'
import Swal from 'sweetalert2';
import RestaurantRoundedIcon from '@material-ui/icons/RestaurantRounded'
import ListAltRoundedIcon from '@material-ui/icons/ListAltRounded'
import StoreRoundedIcon from '@material-ui/icons/StoreRounded'
import ShoppingBasketRoundedIcon from '@material-ui/icons/ShoppingBasketRounded'
import KitchenRoundedIcon from '@material-ui/icons/KitchenRounded'
import LocalBarRoundedIcon from '@material-ui/icons/LocalBarRounded'
import NatureRoundedIcon from '@material-ui/icons/NatureRounded'
import GroupRoundedIcon from '@material-ui/icons/GroupRounded'
import LocalCafeRoundedIcon from '@material-ui/icons/LocalCafeRounded'
import { InsumoUnidadeModal } from '../InsumosUnidades/insumoUnidadeModal.component';

const styles = (theme) => ({
  root: {
    padding: theme.spacing(2)
  },
  content: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    textAlign: 'center'
  },
  nome: {
    marginTop: theme.spacing(1)
  },
  uploadButton: {
    marginRight: theme.spacing(2),
    justifyContent: 'space-between'
  },
  avatar: {
    height: 200,
    width: 200,
    borderRadius: '10px',
    webkitBorderRadius: '10px',
    mozBorderRadius: '10px',
    cursor: 'pointer'
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff'
  },
  tituloDelivery: {
    textAlign: 'center',
    alignItems: 'center',
    display: 'column',
    flex: 1,
    marginTop: 10
  }
})

const ListaPrecoEdit = (props) => {

  const dispatch = useDispatch()
  const { classes, listaPreco, className, ...rest } = props
  const { match: { params } } = props;
  const formRef = React.createRef(null);

  const [idLoja, setIdLoja] = useState(0);
  const [textLoja, setTextLoja] = useState('');
  const [, forceUpdate] = React.useState(0);

  const lojas = useSelector((state) => state.loja.loja)

  const [term, setTerm] = useState('');
  const [filteredTotvsInsumosUnidades, setFilteredTotvsInsumosUnidades] = useState([]);

  const [tab, setTab] = useState('');

  const [value, setValue] = useState(0);

  const [modalInsumoUnidade, setModalInsumoUnidade] = useState(false);
  const [modalIdInsumoUnidade, setModalIdInsumoUnidade] = useState(0);
  const toggleInsumoUnidade = () => setModalInsumoUnidade(!modalInsumoUnidade);

  const { totvsInsumoUnidade } = useSelector(
    (state) => state.totvsInsumoUnidade
  )

  const fornecedores = useSelector((state) => state.fornecedor.fornecedor)

  const handleTabsChange = (event, value) => {
    //history.push(value)
    setTab(value);
  }

  useEffect(() => {

    if (params.id) {
      dispatch(listaPrecoAction.getListaPrecoById(params.id))
    } else {
      dispatch(listaPrecoAction.clear())
      dispatch(listaPrecoAction.clearListaPrecosLojas())
      dispatch(listaPrecoAction.clearListaPrecosProdutos())
    }

    dispatch(lojaAction.getLojaSelect())
    dispatch(fornecedorAction.getFornecedorSelect());

  }, []);

  useEffect(() => {

  }, [props.listaPreco.isLoading])

  useEffect(() => {

    if (props.listaPreco && props.listaPreco.idFornecedor > 0) {

      let filterModel = {
        IdLoja: 0,
        IdFornecedor: props.listaPreco.idFornecedor
      };

      dispatch(totvsInsumoUnidadeAction.getTotvsInsumoUnidadeFornecedor(filterModel, true))

    } else {
      dispatch(totvsInsumoUnidadeAction.clearAll())
    }

  }, [props.listaPreco.idFornecedor])

  useEffect(() => {
    if (totvsInsumoUnidade && totvsInsumoUnidade.totvsInsumosUnidades) {
      setFilteredTotvsInsumosUnidades(totvsInsumoUnidade.totvsInsumosUnidades.filter(filterTerm))
    } else {
      setFilteredTotvsInsumosUnidades(null)
    }
  }, [totvsInsumoUnidade, term, tab]);

  useEffect(() => {

  }, [filteredTotvsInsumosUnidades]);

  function filterTerm(obj) {
      if (tab == null || tab == '' || (obj.categoriaInsumo.nome.toUpperCase() == (tab.toUpperCase()))) {
        if (term == null || term == '' || (obj.nome != null && obj.nome != '' && obj.nome.toUpperCase().includes(term.toUpperCase()))) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
  }

  const handleChangeValorUnitario = (event, prop, n) => {

    setValue(event.target.value);

    var listasPrecosProdutos = props.listaPreco.listasPrecosProdutos;

    if (listasPrecosProdutos && listasPrecosProdutos.length > 0) {

      var index = listasPrecosProdutos.findIndex(x => x.idTotvsInsumoUnidade == n.id);
      if (index >= 0) {

        const novosPedidosComprasProdutos = props.listaPreco.listasPrecosProdutos.map(x =>
          x.idTotvsInsumoUnidade == n.id ? { ...x, valorUnitario: parseFloat(event.target.value) } : x
        );
        dispatch(listaPrecoAction.onChangeProps('listasPrecosProdutos', novosPedidosComprasProdutos))

      } else {

        let payload = {
          id: 0,
          idListaPreco: (params.id ? params.id : 0),
          idTotvsInsumoUnidade: n.id,
          valorUnitario: parseFloat(event.target.value)
        }
        listasPrecosProdutos.push(payload);

        dispatch(listaPrecoAction.onChangeProps('listasPrecosProdutos', listasPrecosProdutos))


      }

    } else {

      let payload = {
        id: 0,
        idListaPreco: (params.id ? params.id : 0),
        idTotvsInsumoUnidade: n.id,
        valorUnitario: parseFloat(event.target.value)
      }
      listasPrecosProdutos.push(payload);

      dispatch(listaPrecoAction.onChangeProps('listasPrecosProdutos', listasPrecosProdutos))


    }

  };

  function getValorUnitario(id) {
    if (props && props.listaPreco && props.listaPreco.listasPrecosProdutos && props.listaPreco.listasPrecosProdutos.length > 0) {
      const produto = props.listaPreco.listasPrecosProdutos.find(p => p.idTotvsInsumoUnidade == id);
      if (produto) {
        return produto.valorUnitario;
      } else {
        return 0;
      }
    } else {
      return 0;
    }
  }

  const handleChange = (prop) => (event) => {
    dispatch(listaPrecoAction.onChangeProps(prop, event))
  }

  const handleChangeTerm = prop => event => {
    setTerm(event.target.value);
  };

  const handleSelectChangeState = prop => event => {
    setIdLoja(event.target.value);
    setTextLoja(event.nativeEvent.target.innerText);
  };

  const handleSubmit = (event) => {

    let payload = {
      id: params.id,
      nome: props.listaPreco.nome,
      idFornecedor: props.listaPreco.idFornecedor,
      listasPrecosLojas: props.listaPreco.listasPrecosLojas,
      listasPrecosProdutos: props.listaPreco.listasPrecosProdutos
    }

    if (params.id) {
      dispatch(listaPrecoAction.editListaPrecoInfo(params.id, payload, true))
    } else {
      dispatch(listaPrecoAction.createListaPreco(payload))
    }
  };

  const handleAddOnList = e => {

    if (props.listaPreco.listasPrecosLojas.filter(ts => ts.idLoja == parseInt(idLoja)).length > 0) {
      toast.error('Erro ao adicionar! Esta loja já existe!')
    }
    else {

      if (idLoja != '' && idLoja != '0') {
        props.listaPreco.listasPrecosLojas.push({ 'idLoja': idLoja, 'idListaPreco': props.listaPreco.id, 'loja': { 'nome': textLoja } });
        forceUpdate(n => !n);
        setIdLoja(0);

      } else {
        Swal.fire({
          icon: 'warning',
          title: 'Erro ao salvar',
          showCloseButton: true,
          showConfirmButton: true,
          html: '<div style="font-size: 14px; text-align: center">Informe ao menos uma loja!</div>'
        });
      }
    }
  };

  const handleRemoveOnList = id => {

    props.listaPreco.listasPrecosLojas = props.listaPreco.listasPrecosLojas.filter(ts => ts.idLoja != parseInt(id));
    forceUpdate(n => !n);
  };

  function excludeFilter(obj) {
    if (props.listaPreco.listasPrecosLojas && props.listaPreco.listasPrecosLojas.length > 0) {
      if (props.listaPreco.listasPrecosLojas.filter(fl => fl.idLoja == parseInt(obj.value)).length > 0) {
        return false;
      } else {
        return true;
      }

    } else {
      return true;
    }
  }

  function hasQuantidade() {
    if (props.listaPreco.idFornecedor != null && props.listaPreco.idFornecedor != 0) {
      if (props && props.listaPreco && props.listaPreco.listasPrecosProdutos.length > 0) {
        return props.listaPreco.listasPrecosProdutos.some(e => e.valorUnitario != null && e.valorUnitario > 0) == true
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  const handleOpenModal = (id) => {
    toggleInsumoUnidade();

    setModalIdInsumoUnidade(id)
  }

  const tabs = []
  {
    tabs.push({
      value: '',
      label: 'TODOS',
      icon: <ListAltRoundedIcon />
    })
  }
  {
    tabs.push({
      value: 'BEBIDAS',
      label: 'BEBIDAS',
      icon: <LocalBarRoundedIcon />,
      //disabled: !hasTab('BEBIDAS')
    })
  }
  {
    tabs.push({
      value: 'FRENTE',
      label: 'FRENTE',
      icon: <LocalCafeRoundedIcon />,
      //disabled: !hasTab('FRENTE')
    })
  }
  {
    tabs.push({
      value: 'EMBALAGEM',
      label: 'EMBALAGEM',
      icon: <ShoppingBasketRoundedIcon />,
      //disabled: !hasTab('EMBALAGEM')
    })
  }
  {
    tabs.push({
      value: 'COZINHA',
      label: 'COZINHA',
      icon: <KitchenRoundedIcon />,
      //disabled: !hasTab('COZINHA')
    })
  }
  {
    tabs.push({
      value: 'LOJA',
      label: 'LOJA',
      icon: <StoreRoundedIcon />,
      //disabled: !hasTab('LOJA')
    })
  }
  {
    tabs.push({
      value: 'UTENSÍLIO',
      label: 'UTENSÍLIO',
      icon: <RestaurantRoundedIcon />,
      //disabled: !hasTab('UTENSÍLIO')
    })
  }
  {
    tabs.push({
      value: 'HORTIFRUTI',
      label: 'HORTIFRUTI',
      icon: <NatureRoundedIcon />,
      //disabled: !hasTab('HORTIFRUTI')
    })
  }
    {
    tabs.push({
      value: 'UNIFORMES',
      label: 'UNIFORMES',
      icon: <GroupRoundedIcon />,
      //disabled: !hasTab('HORTIFRUTI')
    })
  }

  function InsertText() {
    return (
      <Typography variant="h4">
        <b>{'Nova Lista de Preço'}</b>
      </Typography>
    )
  }

  function EditText() {
    return (
      <Typography variant="h4">
        <b>{'Editar Lista de Preço'}</b>
      </Typography>
    )
  }

  function SegHeader() {
    if (params.id) {
      return <EditText />
    }
    return <InsertText />
  }

  return (
    <Page
      className={classes.root}
      title={
        params.id
          ? 'Gerência de Listas de Preços - Editar Lista de Preço'
          : 'Gerência de Listas de Preços - Nova Lista de Preço'
      }
    >
      <Grid
        {...rest}
        className={clsx(classes.root, className)}
        container
        spacing={3}
      >
        <Grid item lg={12} md={12} xl={12} xs={12}>
          <Grid item md={12} xs={12}>
            <Card>
              <ValidatorForm
                className={classes.form}
                ref={formRef}
                id="formListaPreco"
                onSubmit={(event) => handleSubmit(event)}
              >
                <CardHeader
                  subheader="Cadastro de Listas de Preços"
                  title={<SegHeader />}
                />
                <Divider />
                <CardContent lg={12} md={12} xl={12} xs={12}>
                  <Grid container spacing={2}>
                    <Grid item xl={12} lg={12} md={12} xs={12}>
                      <SelectValidator
                        validators={['required']}
                        errorMessages={['Campo obrigatório!']}
                        variant="outlined"
                        id="idFornecedor"
                        label="Fornecedor *"
                        value={props.listaPreco.idFornecedor}
                        onChange={handleChange('idFornecedor')}
                        InputLabelProps={{
                          shrink: true
                        }}
                        fullWidth
                        displayEmpty
                        labelId="idFornecedor"
                        disabled={hasQuantidade()}
                      >
                        <MenuItem disabled value="" key={12}>
                          <em>Fornecedor *</em>
                        </MenuItem>
                        {!isEmpty(fornecedores) &&
                          undefined !== fornecedores &&
                          fornecedores.length &&
                          fornecedores.map((row, index) => (
                            <MenuItem key={'u' + index} value={row.value}>
                              {row.text}
                            </MenuItem>
                          ))}
                      </SelectValidator>
                    </Grid>
                    <Grid item lg={12} md={12} xl={12} xs={12}>
                      <TextValidator
                        className={classes.textField}
                        fullWidth
                        id="nome"
                        label="Nome *"
                        onInput={stringUtils.toInputUppercase}
                        margin="normal"
                        onChange={handleChange('nome')}
                        value={props.listaPreco.nome}
                        variant="outlined"
                        validators={['required']}
                        errorMessages={['Campo obrigatório!']}
                      />
                    </Grid>
                  </Grid>
                  <Grid item md={12} xs={12} style={{ marginTop: '15px', marginBottom: '15px' }}>
                    <Divider />
                  </Grid>
                  <Grid container xl={12} lg={12} md={12} xs={12} style={{ marginTop: '15px', marginBottom: '15px' }}>
                    <Grid item lg={4} md={4} xl={4} xs={12} style={{ alignItems: 'center', justifyContent: 'center', alignContent: 'center' }} >
                      <SelectValidator
                        validators={['required']}
                        errorMessages={['Campo obrigatório!']}
                        variant="outlined"
                        id="idLoja"
                        label="Lojas vinculadas *"
                        value={idLoja}
                        onChange={handleSelectChangeState('idLoja')}
                        InputLabelProps={{
                          shrink: true
                        }}
                        fullWidth
                        displayEmpty
                        labelId="idLoja"
                      >
                        <MenuItem disabled value="" key={12}>
                          <em>Loja *</em>
                        </MenuItem>
                        {!isEmpty(lojas) &&
                          undefined !== lojas &&
                          lojas.length &&
                          lojas.filter(excludeFilter).map((row, index) => (
                            <MenuItem key={'u' + index} value={row.value}>
                              {row.text}
                            </MenuItem>
                          ))}
                      </SelectValidator>
                    </Grid>
                    <Grid item lg={4} md={4} xl={4} xs={12} style={{ alignItems: 'top', justifyContent: 'center' }}>
                      <Button style={{ width: '200px', marginLeft: '15px' }} name="btnLojas" id="btnLojas" className="btn" variant="contained" color="primary" component='a' onClick={handleAddOnList}>Adicionar&nbsp;<FontAwesomeIcon icon={faPlus} /></Button>
                    </Grid>
                    <Grid item lg={12} md={12} xl={12} xs={12}>
                      <div className="selectgroup selectgroup-pills" style={{ marginLeft: '5px', display: 'flex-row', alignItems: 'top' }}>
                        <Grid item lg={12} md={12} xl={12} xs={12}>
                          {
                            props.listaPreco.listasPrecosLojas.map(n => {
                              return (
                                (n.id !== '' ?
                                  <Button color="default" onClick={() => handleRemoveOnList(n.idLoja)} key={'button' + n.id}>
                                    <Badge color="primary" style={{ fontSize: 15, paddingLeft: 7, paddingRight: 7 }}>
                                      {(n.loja ? n.loja.nome : n.idLoja)}
                                    </Badge>
                                    &nbsp;<FontAwesomeIcon icon={faTrashAlt} />
                                  </Button>
                                  : null)
                              );
                            })
                          }
                        </Grid>
                      </div>
                    </Grid>
                  </Grid>
                  <Grid item md={12} xs={12} style={{ marginTop: '15px', marginBottom: '15px' }}>
                    <Divider />
                  </Grid>

                  <Tabs
                    className={classes.tabs}
                    onChange={handleTabsChange}
                    scrollButtons="auto"
                    value={tab}
                    indicatorColor="primary"
                    textColor="primary"
                    variant={'scrollable'}
                  >
                    {tabs.map((tab) => (
                      <Tab
                        disabled={tab.disabled}
                        key={tab.value}
                        label={tab.label}
                        value={tab.value}
                        icon={tab.icon}
                      />
                    ))}
                  </Tabs>

                  <Grid container spacing={2}>
                    <Grid item lg={12} md={12} xl={12} xs={12}>
                      <Grid item xl={6} lg={6} md={6} xs={12} style={{ marginTop: 20 }}>
                        <Input
                          id="term"
                          label="term"
                          placeholder="Filtro"
                          value={term}
                          onChange={handleChangeTerm('term')}
                          fullWidth
                        />
                      </Grid>
                      <br />
                      <TableContainer>
                        <Table>
                          <TableHead>
                            <TableRow>
                              <TableCell>Descrição</TableCell>
                              <TableCell>Unidade</TableCell>
                              <TableCell align="right">Preço Und. Padrão</TableCell>
                              <TableCell align="right">Preço Und. Lista</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {props.listaPreco && props.listaPreco.idFornecedor > 0 && undefined !== filteredTotvsInsumosUnidades && filteredTotvsInsumosUnidades?.length
                              ? filteredTotvsInsumosUnidades.map((n) => {
                                return (
                                  <TableRow hover key={(n ? n.id : 0)}>
                                    <TableCell component="th" scope="row">
                                      <Link color="inherit" onClick={() => handleOpenModal(n.id)} >
                                        {(n ? n.nome.toUpperCase() : '')}
                                      </Link>
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                      {(n.unidade || '')}
                                    </TableCell>
                                    <TableCell component="th" scope="row" align="right">
                                      <Input
                                        id="valor"
                                        label="Valor"
                                        className="form-control"
                                        type="number"
                                        value={parseFloat(n.preco).toFixed(2)}
                                        fullWidth
                                        disabled
                                        inputProps={{
                                          style: { textAlign: "right" }
                                        }}
                                      />
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                      <Input
                                        id="valor"
                                        label="Valor"
                                        className="form-control"
                                        type="number"
                                        step="0.01"
                                        pattern="^\d*(\.\d{0,2})?$"
                                        value={getValorUnitario(n.id)}
                                        onChange={(event) => handleChangeValorUnitario(event, 'valorUnitario', n)}
                                        fullWidth
                                        inputProps={{
                                          min: 0,
                                          style: { textAlign: "right" },
                                          pattern: "^\d*(\.\d{0,2})?$", step: "0.01"
                                        }}
                                      />
                                    </TableCell>
                                  </TableRow>
                                )
                              })
                              : null}
                          </TableBody>
                        </Table>
                      </TableContainer>

                    </Grid>
                  </Grid>

                </CardContent>
                <Divider />
                <CardActions>
                  <Grid item lg={12} md={12} xl={12} xs={12}>

                    <Typography style={{ fontSize: "10px", paddingLeft: "6px" }}>
                      * Campos obrigatórios
                    </Typography>

                  </Grid>
                  <Grid item lg={12} md={12} xl={12} xs={12} style={{
                    display: 'flex',
                    alignItems: 'right',
                    flexDirection: 'row',
                    textAlgin: 'right',
                    justifyContent: 'right'
                  }}>
                    <Button
                      color="default"
                      onClick={() => history.push('/listasPrecos')}
                      variant="contained"
                    >
                      Cancelar
                    </Button>
                    <Button
                      color="primary"
                      type="submit"
                      variant="contained"
                      disabled={props.listaPreco.isLoading}
                      startIcon={<SaveIcon />}
                      endIcon={props.listaPreco.isLoading && <CircularProgress size={24} />}
                    >
                      {params.id ? (props.listaPreco.isLoading ? 'Atualizando lista de preço...' : 'Atualizar') : (props.listaPreco.isLoading ? 'Salvando lista de preço...' : 'Salvar')}
                    </Button>
                  </Grid>
                </CardActions>
              </ValidatorForm>
            </Card>
          </Grid>
        </Grid>
      </Grid>

      {(modalInsumoUnidade ?
        <InsumoUnidadeModal toggle={toggleInsumoUnidade} modal={modalInsumoUnidade} props={props} id={modalIdInsumoUnidade} />
        :
        null
      )}

    </Page >
  );
}

ListaPrecoEdit.propTypes = {
  classes: PropTypes.object.isRequired,
}
const mapStateToProps = (state) => {
  return state
}

const connectedListaPrecoEditPage = withRouter(
  connect(mapStateToProps, null, null, {
    pure: false
  })(withStyles(styles)(ListaPrecoEdit))
)
export { connectedListaPrecoEditPage as ListaPrecoEdit };
