import { history } from 'utils'
import { crudService } from '../_services'
import { stringUtils } from 'utils'
import { toast } from 'react-toastify'
import { compraAction, fornecedorAction } from '../../src/_actions'

export const fornecedorFilialAction = {
  getFornecedorFilial,
  addFornecedorFilial,
  getFornecedorFilialSelect,
  getFornecedorFilialSelectLoja,
  getFornecedorFilialById,
  onChangeProps,
  editFornecedorFilialInfo,
  editFornecedoresFiliaisDetails,
  createFornecedorFilial,
  deleteFornecedorFilialById,
  clear,
  clearAll
}

let filterModel = {
  Page: 1,
  Limit: 10,
  Term: ''
};

function getFornecedorFilial(filterModel, showWaiting) {
  return (dispatch) => {
    let apiEndpoint = 'fornecedoresFiliais' + stringUtils.serialize(filterModel);
    crudService
      .get(apiEndpoint)
      .then((response) => {
        dispatch(changeFornecedoresFiliaisList(response.data.items, response.data.totalRows))
      })
      .catch((err) => {
        console.log(err)
      })
  }
}

function createFornecedorFilial(payload, idCompra) {
  return (dispatch) => {
    dispatch(isLoading())
    let apiEndpoint = 'fornecedoresFiliais/'
    crudService.post(apiEndpoint, payload)
      .then((response) => {
        if (response.request != undefined && response.request.status === 200) {
          var ret = JSON.parse(response.request.responseText);
          dispatch(createFornecedorFilialInfo())
          toast.success('Feito! Cadastro realizado com sucesso! 😎')
          dispatch(notIsLoading())
          dispatch(compraAction.getCompraById(idCompra))
          dispatch(getFornecedorFilialSelect())
        } else {
          toast.err('Oops! Erro ao cadastrar Fornecedor Filial! 😥')
        }
      }).catch((err) => {
        // console.log(err.response);
        dispatch(notIsLoading())
        // toast.error('Oops! Erro ao cadastrar Fornecedor Filial! 😥')
        return err.response;
      })
  }
}

function getFornecedorFilialSelect() {
  return (dispatch) => {
    let apiEndpoint = 'fornecedoresFiliais/select'
    crudService
      .get(apiEndpoint)
      .then((response) => {
        if (response.data) {
          dispatch(changeFornecedoresFiliaisList(response.data, null))
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }
}

function getFornecedorFilialSelectLoja(id) {
  return (dispatch) => {
    let apiEndpoint = 'fornecedoresFiliais/select/loja/' + id
    crudService
      .get(apiEndpoint)
      .then((response) => {
        if (response.data) {
          dispatch(changeFornecedoresFiliaisList(response.data))
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }
}

function getFornecedorFilialById(id) {
  return (dispatch) => {
    let apiEndpoint = 'fornecedoresFiliais/' + id
    crudService.get(apiEndpoint).then((response) => {
      dispatch(editFornecedoresFiliaisDetails(response.data))
    })
  }
}

function onChangeProps(props, event) {
  return (dispatch) => {
    if (event.target.type === 'checkbox') {
      dispatch(handleOnChangeProps(props, event.target.checked))
    } else {
      dispatch(handleOnChangeProps(props, event.target.value))
    }
  }
}

function editFornecedorFilialInfo(id, payload, redirect) {
  return (dispatch) => {
    dispatch(isLoading())
    let apiEndpoint = 'fornecedoresFiliais/' + id
    crudService.put(apiEndpoint, payload)
      .then((response) => {
        if (response.request != undefined && response.request.status === 200) {
          var ret = JSON.parse(response.request.responseText);
          dispatch(updatedFornecedorFilialInfo())
          dispatch(clear())
          if (redirect) {
            dispatch(notIsLoading())
            history.push('/fornecedoresFiliais')
          }
        }
      }).catch((err) => {
        dispatch(notIsLoading())
        toast.error('Oops! Erro ao atualizar fornecedorFilial! 😥')
        //  console.log(err.response);
        return err.response;
      }
      )
  }
}

function deleteFornecedorFilialById(id, idFornecedor) {
  return (dispatch) => {
    dispatch(isLoading())
    let apiEndpoint = 'fornecedoresFiliais/' + id
    crudService.deleteDetail(apiEndpoint).then(() => {
      dispatch(deleteFornecedoresFiliaisDetails())
      dispatch(fornecedorAction.getFornecedorById(idFornecedor))
      dispatch(notIsLoading())
    })
  }
}

export function addFornecedorFilial(payload) {
  return [{ type: 'ADD_FORNECEDOR_FILIAL', fornecedorFilial: payload }, clear()]
}

export function changeFornecedoresFiliaisList(fornecedorFilial, totalRows) {
  return {
    type: 'FETCHED_ALL_FORNECEDOR_FILIAL',
    fornecedorFilial: fornecedorFilial,
    totalRegistros: totalRows
  }
}

export function clear() {
  return {
    type: 'FORNECEDOR_FILIAL_CLEAR'
  }
}

export function clearAll() {
  return {
    type: 'FORNECEDOR_FILIAL_CLEAR_ALL'
  }
}

export function handleOnChangeProps(props, value) {
  return {
    type: 'FORNECEDOR_FILIAL_HANDLE_ON_CHANGE',
    props: props,
    value: value
  }
}

export function editFornecedoresFiliaisDetails(fornecedorFilial) {
  return [
    { type: 'FORNECEDOR_FILIAL_DETAIL', fornecedorFilial },
  ]
}

export function updatedFornecedorFilialInfo() {
  return {
    type: 'FORNECEDOR_FILIAL_UPDATED'
  }
}

export function createFornecedorFilialInfo() {
  return {
    type: 'FORNECEDOR_FILIAL_CREATED_SUCCESSFULLY'
  }
}

export function deleteFornecedoresFiliaisDetails() {
  return {
    type: 'DELETED_FORNECEDOR_FILIAL_DETAILS'
  }
}
export function isLoading() {
  return {
    type: 'FORNECEDOR_FILIAL_ISLOADING'
  }
}

export function notIsLoading() {
  return {
    type: 'FORNECEDOR_FILIAL_NOTISLOADING'
  }
}
