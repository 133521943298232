import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import { Pie } from 'react-chartjs-2'
import { makeStyles } from '@material-ui/styles'
import {
  Card,
  CardHeader,
  CardContent,
  Divider,
} from '@material-ui/core'
import { options } from './options'
import palette from 'theme/palette'
import { useSelector } from 'react-redux'
import { dashboardAction } from '../../../../_actions'
import { Skeleton } from '@material-ui/lab'
import moment from 'moment'

const useStyles = makeStyles(() => ({
  root: {},
  chartContainer: {
    height: 400,
    position: 'relative'
  },
  actions: {
    justifyContent: 'flex-end'
  }
}))

const GraficoClientesSexoChartCard = (props) => {

  const dispatch = useDispatch()

  const { className, idLoja, dataInicio, dataFim, ...rest } = props
  const graficoClientesSexo = useSelector((state) => (state.dashboard ? (state.dashboard.dashboardLoja ? state.dashboard.dashboardLoja.graficoClientesSexo : undefined) : undefined))
  const { isLoading } = useSelector((state) => state.dashboard)
  const classes = useStyles()

  useEffect(() => {
    let filterModel = {
      IdLoja: idLoja,
      DataInicio: moment(dataInicio).format("YYYY-MM-DD"),
      DataFim: moment(dataFim).format("YYYY-MM-DD")
    };

    dispatch(dashboardAction.getDashboardLojaClientesFaixaEtaria(filterModel, true));


  }, [dataInicio, dataFim, idLoja]);

  const generateData = () => {
    if (graficoClientesSexo) {
      const data = {
        labels: graficoClientesSexo.options, //labels
        datasets: [
          {
            label: 'Número de clientes cadastrados',
            data: graficoClientesSexo.series[0],  //dados
            backgroundColor: [
              palette.error.main,
              '#2196F3',
              palette.primary.light,
              '#4CAF50'
            ],
            borderColor: [
              palette.error.main,
              '#2196F3',
              palette.primary.light,
              '#4CAF50'
            ],
            borderWidth: 2
          },
        ],
      }
      return data
    } else {
      return []
    }
  }


  return (
    <Card {...rest} className={clsx(classes.root, className)}>
      <CardHeader
        title="CLIENTES DE ACORDO COM O SEXO INFORMADO"
      />
      <Divider />
      <CardContent className={classes.chartContainer}>
        {isLoading ? (
          <Skeleton animation="wave" style={{ marginTop: -140 }} height={600} />
        ) : (
            <Pie data={generateData} options={options} />
        )}
      </CardContent>
      <Divider />
    </Card>
  )
}

GraficoClientesSexoChartCard.propTypes = {
  className: PropTypes.string
}

export default GraficoClientesSexoChartCard
