import React, { useEffect, useState } from 'react'
import { connect, useDispatch, useSelector } from 'react-redux'
import { relatorioAction } from '../../_actions'
import PropTypes from 'prop-types'
import { useDebounce } from 'use-debounce';
import {
  Card,
  CardContent,
  Input,
  withStyles,
  Table,
  TableContainer,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Paper,
  Grid
} from '@material-ui/core'
import {
  ValidatorForm
} from 'react-material-ui-form-validator'
import Skeleton from "@material-ui/lab/Skeleton"
import { withRouter } from 'react-router-dom'
import TitleContentInformation from '../TitleContentInformation'
import { AccessControl } from '../../components/Utils/AccessControl'
import AccessDenied from '../Errors/AccessDenied'
import { Page } from 'components'

const styles = (theme) => ({
  root: {
    padding: theme.spacing(2)
  },
  gridButton: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'right',
    textAlign: 'right'
  }
})

const RelatorioTotvsInsumoUnidadeFornecedor = (props) => {

  const dispatch = useDispatch()
  const { classes, className, ...rest } = props
  const { match: { params } } = props;
  const { relatorio } = props.relatorio
  const { totalRegistros } = props.relatorio
  const { isLoading } = props.relatorio

  //filtro no front-end dos insumos
  const [term, setTerm] = useState('');
  const [debounceTerm] = useDebounce(term, 200);
  const [filteredRelatorio, setFilteredRelatorio] = useState([]);

  const { idLoja } = useSelector(
    (state) => state.usuarioLoja
  )

  useEffect(() => {
    dispatch(relatorioAction.clear());
  }, []);

  useEffect(() => {
    if (relatorio) {
      setFilteredRelatorio(relatorio.filter(filterTerm))
    }   
  }, [relatorio, debounceTerm]);

  useEffect(() => {
    handleSubmit()
  }, [idLoja]);

  const handleSubmit = () => {

    if (idLoja) {

      let payload = {
        IdLoja: idLoja ? idLoja : 0
      };

      dispatch(relatorioAction.getRelatorioTotvsInsumosUnidadesFornecedores(payload, true));

    } else {
      dispatch(relatorioAction.clear());
    }
  };

  const handleChangeTerm = prop => event => {
    setTerm(event.target.value);
  };

  function filterTerm(obj) {

    if (obj) {
      if (obj.totvsInsumosUnidadesFornecedores) {
       const nomeFornecedorFiltro = obj.totvsInsumosUnidadesFornecedores.map((n) => {
          return (
            n.nomeFornecedor
          )
        })
        if (debounceTerm == null || debounceTerm == '' || (obj.nomeTotvsInsumoUnidade != null && obj.nomeTotvsInsumoUnidade != '' && (obj.nomeTotvsInsumoUnidade.normalize("NFD").replace(/\p{Diacritic}/gu, "").toUpperCase()).includes(debounceTerm.normalize("NFD").replace(/\p{Diacritic}/gu, "").toUpperCase()))
          || (obj.totvsInsumosUnidadesFornecedores &&
            nomeFornecedorFiltro[0].normalize("NFD").replace(/\p{Diacritic}/gu, "").toUpperCase())
            .includes(debounceTerm.normalize("NFD").replace(/\p{Diacritic}/gu, "").toUpperCase())) {
          return true;
        } else {
          return false;
        }
      }
    
    } else {
      return false;
    }
  }

  useEffect(() => {

  }, [totalRegistros]);

  useEffect(() => {
  }, [props.relatorio.isLoading])

  function getTotal() {
    if (relatorio && relatorio.length > 0 && filteredRelatorio && filteredRelatorio.length > 0) {

      let total = filteredRelatorio.length;

      return total;

    } else {
      return 0;
    }
  }

  return (
    <Page className={classes.root} title="Relatório - Fornecedores de Insumos">
      <AccessControl
        rule={'relatorios.list'} //permissão necessária para acessar conteúdo
        yes={() => (
          <Grid
            {...rest}
            container
            spacing={2}
          >
            <Grid item lg={12} md={12} xl={12} xs={12}>
              <Card style={{ marginBottom: 10, marginTop: 0 }}>
                <ValidatorForm
                  id="formRelatorioTotvsInsumoUnidadeFornecedores"
                  onSubmit={(event) => handleSubmit(event)}
                >
                  <CardContent>
                    <TitleContentInformation
                      subTitle={'RELATÓRIO'}
                      title={'Fornecedores de Insumos'}
                    />
                    <Grid container spacing={2} style={{ alignItems: 'center', justifyContent: 'left', alignContent: 'left' }}>
                        <Grid item xl={6} lg={6} md={6} xs={12} >
                          <Grid item xl={6} lg={6} md={6} xs={12} style={{ marginTop: 15 }}>
                            <Input
                              id="term"
                              label="term"
                              placeholder="Filtro"
                              value={term}
                              onChange={handleChangeTerm('term')}
                              fullWidth
                              onKeyDown={(e) => { e.key === 'Enter' && e.preventDefault() }}
                            />
                          </Grid>
                          <br />
                        </Grid>
                    </Grid>
                  </CardContent>
                </ValidatorForm>
              </Card>
              <Card>
                <TableContainer component={Paper}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>Insumo</TableCell>
                        <TableCell align="left">Fornecedor(es)</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {filteredRelatorio && undefined !== filteredRelatorio && filteredRelatorio.length
                        ? filteredRelatorio.map((n) => {
                          return (
                            <TableRow hover key={n.id}>
                              <TableCell component="th" scope="row">
                                {n.nomeTotvsInsumoUnidade || ''}
                              </TableCell>
                              <TableCell component="th" scope="row" align="left">
                                {undefined !== n.totvsInsumosUnidadesFornecedores && n.totvsInsumosUnidadesFornecedores
                                  ? n.totvsInsumosUnidadesFornecedores.map((n) => {
                                    return (
                                      n.nomeFornecedor
                                    )
                                  }).join(', ')
                                  : ''
                                }
                              </TableCell>
                            </TableRow>
                          )
                        })
                        :
                        (
                          idLoja ?
                            (isLoading ?
                              Array.apply(null, { length: 6 }).map((e, i) => (
                                <TableRow hover>
                                  <TableCell component="th" scope="row">
                                    <Skeleton animation="wave" />
                                  </TableCell>
                                  <TableCell component="th" scope="row">
                                    <Skeleton animation="wave" />
                                  </TableCell>
                                </TableRow>
                              ))
                              :
                              <TableRow>
                                <TableCell component="th" colSpan={2} align="center">Nenhum registro encontrado</TableCell>
                              </TableRow>
                            )
                            :
                            <TableRow>
                              <TableCell rowSpan={2} colSpan={2} align="center">Informe uma Loja</TableCell>
                            </TableRow>
                        )
                      }
                      {filteredRelatorio && undefined !== filteredRelatorio && filteredRelatorio.length
                        ?
                        <TableRow>
                          <TableCell rowSpan={2} colSpan={1}><b>Total</b></TableCell>
                          <TableCell ><b>{getTotal()}</b></TableCell>
                        </TableRow>
                        :
                        null
                      }
                    </TableBody>
                  </Table>
                </TableContainer>
              </Card>
            </Grid>
          </Grid>
        )}
        no={() => <AccessDenied />}
      />
    </Page>
  )
}
RelatorioTotvsInsumoUnidadeFornecedor.propTypes = {
  classes: PropTypes.object.isRequired
}
const mapStateToProps = (state) => {
  return state
}
const connectedRelatorioTotvsInsumoUnidadeFornecedorPage = withRouter(
  connect(mapStateToProps, null, null, {
    pure: false
  })(withStyles(styles)(RelatorioTotvsInsumoUnidadeFornecedor))
)
export { connectedRelatorioTotvsInsumoUnidadeFornecedorPage as RelatorioTotvsInsumoUnidadeFornecedor }
