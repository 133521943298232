import { history } from 'utils'
import { crudService } from '../_services'
import { stringUtils } from 'utils'
import { toast } from 'react-toastify'

export const grupoAction = {
  getGrupo,
  addGrupo,
  getGrupoById,
  onChangeProps,
  editGrupoInfo,
  editGruposDetails,
  createGrupo,
  deleteGrupoById,
  clear,
  clearAll
}

let filterModel = {
  Page: 1,
  Limit: 10,
  Term: ''
};

function getGrupo(filterModel, showWaiting) {
  return (dispatch) => {
    dispatch(isLoading())
    let apiEndpoint = 'grupos' + stringUtils.serialize(filterModel);
    crudService
      .get(apiEndpoint)
      .then((response) => {
        dispatch(changeGruposList(response.data.items, response.data.totalRows))
        dispatch(notIsLoading())
      })
      .catch((err) => {
        console.log(err)
      })
  }
}

function createGrupo(payload) {
  return (dispatch) => {
    dispatch(isLoading())
  //  console.log('entrou na action');
    let apiEndpoint = 'grupos/'
    crudService.post(apiEndpoint, payload)
      .then((response) => {
        if (response.request != undefined && response.request.status === 200) {
          var ret = JSON.parse(response.request.responseText);
          dispatch(createGrupoInfo())
          toast.success('Feito! Cadastro realizado com sucesso! 😎')
          dispatch(clear())
          dispatch(notIsLoading())
          history.push('/grupos')
        } else {
          toast.err('Oops! Erro ao cadastrar grupo! 😥')
        }
      }).catch((err) => {
       // console.log(err.response);
        dispatch(notIsLoading())
        toast.error('Oops! Erro ao cadastrar grupo! 😥')
        return err.response;
      })
  }
}

function getGrupoById(id) {
  return (dispatch) => {
    let apiEndpoint = 'grupos/' + id
    crudService.get(apiEndpoint).then((response) => {
     // console.log('response.data', response.data)
      dispatch(editGruposDetails(response.data))
    })
  }
}

function onChangeProps(props, event) {
  return (dispatch) => {
    if (event.target.type === 'checkbox') {
      dispatch(handleOnChangeProps(props, event.target.checked))
    } else {
      dispatch(handleOnChangeProps(props, event.target.value))
    }
  }
}

function editGrupoInfo(id, payload, redirect) {
  return (dispatch) => {
    if (redirect) {
      dispatch(isLoading())
    }
    let apiEndpoint = 'grupos/' + id
    crudService.put(apiEndpoint, payload)
      .then((response) => {
        if (response.request != undefined && response.request.status === 200) {
          var ret = JSON.parse(response.request.responseText);
          dispatch(updatedGrupoInfo())
          if (redirect) {
            dispatch(clear())
            dispatch(notIsLoading())
            history.push('/grupos')
          }
        }
      }).catch((err) => {
        dispatch(notIsLoading())
        toast.error('Oops! Erro ao atualizar grupo! 😥')
        //  console.log(err.response);
        return err.response;
      }
  )}
}

function deleteGrupoById(id) {
  return (dispatch) => {
    dispatch(isLoading())
    let apiEndpoint = 'grupos/' + id
    crudService.deleteDetail(apiEndpoint).then(() => {
      dispatch(deleteGruposDetails())
      dispatch(getGrupo(filterModel))
      dispatch(notIsLoading())
    })
  }
}

export function addGrupo(payload) {
  return [{ type: 'ADD_GRUPO', grupo: payload }, clear()]
}

export function changeGruposList(grupo, totalRows) {
  return {
    type: 'FETCHED_ALL_GRUPO',
    grupo: grupo,
    totalRegistros: totalRows
  }
}

export function clear() {
  return {
    type: 'GRUPO_CLEAR'
  }
}

export function clearAll() {
  return {
    type: 'GRUPO_CLEAR_ALL'
  }
}

export function handleOnChangeProps(props, value) {
  return {
    type: 'GRUPO_HANDLE_ON_CHANGE',
    props: props,
    value: value
  }
}

export function editGruposDetails(grupo) {
  return [
    { type: 'GRUPO_DETAIL', grupo },
  ]
}

export function updatedGrupoInfo() {
  return {
    type: 'GRUPO_UPDATED'
  }
}

export function createGrupoInfo() {
  return {
    type: 'GRUPO_CREATED_SUCCESSFULLY'
  }
}

export function deleteGruposDetails() {
  return {
    type: 'DELETED_GRUPO_DETAILS'
  }
}
export function isLoading() {
  return {
    type: 'GRUPO_ISLOADING'
  }
}

export function notIsLoading() {
  return {
    type: 'GRUPO_NOTISLOADING'
  }
}
