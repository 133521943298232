import React, { useEffect, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import { Bar } from 'react-chartjs-2'
import { makeStyles } from '@material-ui/styles'
import {
  Card,
  CardHeader,
  CardContent,
  Divider,
} from '@material-ui/core'
import { options } from './options'
import { useSelector } from 'react-redux'
import { dashboardAction } from '../../../../_actions'
import { Skeleton } from '@material-ui/lab'
import { ClientesInfoModal } from './clientesInfoModal.component';
import moment from 'moment'

const useStyles = makeStyles(() => ({
  root: {},
  chartContainer: {
    height: 400,
    position: 'relative'
  },
  actions: {
    justifyContent: 'flex-end'
  }
}))

const GraficoClientesRecorrenciaDiasChartCard = (props) => {
  const dispatch = useDispatch()

  const [dia1, setDia1] = useState('');
  const [dia2, setDia2] = useState('');

  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);

  const { className, idLoja, dataInicio, dataFim, ...rest } = props
  const graficoClientesRecorrenciaDias = useSelector((state) => (state.dashboard ? (state.dashboard.dashboardLoja ? state.dashboard.dashboardLoja.graficoClientesRecorrenciaDias : undefined) : undefined))
  const { isLoading } = useSelector((state) => state.dashboard)
  const classes = useStyles()

  const chartRef = useRef()

  useEffect(() => {
    let filterModel = {
      IdLoja: idLoja,
      DataInicio: moment(dataInicio).format("YYYY-MM-DD"),
      DataFim: moment(dataFim).format("YYYY-MM-DD")
    };

    dispatch(dashboardAction.getDashboardLojaClientesRecorrenciaDias(filterModel, true));


  }, [dataInicio, dataFim, idLoja]);

  const generateData = () => {
    if (graficoClientesRecorrenciaDias) {
      const data = {
        labels: graficoClientesRecorrenciaDias.options,
        datasets: [
          {
            label: 'Clientes cadastrados',
            yAxisID: 'A',
            backgroundColor: '#DF181B',
            data: graficoClientesRecorrenciaDias.series[0]
          }
        ]
      }
      return data
    } else {
      return []
    }
  }

  return (
    <Card {...rest} className={clsx(classes.root, className)}>
      <CardHeader
        title="CLIENTES DE ACORDO COM AS FIDELIZAÇÕES"
      />
      <Divider />
      <CardContent className={classes.chartContainer}>
        {isLoading ? (
          <Skeleton animation="wave" style={{ marginTop: -140 }} height={600} />
        ) : (
          <Bar
            data={generateData}
            options={options}
            getElementAtEvent={(elements, event) => {
              if (event.type === "click" && elements.length) {
                // console.log(elements[0]._index);
                const i = elements[0]._index;
                if (elements[0]._xScale) {
                  const dias = elements[0]._xScale.ticks[i];
                  //  console.log('dias', dias);
                  if (dias && dias.length) {
                    if (dias.length == 1) {
                      setDia1(dias);
                      setDia2(dias);
                    } else {
                      var arrayDiasIntervalo = dias.split("a", 2);
                      setDia1(arrayDiasIntervalo && arrayDiasIntervalo[0] != null ? arrayDiasIntervalo[0].trim() : '');
                      setDia2(arrayDiasIntervalo && arrayDiasIntervalo[1] != null ? arrayDiasIntervalo[1].trim() : '');
                      // console.log('dia1', dia1);
                      // console.log('dia2', dia2);
                    }
                  }

                  toggle();
                }
              }
            }
            }
          />
        )}
      </CardContent>
      <Divider />
      {
        (modal ?
          <ClientesInfoModal toggle={toggle} modal={modal} dataInicio={dataInicio} dataFim={dataFim} dia1={dia1} setDia1={setDia1} dia2={dia2} setDia2={setDia2} props={props} />
          :
          null
        )
      }
    </Card>
  )
}

GraficoClientesRecorrenciaDiasChartCard.propTypes = {
  className: PropTypes.string
}

export default GraficoClientesRecorrenciaDiasChartCard
