const initialState = {
  isLoading: false,
  totalRegistros: 0,
  produto: [],
  open: false,
  id: 0,
  nome: '',
  descricao: '',
  idProdutoReferencia: '',
  caminhoImagem: '',
  trocavel: false,
  novidade: false,
  pontos: 0,
  fatorPontuacao: 0,
  preco: 0,
  limiteTrocaClientes: 0,
  total: 0,
  idLoja: 0,
  page: 0,
  limit: 10,
  order: 'nome',
  direction: 'asc',
  term: ''
}

export function produto(state = initialState, action) {
  switch (action.type) {
    case 'FETCHED_ALL_PRODUTO':
      return {
        ...state,
        produto: action.produto,
        totalRegistros: action.totalRegistros
      }
    case 'PRODUTO_DETAIL':
      return {
        ...state,
        isLoading: false,
        id: action.id,
        nome: action.nome,
        descricao: action.descricao,
        idProdutoReferencia: action.idProdutoReferencia,
        caminhoImagem: action.caminhoImagem,
        trocavel: action.trocavel,
        novidade: action.novidade,
        pontos: action.pontos,
        fatorPontuacao: action.fatorPontuacao,
        preco: action.preco,
        limiteTrocaClientes: action.limiteTrocaClientes,
        total: action.total,
        idLoja: action.idLoja
      }
    case 'PRODUTO_UPDATED':
      return state
    case 'PRODUTO_CLEAR':
      return {
        ...initialState,
        page: state.page,
        limit: state.limit,
        order: state.order,
        direction: state.direction,
        term: state.term
      }
    case 'PRODUTO_CLEAR_ALL':
      return {
        ...state,
        produto: []
      }
    case 'PRODUTO_HANDLE_ON_CHANGE':
      return {
        ...state,
        [action.props]: action.value
      }
    case 'PRODUTO_ISLOADING':
      return {
        ...state,
        isLoading: true
      }
    case 'PRODUTO_NOTISLOADING':
      return {
        ...state,
        isLoading: false
      }
    default:
      return state
  }
}
