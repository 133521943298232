import React, { useEffect, useState } from 'react'
import { connect, useDispatch, useSelector } from 'react-redux'
import { respostaChecklistOperacaoAction } from '../../_actions'
import PropTypes from 'prop-types'
import {
  withStyles,
  Table,
  TableContainer,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Tooltip,
  Input,
  Grid,
  TablePagination
} from '@material-ui/core'
import DeleteIcon from '@material-ui/icons/Delete'
import EditIcon from '@material-ui/icons/Edit'
import { withRouter } from 'react-router-dom'
import { history } from '../../utils'
import TitleContent from '../TitleContent'
import { useConfirm } from 'material-ui-confirm'
import { AccessControl } from '../../components/Utils/AccessControl'
import AccessDenied from '../Errors/AccessDenied'
import { Page } from 'components'
import { useDebounce } from 'use-debounce'
import moment from 'moment'

const styles = (theme) => ({
  root: {
    padding: theme.spacing(3, 2, 2, 2)
  }
})

const RespostaChecklistOperacao = (props) => {

  const confirm = useConfirm()
  const dispatch = useDispatch()
  const { classes } = props
  const { respostaChecklistOperacao } = props.respostaChecklistOperacao
  const { totalRegistros } = props.respostaChecklistOperacao

  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [term, setTerm] = useState('');
  const [debounceTerm] = useDebounce(term, 1000);
  const [order] = useState('nome');
  const [direction] = useState('asc');
  const [ready, setReady] = React.useState(false);

  const { idLoja } = useSelector(
    (state) => state.usuarioLoja
  )

  useEffect(() => {

    const fetchData = (page, limit, term) => {

      let filterModel = {
        Page: page,
        Limit: limit,
        Term: term,
        Order: order,
        Direction: direction,
        IdLoja: (idLoja && idLoja !== '' ? idLoja : 0)
      };

      dispatch(respostaChecklistOperacaoAction.getRespostaChecklistOperacao(filterModel, true));
    };

    fetchData(page, limit, debounceTerm, order, direction);

  }, [page, limit, debounceTerm, order, direction, idLoja]);

  useEffect(() => {

  }, [totalRegistros]);

  useEffect(() => {

  }, [ready, props.respostaChecklistOperacao.isLoading])

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeLimit = (event) => {
    setLimit(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleClickDelete = (id) => {
    confirm({
      title: 'Você deseja excluir esta Resposta de Checklist de Operação?',
      description: 'Essa operação é irreversível',
      disabled: props.respostaChecklistOperacao.isLoading,
      confirmationText: props.respostaChecklistOperacao.isLoading ? 'Excluindo Resposta de Checklist...' : 'Sim, excluir!',
      cancellationText: 'Não!'

    }).then(() => {
      dispatch(respostaChecklistOperacaoAction.deleteRespostaChecklistOperacaoById(id))
    })
  }

  const handleChange = prop => event => {
    setTerm(event.target.value);
  };

  return (
    <Page className={classes.root} title="Checklists de Operação">
      <AccessControl
        rule={'respostasChecklists'} //permissão necessária para acessar conteúdo
        yes={() => (
          <div>
            {undefined !== respostaChecklistOperacao && respostaChecklistOperacao.length > 0 ? (
              <TitleContent
                rule={'respostasChecklists.create'}
                length={respostaChecklistOperacao.length}
                subTitle={'GERÊNCIA DE RESPOSTA DE CHECKLIST DE OPERAÇÃO'}
                title={'Lista de Respostas de Checklists de Operação'}
                href={'/respostaChecklistOperacao'}
              />
            ) : (
              <TitleContent
                rule={'respostasChecklists.create'}
                length={0}
                subTitle={'GERÊNCIA DE RESPOSTA DE CHECKLIST DE OPERAÇÃO'}
                title={'Lista de Respostas de Checklists de Operação'}
                href={'/respostaChecklistOperacao'}
              />
            )}
            <Grid item xl={4} lg={4} md={6} xs={12}>
              <Input
                id="term"
                label="term"
                placeholder="Filtro"
                value={term}
                onChange={handleChange('term')}
                fullWidth
              />
            </Grid>
            <br />
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Data/Hora</TableCell>
                    <TableCell>Checklist de Operação</TableCell>
                    <TableCell>Usuário</TableCell>
                    <TableCell align="right">Ações</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {undefined !== respostaChecklistOperacao && respostaChecklistOperacao.length
                    ? respostaChecklistOperacao.map((n) => {
                      return (
                        <TableRow hover key={n.id}>
                          <TableCell component="th" scope="row">
                            {n.dataHora ? moment(n.dataHora).format("DD/MM/YYYY HH:mm") : ''}
                          </TableCell>
                          <TableCell component="th" scope="row">
                            {n.nomeChecklistOperacao ? n.nomeChecklistOperacao : ''}
                          </TableCell>
                          <TableCell component="th" scope="row">
                            {n.nomeUsuario ? n.nomeUsuario : ''}
                          </TableCell>

                          <TableCell align="right">
                            <AccessControl
                              rule={'respostasChecklists.edit'} //permissão necessária para acessar conteúdo
                              yes={() => (
                                <IconButton
                                  aria-label="Edit"
                                  component="a"
                                  onClick={() =>
                                    history.push(`/respostaChecklistOperacao/${n.id}`)
                                  }
                                  size="small"
                                >
                                  <Tooltip title="Editar">
                                    <Tooltip >
                                      <EditIcon />
                                    </Tooltip>
                                  </Tooltip>
                                </IconButton>
                              )}
                            />
                            <AccessControl
                              rule={'respostasChecklists.delete'} //permissão necessária para acessar conteúdo
                              yes={() => (
                                <IconButton
                                  aria-label="Delete"
                                  onClick={() => handleClickDelete(n.id)}
                                  size="small"
                                >
                                  <Tooltip
                                    title="Excluir"
                                  >
                                    <DeleteIcon />
                                  </Tooltip>
                                </IconButton>
                              )}
                            />
                          </TableCell>
                        </TableRow>
                      )
                    })
                    : null}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              component="div"
              count={totalRegistros}
              page={page}
              onPageChange={handleChangePage}
              rowsPerPage={limit}
              onRowsPerPageChange={handleChangeLimit}
            />
          </div>
        )}
        no={() => <AccessDenied />}
      />
    </Page>
  )
}
RespostaChecklistOperacao.propTypes = {
  classes: PropTypes.object.isRequired
}
const mapStateToProps = (state) => {
  return {
    respostaChecklistOperacao: state.respostaChecklistOperacao
  }
}
const connectedRespostaChecklistOperacaoPage = withRouter(
  connect(mapStateToProps, null, null, {
    pure: false
  })(withStyles(styles)(RespostaChecklistOperacao))
)
export { connectedRespostaChecklistOperacaoPage as RespostaChecklistOperacao }
