/// <reference path="../../_reducers/notificacao.reducer.js" />
import React, { useEffect, useState } from 'react'
import { connect, useDispatch, useSelector } from 'react-redux'
import { clienteAction, usuarioAction } from '../../_actions'
import { phoneMask, history } from '../../utils'
import { Avatar } from '@material-ui/core'
import Skeleton from "@material-ui/lab/Skeleton";
import { deepOrange } from '@material-ui/core/colors';
import VisibilityTwoToneIcon from '@material-ui/icons/VisibilityTwoTone';
import PropTypes from 'prop-types'
import {
  withStyles,
  Tooltip,
  Table,
  TableContainer,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Paper,
  Input,
  IconButton,
  Grid,
  TablePagination
} from '@material-ui/core'
import { withRouter } from 'react-router-dom'
import DeleteIcon from '@material-ui/icons/Delete';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import { AccessControl } from '../../components/Utils/AccessControl'
import AccessDenied from '../Errors/AccessDenied'
import { Page, PaginationActions } from 'components'
import { useDebounce } from 'use-debounce';
import moment from 'moment'
import TitleContentCliente from '../TitleContentCliente'
import { useConfirm } from 'material-ui-confirm'

const styles = (theme) => ({
  root: {
    padding: theme.spacing(3, 2, 2, 2)
  }
})

const Cliente = (props) => {

  const dispatch = useDispatch()
  const confirm = useConfirm()
  const { classes } = props
  const { totalRegistros } = props.cliente
  const { isLoading } = props.cliente
  const { cliente } = props.cliente
  const { idLoja } = useSelector(state => state.usuarioLoja)
  const { page, limit, term, direction, order } = useSelector(state => state.cliente);

  const [chaveCupomTerm, setChaveCupomTerm] = useState('')

  const [debounceTerm] = useDebounce(term, 1000)
  const [debounceChaveCupomTerm] = useDebounce(chaveCupomTerm, 1000)

  useEffect(() => {
    fetchData();
  }, [page, limit, debounceTerm, debounceChaveCupomTerm]);

  useEffect(() => {
    handleChangePage(null, 0);
  }, [totalRegistros])

  useEffect(() => {
    handleChangePage(null, page);
    fetchData();
  }, [idLoja]);

  const fetchData = () => {
    let filterModel = {
      Page: page || 0,
      Limit: limit || 10,
      Term: debounceTerm || '',
      ChaveCupomTerm: chaveCupomTerm || '',
      Order: order || 'nome',
      Direction: direction || 'asc',
      IdLoja: (idLoja && idLoja !== '') ? idLoja : 0
    };

    dispatch(clienteAction.getCliente(filterModel, true));
  };

  const handleChange = prop => event => {
    dispatch(clienteAction.onChangeProps(prop, event))
  };

  const handleChangePage = (_, value) => {
    const fakeEvent = {
      type: 'text',
      target: {
        value
      }
    }
    dispatch(clienteAction.onChangeProps('page', fakeEvent))
  }

  const handleChangeTerm = (value) => {
    const fakeEvent = {
      type: 'text',
      target: {
        value
      }
    }
    props.cliente.page = 0;
    dispatch(clienteAction.onChangeProps('term', fakeEvent))
  }

  const handleDeleteUsuarioClienteTelefone = (id) => {
    confirm({
      title: 'Você deseja excluir este cliente?',
      description: 'Essa operação é irreversível',
      disabled: props.usuario.isLoading,
      confirmationText: props.usuario.isLoading ? 'Excluindo cliente...' : 'Sim, excluir!',
    }).then(() => {
      dispatch(usuarioAction.deleteUsuarioById(id))
    }).finally(() => {
      let filterModel = {
        Page: page,
        Limit: limit,
        Term: term,
        Order: order,
        Direction: direction,
        IdLoja: (idLoja && idLoja !== '' ? idLoja : 0)
      };

      dispatch(clienteAction.getCliente(filterModel, true));
    })
  }

  return (
    <Page className={classes.root} title="Clientes">
      <AccessControl
        rule={'clientes.list'} //permissão necessária para acessar conteúdo
        yes={() => (
          <div>
            <TitleContentCliente
              rule={'clientes.list'}
              subTitle={'GERÊNCIA DE CLIENTES'}
              title={'Lista de Clientes'}
              href={'/insumo'}
            />
            <Grid container spacing={2}>
              <Grid item xl={4} lg={4} md={6} xs={12}>
                <Input
                  id="term"
                  label="term"
                  placeholder="Filtro"
                  value={term}
                  onChange={(event) => handleChangeTerm(event.target.value)}
                  fullWidth
                />
              </Grid>
              <Grid item xl={4} lg={4} md={6} xs={12}>
                <Input
                  id="chaveCupomTerm"
                  label="chaveCupomTerm"
                  placeholder="Chave Cupom"
                  value={chaveCupomTerm}
                  onChange={(event) => setChaveCupomTerm(event.target.value)}
                  fullWidth
                />
              </Grid>
            </Grid>
            <br />
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell style={{ paddingLeft: 64 }}>Nome</TableCell>
                    <TableCell>E-mail</TableCell>
                    <TableCell>Telefone</TableCell>
                    <TableCell>Data/Hora Último Acesso</TableCell>
                    <TableCell align="center" colspan={2}>Ações</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {undefined !== cliente && cliente.length > 0
                    ? cliente.map((n) => {
                      return (
                        <TableRow hover key={n.id}>
                          <TableCell component="th" scope="row" align="right">
                            {n.usuario && n.usuario.caminhoImagem ?
                              <Grid style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                <Avatar
                                  alt={n.usuario.nome}
                                  className={classes.avatar}
                                  src={n.usuario.caminhoImagem}
                                />
                                <span style={{ paddingLeft: 15, verticalAlign: 'center' }}>  {n.usuario.nome.toUpperCase()} {(n.usuario.sobrenome) ? (n.usuario.sobrenome.toUpperCase()) : ''} </span>
                              </Grid>
                              : n.usuario ?
                                <Grid style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                  <Avatar
                                    sx={{ bgcolor: deepOrange[500] }}
                                    alt={n.usuario.nome}
                                    src="/broken-image.jpg"
                                  />
                                  <span style={{ paddingLeft: 15, verticalAlign: 'center' }}>  {n.usuario.nome.toUpperCase()} {(n.usuario.sobrenome) ? (n.usuario.sobrenome.toUpperCase()) : ''} </span>
                                </Grid>
                                : null
                            }
                          </TableCell>
                          <TableCell component="th" scope="row">
                            {n.usuario ? (n.usuario.email) ? (n.usuario.email.toLowerCase()) : ('') : ''}
                          </TableCell>
                          <TableCell component="th" scope="row">
                            {n.telefone ? phoneMask(n.telefone.slice(-11)) : ''}
                          </TableCell>
                          <TableCell component="th" scope="row">
                            {n.usuario ? (n.usuario.dataHoraUltimoAcesso) ? (moment(n.usuario.dataHoraUltimoAcesso).format("DD/MM/YYYY HH:mm")) : ('') : ''}
                          </TableCell>
                          <TableCell align="right">
                            {(n.telefone ?
                              <AccessControl
                                rule={'clientes.list'} //permissão necessária para acessar conteúdo
                                yes={() => (
                                  <IconButton
                                    aria-label="SendWhatsApp"
                                    size="small"
                                    href={'http://wa.me/' + n.telefone}
                                    target="_blank"
                                  >
                                    <Tooltip title="Enviar mensagem">
                                      <WhatsAppIcon />
                                    </Tooltip>
                                  </IconButton>
                                )}
                              />
                              : null
                            )}
                            <AccessControl
                              rule={'clientes.list'} //permissão necessária para acessar conteúdo
                              yes={() => (
                                <IconButton
                                  aria-label="Ver perfil"
                                  component="a"
                                  onClick={() => history.push(window.open(`/cliente/${n.id}`))}
                                  size="small"
                                >
                                  <Tooltip title={'Visualizar perfil'}>
                                    <Tooltip >
                                      <VisibilityTwoToneIcon />
                                    </Tooltip>
                                  </Tooltip>
                                </IconButton>
                              )}
                            />
                            <AccessControl
                              rule={'usuarios.delete'} //permissão necessária para acessar conteúdo
                              yes={() => (
                                <IconButton
                                  aria-label="Delete"
                                  onClick={() => handleDeleteUsuarioClienteTelefone(n.usuario.id)}
                                  size="small"
                                >
                                  <Tooltip title="Excluir cliente">
                                    <DeleteIcon />
                                  </Tooltip>
                                </IconButton>
                              )}
                            />
                          </TableCell>
                        </TableRow>
                      )
                    })
                    :
                    (
                      !isLoading ?
                        <TableRow>
                          <TableCell rowSpan={3} colSpan={4} align="center">Nenhum registro encontrado</TableCell>
                        </TableRow>
                        :
                        <TableRow hover>
                          <TableCell component="th" scope="row">
                            <Skeleton animation="wave" />
                          </TableCell>
                          <TableCell component="th" scope="row">
                            <Skeleton animation="wave" />
                          </TableCell>
                          <TableCell component="th" scope="row">
                            <Skeleton animation="wave" />
                          </TableCell>
                          <TableCell component="th" scope="row">
                            <Skeleton animation="wave" />
                          </TableCell>
                          <TableCell component="th" scope="row">
                            <Skeleton animation="wave" />
                          </TableCell>
                        </TableRow>
                    )
                  }
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              component="div"
              count={totalRegistros}
              page={page}
              onPageChange={handleChangePage}
              rowsPerPage={limit}
              onRowsPerPageChange={handleChange('limit')}
              ActionsComponent={PaginationActions}
            />
          </div>
        )}
        no={() => <AccessDenied />}
      />
    </Page>
  )
}
Cliente.propTypes = {
  classes: PropTypes.object.isRequired
}
const mapStateToProps = (state) => {
  return state;
}
const connectedClientePage = withRouter(
  connect(mapStateToProps, null, null, {
    pure: false
  })(withStyles(styles)(Cliente))
)
export { connectedClientePage as Cliente }
