import React from 'react'
import { Link as RouterLink } from 'react-router-dom'
import { makeStyles } from '@material-ui/styles'
import { Typography, Button, useTheme, useMediaQuery } from '@material-ui/core'

import { Page } from 'components'

import video from 'img/removerConta.mp4'

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3),
    display: 'flex',
    flexDirection: 'column',
    alignContent: 'center',
    alignItems: 'center'
  },
  imageContainer: {
    marginTop: theme.spacing(6),
    display: 'flex',
    justifyContent: 'center'
  },
  image: {
    maxWidth: '100%',
    width: 560,
    maxHeight: 300,
    height: 'auto'
  },
  buttonContainer: {
    marginTop: theme.spacing(6),
    display: 'flex',
    justifyContent: 'center'
  }
}))

const Ajuda = () => {
  const classes = useStyles()
  const theme = useTheme()
  const mobileDevice = useMediaQuery(theme.breakpoints.down('sm'))

  return (
    <Page className={classes.root} title="Ajuda">
      <img
        width="100px"
        height="100px"
        alt="Logo"
        src="/images/logo_vermelho.svg"
      />
      <Typography align="center" variant={mobileDevice ? 'h4' : 'h1'}>
        Como remover minha conta?
      </Typography>
      <Typography align="center" variant="subtitle2">
        Para remover sua conta, basta seguir o passo a passo do vídeo abaixo
      </Typography>
      <div className={classes.imageContainer}>
        <video width="auto" height="500" controls >
          <source src={video} type="video/mp4" />
        </video>
      </div>
      <div className={classes.buttonContainer}>
        <Button
          color="primary"
          component={RouterLink}
          to="/principal"
          variant="outlined"
        >
          Voltar para home
        </Button>
      </div>
    </Page>
  )
}

export default Ajuda
