const initialState = {
  id: 0,
  isLoading: false,
  data: '',
  chaveNota: '',
  dataImportacao: '',
  valorTotal: '',
  valorFidelidade: '',
  idLoja: 0,
  loja: [],
  totvsVenda: []
}

export function totvsVenda(state = initialState, action) {
  switch (action.type) {     
    case 'EDIT_TOTVS_VENDA_REQUEST':
      return {
        ...state,
        loading: true
      }
    case 'EDIT_TOTVS_VENDA_SUCCESS':
      return {
        ...state,
        loading: false,
        id: action.id,
        isLoading: action.isLoading,
        data: action.data,
        chaveNota: action.chaveNota,
        dataImportacao: action.dataImportacao,
        valorTotal: action.valorTotal,
        valorFidelidade: action.valorFidelidade,
        idLoja: action.idLoja,
        loja: action.loja
      }
    case 'EDIT_TOTVS_VENDA_FAILURE':
      return {
        ...state,
        loading: false,
        error: action.error
      }
    case 'FETCHED_ALL_TOTVS_VENDA':
      return {
        ...state,
        totvsVenda: action.totvsVenda
      }
    case 'TOTVS_VENDA_DETAIL':
      return {
        ...state,
        id: action.id,
        isLoading: false,
        data: action.data,
        chaveNota: action.chaveNota,
        dataImportacao: action.dataImportacao,
        valorTotal: action.valorTotal,
        valorFidelidade: action.valorFidelidade,
        idLoja: action.idLoja,
        loja: action.loja
      }
    case 'TOTVS_VENDA_CLEAR':
      return initialState
    case 'TOTVS_VENDA_CLEAR_ALL':
      return {
        ...state,
        totvsVenda: []
      }
    case 'TOTVS_VENDA_ESTABELECIMENTO_ADD_SUCCESS':
      return {
        ...state,
        totvsVenda: [...state.totvsVenda, action.data.totvsVenda]
      }
    case 'TOTVS_VENDA_HANDLE_ON_CHANGE':
      return {
        ...state,
        [action.props]: action.value
      }
    case 'TOTVS_VENDA_ISLOADING':
      return {
        ...state,
        isLoading: true
      }
    case 'TOTVS_VENDA_NOTISLOADING':
      return {
        ...state,
        isLoading: false
      }
    default:
      return state
  }
}
