const initialState = {
  isLoading: false,
  totalRegistros: 0,
  bling: {
    dataUltimaVendaImportada: '',
    produtos: []
  },
  open: false,
  id: 0,
  codigo: '',
  descricao: '',
  tipo: '',
  situacao: '',
  unidade: '',
  preco: '',
  precoCusto: '',
  descricaoCurta: '',
  descricaoComplementar: '',
  dataInclusao: '',
  dataAlteracao: '',
  imageThumbnail: '',
  urlVideo: '',
  nomeFornecedor: '',
  codigoFabricante: '',
  marca: '',
  class_fiscal: '',
  cest: '',
  origem: '',
  idGrupoProduto: '',
  linkExterno: '',
  observacoes: '',
  grupoProduto: '',
  garantia: '',
  descricaoFornecedor: '',
  idFabricante: '',
  categoria: '',
  pesoLiq: '',
  pesoBruto: '',
  estoqueMinimo: '',
  estoqueMaximo: '',
  gtin: '',
  gtinEmbalagem: '',
  larguraProduto: '',
  alturaProduto: '',
  profundidadeProduto: '',
  unidadeMedida: '',
  itensPorCaixa: 0,
  volumes: 0,
  localizacao: '',
  crossdocking: '',
  condicao: '',
  freteGratis: '',
  producao: '',
  dataValidade: '',
  spedTipoItem: '',
  imagem: [],
  quantidadePedidoMedio: 0,
  vendas7dias: 0,
  vendas14dias: 0
}

export function bling(state = initialState, action) {
  switch (action.type) {
    case 'FETCHED_ALL_BLING':
      return {
        ...state,
        bling: action.bling,
        totalRegistros: action.totalRegistros
      }
    case 'BLING_DETAIL':
      return {
        ...state,
        isLoading: false,
        id: action.id,
        codigo: action.codigo,
        descricao: action.descricao,
        tipo: action.tipo,
        situacao: action.situacao,
        unidade: action.unidade,
        preco: action.preco,
        precoCusto: action.precoCusto,
        descricaoCurta: action.descricaoCurta,
        descricaoComplementar: action.descricaoComplementar,
        dataInclusao: action.dataInclusao,
        dataAlteracao: action.dataAlteracao,
        imageThumbnail: action.imageThumbnail,
        urlVideo: action.urlVideo,
        nomeFornecedor: action.nomeFornecedor,
        codigoFabricante: action.codigoFabricante,
        marca: action.marca,
        class_fiscal: action.class_fiscal,
        cest: action.cest,
        origem: action.origem,
        idGrupoProduto: action.idGrupoProduto,
        linkExterno: action.linkExterno,
        observacoes: action.observacoes,
        grupoProduto: action.grupoProduto,
        garantia: action.garantia,
        descricaoFornecedor: action.descricaoFornecedor,
        idFabricante: action.idFabricante,
        categoria: action.categoria,
        pesoLiq: action.pesoLiq,
        pesoBruto: action.pesoBruto,
        estoqueMinimo: action.estoqueMinimo,
        estoqueMaximo: action.estoqueMaximo,
        gtin: action.gtin,
        gtinEmbalagem: action.gtinEmbalagem,
        larguraProduto: action.larguraProduto,
        alturaProduto: action.alturaProduto,
        profundidadeProduto: action.profundidadeProduto,
        unidadeMedida: action.unidadeMedida,
        itensPorCaixa: action.itensPorCaixa,
        volumes: action.volumes,
        localizacao: action.localizacao,
        crossdocking: action.crossdocking,
        condicao: action.condicao,
        freteGratis: action.freteGratis,
        producao: action.producao,
        dataValidade: action.dataValidade,
        spedTipoItem: action.spedTipoItem,
        imagem: action.imagem,
        quantidadePedidoMedio: action.quantidadePedidoMedio,
        vendas7dias: action.vendas7dias,
        vendas14dias: action.vendas14dias
      }
    case 'BLING_CLEAR':
      return initialState
    case 'BLING_CLEAR_ALL':
      return {
        ...state,
        bling: []
      }
    case 'BLING_HANDLE_ON_CHANGE':
      return {
        ...state,
        [action.props]: action.value
      }
    case 'BLING_ISLOADING':
      return {
        ...state,
        isLoading: true
      }
    case 'BLING_NOTISLOADING':
      return {
        ...state,
        isLoading: false
      }
    default:
      return state
  }
}
