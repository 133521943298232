import { history } from 'utils'
import { crudService } from '../_services'
import { stringUtils } from 'utils'
import { toast } from 'react-toastify'

export const pedidoCompraAction = {
  getPedidoCompra,
  addPedidoCompra,
  getPedidoCompraById,
  onChangeProps,
  editPedidoCompraInfo,
  editPedidoComprasDetails,
  duplicatePedidoCompra,
  createPedidoCompra,
  sendPedidoCompra,
  markAsSentPedidoCompra,
  markAsReceivedPedidoCompra,
  downloadFilePedidoCompra,
  sendEmailPedidoCompra,
  deletePedidoCompraById,
  deletePedidoCompraAdminById,
  clear,
  clearAll,
  clearPedidosComprasProdutos
}

let lastFilterModel = {
  Page: 0,
  Limit: 10,
  Term: '',
  Order: 'dataHora',
  Direction: 'desc',
  IdLoja: 0
}

function getPedidoCompra(filterModel, showWaiting) {
  return (dispatch) => {
    dispatch(isLoading())
    lastFilterModel = filterModel;
    let apiEndpoint = 'pedidosCompras' + stringUtils.serialize(filterModel);
    crudService
      .get(apiEndpoint)
      .then((response) => {
        dispatch(changePedidoComprasList(response.data.items, response.data.totalRows))
        dispatch(notIsLoading())
      })
      .catch((err) => {
        console.log(err)
      })
  }
}

function createPedidoCompra(payload, redirect, toggle, setModalId) {
  return (dispatch) => {
    dispatch(isLoading())
    let apiEndpoint = 'pedidosCompras/'
    crudService.post(apiEndpoint, payload)
      .then((response) => {
        if (response.request != undefined && response.request.status === 200) {
          dispatch(createPedidoCompraInfo())
          toast.success('Feito! Cadastro realizado com sucesso! 😎')
          dispatch(clear())
          dispatch(notIsLoading())
          if (redirect) {
            dispatch(notIsModalOpen())
            history.push('/pedidosCompras')
          } else {
            //dispatch(isModalOpen())
            var ret = JSON.parse(response.request.responseText);
            setModalId(ret.key);
            toggle();
          }
        } else {
         // toast.err('Oops! Erro ao cadastrar Pedido de Compra! 😥')
        }
      }).catch((err) => {
        //  console.log(err.response);
        dispatch(notIsLoading())
      //  toast.error('Oops! Erro ao cadastrar Pedido de Compra! 😥')
        return err.response;
      })
  }
}

function duplicatePedidoCompra(id, redirect) {
  return (dispatch) => {
    let apiEndpoint = 'pedidosCompras/duplicar/' + id
    crudService.post(apiEndpoint).then((response) => {
      dispatch(createPedidoCompraInfo())
      toast.success('Feito! Pedido de Compra duplicado com sucesso! 😎')
      dispatch(clear())
      dispatch(notIsLoading())
      if (redirect) {
        history.push('/pedidosCompras')
      }
    })
  }
}

function sendPedidoCompra(id, toggle) {
  return (dispatch) => {
    dispatch(isLoading())
    let apiEndpoint = 'pedidosCompras/enviar/' + id;
    crudService.post(apiEndpoint)
      .then((response) => {
        if (response.request != undefined && response.request.status === 200) {
          dispatch(createPedidoCompraInfo())
          toast.success('Feito! Envio realizado com sucesso! 😎')
          dispatch(clear())
          dispatch(notIsLoading())
          toggle()
          dispatch(getPedidoCompra(lastFilterModel))
        } else {
          toast.err('Oops! Erro ao enviar Pedido de Compra! 😥')
        }
      }).catch((err) => {
        //  console.log(err.response);
        dispatch(notIsLoading())
        toast.error('Oops! Erro ao cadastrar pedidoCompra! 😥')
        return err.response;
      })
  }
}

function markAsSentPedidoCompra(id) {
  return (dispatch) => {
    //dispatch(isLoading())
    let apiEndpoint = 'pedidosCompras/enviado/' + id;
    crudService.post(apiEndpoint)
      .then((response) => {
        if (response.request != undefined && response.request.status === 200) {
          dispatch(getPedidoCompra(lastFilterModel))
          history.push('/pedidosCompras')
          //dispatch(createPedidoCompraInfo())
          //toast.success('Feito! Envio realizado com sucesso! 😎')
          //dispatch(clear())
          //dispatch(notIsLoading())          
        } else {
          toast.err('Oops! Erro ao marcar Pedido de Compra como enviado! 😥')
        }
      }).catch((err) => {
        //  console.log(err.response);
        //dispatch(notIsLoading())
        toast.error('Oops! Erro ao marcar Pedido de Compra como enviado! 😥')
        return err.response;
      })
  }
}

function markAsReceivedPedidoCompra(id) {
  return (dispatch) => {
    //dispatch(isLoading())
    let apiEndpoint = 'pedidosCompras/recebido/' + id;
    crudService.post(apiEndpoint)
      .then((response) => {
        if (response.request != undefined && response.request.status === 200) {
          toast.success('Feito! Atendimento de pedido de compra confirmado! 😎') 
        } else {
          toast.err('Oops! Erro ao marcar Pedido de Compra como recebido! 😥')
        }
      }).catch((err) => {
        //  console.log(err.response);
        //dispatch(notIsLoading())
        toast.error('Oops! Erro ao marcar Pedido de Compra como recebido! 😥')
        return err.response;
      })
  }
}

function downloadFilePedidoCompra(id) {
  return (dispatch) => {
    //dispatch(isLoading())
    let apiEndpoint = 'pedidosCompras/arquivo/' + id;
    crudService.post(apiEndpoint)
      .then((response) => {

        if (response.request != undefined && response.request.status === 200) {
          //const BOM = "\uFEFF";

          var blob = new Blob(
            [response.data], // Blob parts.
            {
              type: "application/vnd.ms-excel",
            }
          );

          const href = URL.createObjectURL(blob);

          //const href = URL.createObjectURL(new Blob(response.data, { type: "octet/stream" }));

          // create "a" HTML element with href to file & click
          const link = document.createElement('a');
          link.href = href;
          link.setAttribute('download', 'pedidoCompra_' + id + '.xls'); //or any other extension
          document.body.appendChild(link);
          link.click();

          document.body.removeChild(link);
          URL.revokeObjectURL(href);
        } else {
          toast.err('Oops! Erro ao Baixar Pedido de Compra! 😥')
        }
      }).catch((err) => {
        //  console.log(err.response);
        //dispatch(notIsLoading())
        toast.error('Oops! Erro ao baixar Pedido de Compra! 😥')
        return err.response;
      })
  }
}

function sendEmailPedidoCompra(id, toggle) {
  return (dispatch) => {
    dispatch(isLoading())
    let apiEndpoint = 'pedidosCompras/email/' + id;
    crudService.post(apiEndpoint)
      .then((response) => {
        if (response.request != undefined && response.request.status === 200) {
          dispatch(createPedidoCompraInfo())
          toast.success('E-mail enviado com sucesso! Peça para o fornecedor conferir a caixa de entrada! 😎', {
            autoClose: false,
            closeOnClick: true,
          })
          dispatch(clear())
          dispatch(notIsLoading())
          toggle()
          dispatch(getPedidoCompra(lastFilterModel))
        } else {
          toast.err('Oops! Erro ao enviar e-email do Pedido de Compra! 😥')
          dispatch(notIsLoading())
        }
      }).catch((err) => {
        //  console.log(err.response);
        //dispatch(notIsLoading())
        toast.error('Oops! Erro ao enviar e-mail do Pedido de Compra! 😥')
        return err.response;
      })
  }
}

function getPedidoCompraById(id) {
  return (dispatch) => {
    dispatch(isLoading())
    let apiEndpoint = 'pedidosCompras/' + id
    crudService.get(apiEndpoint).then((response) => {
      dispatch(editPedidoComprasDetails(response.data))
      dispatch(notIsLoading())
    })
  }
}

function onChangeProps(props, event) {
  return (dispatch) => {
    if (props === 'pedidosComprasProdutos') {
      //ispatch(handleOnChangePropsProdutos(props, event))
      dispatch(handleOnChangeProps(props, event))
    } else {
      if (event.target.type === 'checkbox') {
        dispatch(handleOnChangeProps(props, event.target.checked))
      } else {
        dispatch(handleOnChangeProps(props, event.target.value))
      }
    }
  }
}

function editPedidoCompraInfo(id, payload, redirect) {
  return (dispatch) => {
    dispatch(isLoading())
    let apiEndpoint = 'pedidosCompras/' + id
    crudService.put(apiEndpoint, payload)
      .then((response) => {
        if (response.request != undefined && response.request.status === 200) {
          dispatch(updatedPedidoCompraInfo())
          if (redirect) {
            dispatch(clear())
            dispatch(notIsLoading())
            dispatch(notIsModalOpen())
            history.push('/pedidosCompras')
          } else {
            dispatch(isModalOpen())
          }
        }
      }).catch((err) => {
          //  console.log(err.response);
        dispatch(notIsLoading())
       // toast.error('Oops! Erro ao atualizar Pedido de Compra! 😥')
          return err.response;
      })
  }
}

function deletePedidoCompraById(id) {
  return (dispatch) => {
    dispatch(isLoading())
    let apiEndpoint = 'pedidosCompras/' + id
    crudService.deleteDetail(apiEndpoint).then(() => {
      dispatch(deletePedidoComprasDetails())
      dispatch(notIsLoading())
      dispatch(getPedidoCompra(lastFilterModel))
    })
  }
}

function deletePedidoCompraAdminById(id) {
  return (dispatch) => {
    dispatch(isLoading())
    let apiEndpoint = 'pedidosCompras/admin/' + id
    crudService.deleteDetail(apiEndpoint).then(() => {
      dispatch(deletePedidoComprasDetails())
      dispatch(notIsLoading())
      dispatch(getPedidoCompra(lastFilterModel))
    })
  }
}

export function addPedidoCompra(payload) {
  return [{ type: 'ADD_PEDIDO_COMPRA', pedidoCompra: payload }, clear()]
}

export function changePedidoComprasList(pedidoCompra, totalRows) {
  return {
    type: 'FETCHED_ALL_PEDIDO_COMPRA',
    pedidoCompra: pedidoCompra,
    totalRegistros: totalRows
  }
}

export function clear() {
  return {
    type: 'PEDIDO_COMPRA_CLEAR'
  }
}

export function clearAll() {
  return {
    type: 'PEDIDO_COMPRA_CLEAR_ALL'
  }
}

export function clearPedidosComprasProdutos() {
  return {
    type: 'PEDIDO_COMPRA_CLEAR_PEDIDOSCOMPRASPRODUTOS'
  }
}

export function handleOnChangeProps(props, value) {
  return {
    type: 'PEDIDO_COMPRA_HANDLE_ON_CHANGE',
    props: props,
    value: value
  }
}

export function handleOnChangePropsProdutos(props, value) {
  return {
    type: 'PEDIDO_COMPRA_HANDLE_ON_CHANGE_PRODUTOS',
    props: props,
    value: value
  }
}

export function editPedidoComprasDetails(pedidoCompra) {
  return {
    type: 'PEDIDO_COMPRA_DETAIL',
    id: pedidoCompra.id,
    isLoading: pedidoCompra.isLoading,
    idLoja: pedidoCompra.idLoja,
    idFornecedor: pedidoCompra.idFornecedor,
    dataHora: pedidoCompra.dataHora,
    dataHoraEnvio: pedidoCompra.dataHoraEnvio,
    situacao: pedidoCompra.situacao,
    numero: pedidoCompra.numero,
    excluido: pedidoCompra.excluido,
    loja: pedidoCompra.loja,
    fornecedor: pedidoCompra.fornecedor,
    lojaNome: pedidoCompra.lojaNome,
    lojaCnpj: pedidoCompra.lojaCnpj,
    fornecedorNome: pedidoCompra.fornecedorNome,
    fornecedorIntegracao: pedidoCompra.fornecedorIntegracao,
    fornecedorIntegracaoArquivo: pedidoCompra.fornecedorIntegracaoArquivo,
    fornecedorEmail: pedidoCompra.fornecedorEmail,
    pedidosComprasProdutos: pedidoCompra.pedidosComprasProdutos
  }
}

export function updatedPedidoCompraInfo() {
  return {
    type: 'PEDIDO_COMPRA_UPDATED'
  }
}

export function createPedidoCompraInfo() {
  return {
    type: 'PEDIDO_COMPRA_CREATED_SUCCESSFULLY'
  }
}

export function deletePedidoComprasDetails() {
  return {
    type: 'DELETED_PEDIDO_COMPRA_DETAILS'
  }
}
export function isLoading() {
  return {
    type: 'PEDIDO_COMPRA_ISLOADING'
  }
}

export function notIsLoading() {
  return {
    type: 'PEDIDO_COMPRA_NOTISLOADING'
  }
}

export function isModalOpen() {
  return {
    type: 'PEDIDO_COMPRA_ISMODALOPEN'
  }
}

export function notIsModalOpen() {
  return {
    type: 'PEDIDO_COMPRA_NOTISMODALOPEN'
  }
}
