import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/styles'
import {
  Card,
  Typography,
  Grid
} from '@material-ui/core'

import { Skeleton } from '@material-ui/lab'

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: 10
  },
  content: {
    padding: 0
  },
  item: {
    padding: theme.spacing(2),
    textAlign: 'center',
    [theme.breakpoints.up('md')]: {
      '&:not(:last-of-type)': {
        borderRight: `1px solid ${theme.palette.divider}`
      }
    },
    [theme.breakpoints.down('sm')]: {
      '&:not(:last-of-type)': {
        borderBottom: `1px solid ${theme.palette.divider}`
      }
    }
  },
  titleWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  label: {
    marginLeft: theme.spacing(1),
    marginTop: theme.spacing(1)
  },
  h2: {
    paddingTop: theme.spacing(1)
  },
  header: {
    marginBottom: theme.spacing(-7)
  }
}))

const StatisticsFidelidade = (props) => {
  const { className, dashboardHome, isLoading, ...rest } = props
  const classes = useStyles()

  return (
      
    <Card {...rest} className={clsx(classes.root, className)}>
      <Grid alignItems="center" container justifyContent="space-between">
        <Grid className={classes.item} item md={3} sm={6} xs={12}>
          <Typography gutterBottom variant="overline">
            Cupons fidelizados nos últimos 30 dias
          </Typography>
          <Typography className={classes.h2} variant="h2">
            {isLoading ? (
              <Skeleton />
            ) : (
                dashboardHome.cuponsFidelizados30dias
            )}
          </Typography>
        </Grid>
        <Grid className={classes.item} item md={3} sm={6} xs={12}>
          <Typography gutterBottom variant="overline">
            Trocas realizadas nos últimos 30 dias
          </Typography>
          <Typography className={classes.h2} variant="h2">
            {isLoading ? <Skeleton /> : dashboardHome.trocas30dias}
          </Typography>
        </Grid>        
        <Grid className={classes.item} item md={3} sm={6} xs={12}>
          <Typography gutterBottom variant="overline">
            Clientes fidelizados
          </Typography>
          <Typography className={classes.h2} variant="h2">
            {isLoading ? <Skeleton /> : dashboardHome.clientesFidelizados}
          </Typography>
        </Grid>
        <Grid className={classes.item} item md={3} sm={6} xs={12}>
          <Typography gutterBottom variant="overline">
            Clientes ativos no APP nos últimos 30 dias
          </Typography>
          <Typography className={classes.h2} variant="h2">
            {isLoading ? <Skeleton /> : dashboardHome.clientesAtivos30dias}
          </Typography>
        </Grid>
      </Grid>
      </Card>
   
  )
}

StatisticsFidelidade.propTypes = {
  className: PropTypes.string,
  dashboardHome: PropTypes.object.isRequired
}

export default StatisticsFidelidade
