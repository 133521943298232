import React, { useEffect } from 'react'
import { connect, useDispatch, useSelector } from 'react-redux'
import { clienteAction } from '../../_actions'
import { cpfMask, phoneMask, history } from '../../utils'
import Skeleton from "@material-ui/lab/Skeleton";
import VisibilityTwoToneIcon from '@material-ui/icons/VisibilityTwoTone';
import PropTypes from 'prop-types'
import {
  withStyles,
  Tooltip,
  Table,
  TableContainer,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Paper,
  Input,
  IconButton,
  Grid,
  TablePagination
} from '@material-ui/core'
import { withRouter } from 'react-router-dom'
import { AccessControl } from '../../components/Utils/AccessControl'
import AccessDenied from '../Errors/AccessDenied'
import { Page, PaginationActions } from 'components'
import { useDebounce } from 'use-debounce';
import moment from 'moment'
import TitleContentParticipacao from '../TitleContentParticipacao'
import { useConfirm } from 'material-ui-confirm'

const styles = (theme) => ({
  root: {
    padding: theme.spacing(3, 2, 2, 2)
  }
})

const CampanhaParticipacoes = (props) => {

  const dispatch = useDispatch()
  const confirm = useConfirm()
  const { classes } = props
  const { match: { params } } = props;
  const { cliente, isLoading, totalRegistros } = props.cliente
  const { page, limit, term, direction, order } = useSelector(state => state.cliente);
  const [debounceTerm] = useDebounce(term, 1000)

  useEffect(() => {
    fetchData();
  }, [page, limit, debounceTerm]);

  useEffect(() => {
    handleChangePage(null, 0);
  }, [totalRegistros])

  const fetchData = () => {
    let filterModel = {
      Page: page || 0,
      Limit: limit || 10,
      Term: debounceTerm || '',
      Order: order || 'nome',
      Direction: direction || 'asc',
    };

    dispatch(clienteAction.getClientesByCampanha(filterModel, params.id, true));
  };

  const handleChange = prop => event => {
    dispatch(clienteAction.onChangeProps(prop, event))
  };

  const handleChangePage = (_, value) => {
    const fakeEvent = {
      type: 'text',
      target: {
        value
      }
    }
    dispatch(clienteAction.onChangeProps('page', fakeEvent))
  }

  const handleChangeTerm = (value) => {
    const fakeEvent = {
      type: 'text',
      target: {
        value
      }
    }
    props.cliente.page = 0;
    dispatch(clienteAction.onChangeProps('term', fakeEvent))
  }

  return (
    <Page className={classes.root} title="Participações">
      <AccessControl
        rule={'campanhas.list'} //permissão necessária para acessar conteúdo
        yes={() => (
          <div>
            <TitleContentParticipacao
              rule={'campanhas.list'}
              subTitle={'GERÊNCIA DE CAMPANHAS'}
              title={'Lista de Participações'}
              href={'/campanha'}
              idCampanha={params.id}
            />
            <Grid item xl={4} lg={4} md={6} xs={12}>
              <Input
                id="term"
                label="term"
                placeholder="Filtro"
                value={term}
                onChange={(event) => handleChangeTerm(event.target.value)}
                fullWidth
              />
            </Grid>
            <br />
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>CPF</TableCell>
                    <TableCell>Nome</TableCell>
                    <TableCell>E-mail</TableCell>
                    <TableCell>Telefone</TableCell>
                    <TableCell>Data/Hora Participação</TableCell>
                    <TableCell align="center" colspan={2}>Ação</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {undefined !== cliente && cliente.length > 0
                    ? cliente.map((n) => {
                      return (
                        <TableRow hover key={n.id}>
                          <TableCell component="th" scope="row">
                            {n.cpf ? cpfMask(n.cpf) : ''}
                          </TableCell>
                          <TableCell component="th" scope="row" align="right">
                            <Grid style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                              <span style={{ paddingLeft: 15, verticalAlign: 'center' }}>
                                {n.nome ? n.nome.toUpperCase() : ''}
                              </span>
                            </Grid>
                          </TableCell>
                          <TableCell component="th" scope="row">
                            {n.email ?? ''}
                          </TableCell>
                          <TableCell component="th" scope="row">
                            {n.telefone ? phoneMask(n.telefone.slice(-11)) : ''}
                          </TableCell>
                          <TableCell component="th" scope="row">
                            {n.dataHoraParticipacao ? moment(n.dataHoraParticipacao).format("DD/MM/YYYY HH:mm") : ''}
                          </TableCell>
                          <TableCell align="right">
                            <AccessControl
                              rule={'campanhas.list'} //permissão necessária para acessar conteúdo
                              yes={() => (
                                <IconButton
                                  aria-label="Ver perfil"
                                  component="a"
                                  onClick={() => history.push(window.open(`/cliente/${n.id}`))}
                                  size="small"
                                >
                                  <Tooltip title={'Ver perfil'}>
                                    <Tooltip >
                                      <VisibilityTwoToneIcon />
                                    </Tooltip>
                                  </Tooltip>
                                </IconButton>
                              )}
                            />
                          </TableCell>
                        </TableRow>
                      )
                    })
                    :
                    (
                      !isLoading ?
                        <TableRow>
                          <TableCell rowSpan={3} colSpan={4} align="center">Nenhum registro encontrado</TableCell>
                        </TableRow>
                        :
                        <TableRow hover>
                          <TableCell component="th" scope="row">
                            <Skeleton animation="wave" />
                          </TableCell>
                          <TableCell component="th" scope="row">
                            <Skeleton animation="wave" />
                          </TableCell>
                          <TableCell component="th" scope="row">
                            <Skeleton animation="wave" />
                          </TableCell>
                          <TableCell component="th" scope="row">
                            <Skeleton animation="wave" />
                          </TableCell>
                          <TableCell component="th" scope="row">
                            <Skeleton animation="wave" />
                          </TableCell>
                        </TableRow>
                    )
                  }
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              component="div"
              count={totalRegistros}
              page={page}
              onPageChange={handleChangePage}
              rowsPerPage={limit}
              onRowsPerPageChange={handleChange('limit')}
              ActionsComponent={PaginationActions}
            />
          </div>
        )}
        no={() => <AccessDenied />}
      />
    </Page>
  )
}

CampanhaParticipacoes.propTypes = {
  classes: PropTypes.object.isRequired
}

const mapStateToProps = (state) => {
  return state;
}

const connectedCampanhaParticipacoesPage = withRouter(
  connect(mapStateToProps, null, null, {
    pure: false
  })(withStyles(styles)(CampanhaParticipacoes))
)
export { connectedCampanhaParticipacoesPage as CampanhaParticipacoes }
