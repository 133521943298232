import { history } from 'utils'
import { crudService } from '../_services'
import { stringUtils } from 'utils'
import { toast } from 'react-toastify'

export const mensagemAction = {
  getMensagem,
  addMensagem,
  getMensagemById,
  onChangeProps,
  editMensagemInfo,
  editMensagemsDetails,
  createMensagem,
  deleteMensagemById,
  clear,
  clearAll,
  clearMensagemLojas
}

let filterModel = {
  Page: 0,
  Limit: 10,
  Term: '',
  Order: 'titulo',
  Direction: 'asc'
};

function getMensagem(filterModel, showWaiting) {  //lista todas mensagens
  return (dispatch) => {
    let apiEndpoint = 'mensagens' + stringUtils.serialize(filterModel);
    crudService
      .get(apiEndpoint)
      .then((response) => {
        dispatch(changeMensagensList(response.data.items, response.data.totalRows))
      })
      .catch((err) => {
        console.log(err)
      })
  }
}

function createMensagem(payload) {
  return (dispatch) => {
    dispatch(isLoading())
    let apiEndpoint = 'mensagens/'
    crudService.post(apiEndpoint, payload)
      .then((response) => {
        if (response.request != undefined && response.request.status === 200) {
          var ret = JSON.parse(response.request.responseText);
          dispatch(createMensagemInfo())
          toast.success('Feito! Cadastro realizado com sucesso! 😎')
          dispatch(clear())
          dispatch(notIsLoading())
          history.push('/mensagens')
        } else {
          toast.err('Oops! Erro ao cadastrar Mensagem Pedido Compra! 😥')
        }
      }).catch((err) => {
        dispatch(notIsLoading())
        toast.error('Oops! Erro ao cadastrar Mensagem Pedido Compra! 😥')
        return err.response;
      })
  }
}

function getMensagemById(id) {
  return (dispatch) => {
    let apiEndpoint = 'mensagens/' + id
    crudService.get(apiEndpoint).then((response) => {
      dispatch(editMensagemsDetails(response.data))
    })
  }
}

function onChangeProps(props, event) {
  return (dispatch) => {
    if (event.target.type === 'checkbox') {
      dispatch(handleOnChangeProps(props, event.target.checked))
    } else {
      dispatch(handleOnChangeProps(props, event.target.value))
    }
  }
}

function editMensagemInfo(id, payload, redirect) {
  return (dispatch) => {
    dispatch(isLoading())
    let apiEndpoint = 'mensagens/' + id
    crudService.put(apiEndpoint, payload)
      .then((response) => {
        if (response.request != undefined && response.request.status === 200) {
          var ret = JSON.parse(response.request.responseText);
          dispatch(updatedMensagemInfo())
          dispatch(clear())
          if (redirect) {
            dispatch(notIsLoading())
            history.push('/mensagens')
          }
        }
      }).catch((err) => {
        dispatch(notIsLoading())
        toast.error('Oops! Erro ao atualizar Mensagem! 😥')
        return err.response;
      }
  )}
}

function deleteMensagemById(id) {
  return (dispatch) => {
    dispatch(isLoading())
    let apiEndpoint = 'mensagens/' + id
    crudService.deleteDetail(apiEndpoint).then(() => {
      dispatch(deleteMensagensDetails())
      dispatch(getMensagem(filterModel))
      dispatch(notIsLoading())
    })
  }
}

export function addMensagem(payload) {
  return [{ type: 'ADD_MENSAGEM', mensagem: payload }, clear()]
}

export function changeMensagensList(mensagem, totalRows) {
  return {
    type: 'FETCHED_ALL_MENSAGEM',
    mensagem: mensagem,
    totalRegistros: totalRows
  }
}

export function clear() {
  return {
    type: 'MENSAGEM_CLEAR'
  }
}

export function clearAll() {
  return {
    type: 'MENSAGEM_CLEAR_ALL'
  }
}
export function clearMensagemLojas() {
  return {
    type: 'MENSAGEM_CLEAR_MENSAGEMLOJAS'
  }
}

export function handleOnChangeProps(props, value) {
  return {
    type: 'MENSAGEM_HANDLE_ON_CHANGE',
    props: props,
    value: value
  }
}

export function editMensagemsDetails(mensagem) {
  return [
    { type: 'MENSAGEM_DETAIL', mensagem },
  ]
}

export function updatedMensagemInfo() {
  return {
    type: 'MENSAGEM_UPDATED'
  }
}

export function createMensagemInfo() {
  return {
    type: 'MENSAGEM_CREATED_SUCCESSFULLY'
  }
}

export function deleteMensagensDetails() {
  return {
    type: 'DELETED_MENSAGEM_DETAILS'
  }
}
export function isLoading() {
  return {
    type: 'MENSAGEM_ISLOADING'
  }
}

export function notIsLoading() {
  return {
    type: 'MENSAGEM_NOTISLOADING'
  }
}
