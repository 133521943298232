import { history } from 'utils'
import { crudService } from '../_services'
import { stringUtils } from 'utils'
import { toast } from 'react-toastify'

export const metaAction = {
  getMeta,
  addMeta,
  getMetaSelectLoja,
  getMetaById,
  onChangeProps,
  editMetaInfo,
  editMetasDetails,
  createMeta,
  sendMetaEmail,
  deleteMetaById,
  clear,
  clearAll
}

let filterModel = {
  Page: 0,
  Limit: 10,
  Term: ''
};

function getMeta(filterModel, showWaiting) {
  return (dispatch) => {
    let apiEndpoint = 'metas' + stringUtils.serialize(filterModel);
    crudService
      .get(apiEndpoint)
      .then((response) => {
        dispatch(changeMetasList(response.data.items, response.data.totalRows))
      })
      .catch((err) => {
        console.log(err)
      })
  }
}

function createMeta(payload) {
  return (dispatch) => {
    dispatch(isLoading())
    let apiEndpoint = 'metas/'
    crudService.post(apiEndpoint, payload)
      .then((response) => {
        if (response.request != undefined && response.request.status === 200) {
          var ret = JSON.parse(response.request.responseText);
          dispatch(createMetaInfo())
          toast.success('Feito! Cadastro realizado com sucesso! 😎')
          dispatch(clear())
          dispatch(notIsLoading())
          history.push('/metas')
        } else {
          toast.err('Oops! Erro ao cadastrar meta! 😥')
        }
      }).catch((err) => {
        // console.log(err.response);
        dispatch(notIsLoading())
        toast.error('Oops! Erro ao cadastrar meta! 😥')
        return err.response;
      })
  }
}

function sendMetaEmail(payload) {
  return (dispatch) => {
    dispatch(isLoading())
    let apiEndpoint = 'metas/email'

    crudService.post(apiEndpoint, payload)
      .then((response) => {
        if (response.request != undefined && response.request.status === 200) {
          toast.success('Feito! E-mail enviado com sucesso! 😎')
          dispatch(clear())
          dispatch(notIsLoading())
          history.push('/metas')
        } else {
          toast.err('Oops! Erro ao enviar e-mail! 😥')
        }
      }).catch((err) => {
        dispatch(notIsLoading())
        toast.error('Oops! Erro ao enviar e-mail! 😥')
        return err.response;
      })
  }
}

function getMetaSelectLoja(id) {
  return (dispatch) => {
    let apiEndpoint = 'metas/select/loja/' + id
    crudService
      .get(apiEndpoint)
      .then((response) => {
        if (response.data) {
          dispatch(changeMetasList(response.data))
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }
}

function getMetaById(id) {
  return (dispatch) => {
    let apiEndpoint = 'metas/' + id
    crudService.get(apiEndpoint).then((response) => {
      dispatch(editMetasDetails(response.data))
    })
  }
}

function onChangeProps(props, event) {
  return (dispatch) => {
    if (event.target.type === 'checkbox') {
      dispatch(handleOnChangeProps(props, event.target.checked))
    } else {
      dispatch(handleOnChangeProps(props, event.target.value))
    }
  }
}

function editMetaInfo(id, payload, redirect) {
  return (dispatch) => {
    dispatch(isLoading())
    let apiEndpoint = 'metas/' + id
    crudService.put(apiEndpoint, payload)
      .then((response) => {
        if (response.request != undefined && response.request.status === 200) {
          var ret = JSON.parse(response.request.responseText);
          dispatch(updatedMetaInfo())
          dispatch(clear())
          if (redirect) {
            dispatch(notIsLoading())
            history.push('/metas')
          }
        }
      }).catch((err) => {
        dispatch(notIsLoading())
        toast.error('Oops! Erro ao atualizar meta! 😥')
        return err.response;
      }
  )}
}

function deleteMetaById(id) {
  return (dispatch) => {
    dispatch(isLoading())
    let apiEndpoint = 'metas/' + id
    crudService.deleteDetail(apiEndpoint).then(() => {
      dispatch(deleteMetasDetails())
      dispatch(getMeta(filterModel))
      dispatch(notIsLoading())
      history.push('/metas')
    })
  }
}

export function addMeta(payload) {
  return [{ type: 'ADD_META', meta: payload }, clear()]
}

export function changeMetasList(meta, totalRows) {
  return {
    type: 'FETCHED_ALL_META',
    meta: meta,
    totalRegistros: totalRows
  }
}

export function clear() {
  return {
    type: 'META_CLEAR'
  }
}

export function clearAll() {
  return {
    type: 'META_CLEAR_ALL'
  }
}

export function handleOnChangeProps(props, value) {
  return {
    type: 'META_HANDLE_ON_CHANGE',
    props: props,
    value: value
  }
}

export function editMetasDetails(meta) {
  return [
    { type: 'META_DETAIL', meta },
  ]
}

export function updatedMetaInfo() {
  return {
    type: 'META_UPDATED'
  }
}

export function createMetaInfo() {
  return {
    type: 'META_CREATED_SUCCESSFULLY'
  }
}

export function deleteMetasDetails() {
  return {
    type: 'DELETED_META_DETAILS'
  }
}
export function isLoading() {
  return {
    type: 'META_ISLOADING'
  }
}

export function notIsLoading() {
  return {
    type: 'META_NOTISLOADING'
  }
}
