const initialState = {
  isLoading: false,
  nome: '',
  fantasia: '',
  cnpj: '',
  logradouro: '',
  numero: '',
  municipio: '',
  uf: '',
  bairro: '',
  cep: '',
  telefone: '',
  email: ''
}

export function fornecedorInfo(state = initialState, action) {
  switch (action.type) {
    case 'FETCHED_FORNECEDOR_INFO':
      return action.fornecedorInfo
    case 'FORNECEDOR_INFO_CLEAR':
      return initialState
    case 'FORNECEDOR_INFO_ISLOADING':
      return {
        ...state,
        isLoading: true
      }
    case 'FORNECEDOR_INFO_NOTISLOADING':
      return {
        ...state,
        isLoading: false
      }
    default:
      return state
  }
}
