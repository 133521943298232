import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Typography from '@material-ui/core/Typography'
import {
  Avatar,
  Grid,
  Link,
  CircularProgress,
  Table,
  TableContainer,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Input,
  Button
} from '@material-ui/core'
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import { Skeleton } from "@material-ui/lab";
import { pedidoCompraAction, totvsInsumoUnidadeAction } from '../../_actions'
import CloseIcon from '@material-ui/icons/Close';
import SendIcon from '@material-ui/icons/Send';
import ShareIcon from '@material-ui/icons/Share';
import SaveIcon from '@material-ui/icons/Save';
import MailIcon from '@material-ui/icons/Mail';
import moment from 'moment'
import { toast } from 'react-toastify'
import getInitials from 'utils/getInitials'
import InputAdornment from '@material-ui/core/InputAdornment';
import Tooltip from '@material-ui/core/Tooltip';
import { StickyContainer, Sticky } from 'react-sticky';
import { AccessControl } from '../../components/Utils/AccessControl'
import { Detector } from 'react-detect-offline'
import { cnpjMask } from '../../utils'
import { useConfirm } from 'material-ui-confirm'
import { history } from 'utils'

const styles = {
  //position: 'absolute',
  //top: '50%',
  //left: '50%',
  //transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4
};

export const PedidoCompraModal = ({ modal, toggle, props, id, send }) => {

  const confirm = useConfirm()
  const dispatch = useDispatch()
  const { classes, pedidoCompra, className, ...rest } = props
  const { match: { params } } = props;
  const [isOfflineOneTime, setIsOfflineOneTime] = useState(false);

  const { idLoja } = useSelector(
    (state) => state.usuarioLoja
  )
  const isLoadingPedidoCompraModal = useSelector(
    (state) => state.pedidoCompra.isLoading
  )

  useEffect(() => {

    dispatch(totvsInsumoUnidadeAction.clear())

    if (id) {
      dispatch(pedidoCompraAction.getPedidoCompraById(id))
    } else {
      dispatch(pedidoCompraAction.clear())
      dispatch(pedidoCompraAction.clearPedidosComprasProdutos())
    }

  }, []);


  useEffect(() => {
    //   console.log('props.pedidoCompra.pedidosComprasProdutos', props.pedidoCompra.pedidosComprasProdutos)
  }, [props.pedidoCompra]);


  useEffect(() => {
  }, [props.pedidoCompra.isLoading, isLoadingPedidoCompraModal])

  function getTotal() {
    var sum = props.pedidoCompra.pedidosComprasProdutos.reduce(function (prevVal, elem) {
      return prevVal + parseFloat(elem.quantidade * elem.valorUnitario);
    }, 0);
    return (sum).toLocaleString('pt-br', { minimumFractionDigits: 2 });
  }

  //const handleSubmit = (event, enviar, redirect) => {

  //  event.preventDefault();

  //  let payload = {
  //    id: (params.id ? params.id : 0),
  //    idLoja: idLoja,
  //    idFornecedor: props.pedidoCompra.idFornecedor,
  //    dataHora: moment().format('YYYY-MM-DD HH:mm'),
  //    situacao: 'E',
  //    pedidosComprasProdutos: props.pedidoCompra.pedidosComprasProdutos,
  //    enviar: enviar
  //  }

  //  if (params.id) {
  //    dispatch(pedidoCompraAction.editPedidoCompraInfo(params.id, payload, redirect))
  //  } else {
  //    dispatch(pedidoCompraAction.createPedidoCompra(payload, redirect))
  //  }

  //};

  const handleCopy = (event, id) => {

    if (props.pedidoCompra != null && props.pedidoCompra.pedidosComprasProdutos != null && props.pedidoCompra.pedidosComprasProdutos.length > 0) {

      var str = '';
      if (props.pedidoCompra != null && props.pedidoCompra.fornecedorNome != null) {
        str += 'FORNECEDOR: ' + props.pedidoCompra.fornecedorNome + '\n';
      }
      if (props.pedidoCompra != null && props.pedidoCompra.lojaNome != null) {
        str += 'LOJA: ' + props.pedidoCompra.lojaNome + '\n';
      }
      if (props.pedidoCompra != null && props.pedidoCompra.lojaCnpj != null) {
        str += 'CNPJ: ' + cnpjMask(props.pedidoCompra.lojaCnpj) + '\n';
      }
      str += 'DATA/HORA PEDIDO: ' + moment(new Date()).format('DD/MM/YYYY HH:mm') + '\n';
      str += '\n';
      str += 'ITENS DO PEDIDO: ' + '\n';
      str += '----------------------------------------';

      //  console.log("props.pedidoCompra", props.pedidoCompra)
      //   console.log("props.pedidoCompra.pedidosComprasProdutos", props.pedidoCompra.pedidosComprasProdutos)

      str += props.pedidoCompra.pedidosComprasProdutos.filter(e => e.quantidade != null && e.quantidade > 0).reduce((p, n) => `${p}\n${(n && n.quantidade != null ? n.quantidade : 0)} ${(n && n.totvsInsumoUnidade ? n.totvsInsumoUnidade.quantidadeUnidade == 1 && n.totvsInsumoUnidade.length == 3 ? n.totvsInsumoUnidade.unidade.abreviacao.toUpperCase() + ' C/ ' + n.totvsInsumoUnidade.quantidadeUnidade + '  ' : (n.totvsInsumoUnidade.quantidadeUnidade > 1 ? n.totvsInsumoUnidade.length == 2 ?
        n.totvsInsumoUnidade.unidade.abreviacao.toUpperCase() + ' C/ ' + n.totvsInsumoUnidade.quantidadeUnidade + '  ' :
        n.totvsInsumoUnidade.length == 3 && n.totvsInsumoUnidade.quantidadeUnidade.length == 2 ?
          n.totvsInsumoUnidade.unidade.abreviacao.toUpperCase() + ' C/ ' + n.totvsInsumoUnidade.quantidadeUnidade + ' ' :
          n.totvsInsumoUnidade.unidade.abreviacao.toUpperCase() + ' C/ ' + n.totvsInsumoUnidade.quantidadeUnidade + ' ' : n.totvsInsumoUnidade.unidade.abreviacao.toUpperCase() + '        ') : n.totvsInsumoUnidade.unidade.abreviacao.toUpperCase())
        } ${(n && n.totvsInsumoUnidade ? n.totvsInsumoUnidade.nome.toUpperCase() : '')}`, '');

      str += '\n';
      str += '\n';
      str += 'PREVISÃO DE ENTREGA: \n';

      navigator.clipboard.writeText(str);

      toast.success('Copiado para área de transferência (CTRL + C)! Cole o conteúdo na conversa do WhatsApp com o Fornecedor', {
        closeOnClick: true
      })

      dispatch(pedidoCompraAction.markAsSentPedidoCompra(props.pedidoCompra.id));

      toggle()
      //history.push('/pedidosCompras')

    }


  }

  //const handleDownloadFile = (id) => {
  //  confirm({
  //    title: 'Você deseja realizar o Download desse arquivo?',
  //    confirmationText: 'Sim, baixar!',
  //    cancellationText: 'Não!'
  //  }).then(() => {
  //    dispatch(pedidoCompraAction.downloadFilePedidoCompra(props.pedidoCompra.id));
  //  })
  //}

  const handleSendEmail = (id) => {
    confirm({
      title: 'Você deseja enviar o e-mail do Pedido de Compra?',
      cancellationText: 'Não!',
      disabled: isLoadingPedidoCompraModal,
      confirmationText: isLoadingPedidoCompraModal ? 'Enviando e-mail...' : 'Sim, enviar!',
    }).then(() => {
      dispatch(pedidoCompraAction.sendEmailPedidoCompra(props.pedidoCompra.id, toggle));
    })
  }

  const handleSend = (id) => {
    confirm({
      title: 'Você deseja enviar este Pedido de Compra?',
      description: 'Essa operação é irreversível',
      confirmationText: 'Sim, enviar!',
      cancellationText: 'Não!'
    }).then(() => {
      dispatch(pedidoCompraAction.sendPedidoCompra(id, toggle))
    })
  }

  const handleClose = () => {
    toggle();
    history.push('/pedidosCompras');
  }


  return (
    <Dialog
      open={modal}
      fullWidth
      maxWidth={false}
      onClose={toggle}
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
    >
      <DialogTitle>
        <Grid alignItems="flex-end" container justifyContent="space-between" spacing={3}>
          <Grid item>
            <Typography style={{ fontSize: "16px", fontWeight: 'bold', textAlign: "left", marginRight: 5 }}>
              RESUMO DO PEDIDO
            </Typography>
          </Grid>
          <AccessControl
            rule={'pedidosCompras.create'} //permissão necessária para acessar conteúdo
            yes={() => (
              <Grid item>
                <Grid item xs={6} align="right">
                  <Button
                    color="default"
                    variant="contained"
                    endIcon={<CloseIcon />}
                    onClick={() => handleClose()}
                  >
                    Fechar
                  </Button>
                </Grid>
              </Grid>
            )}
          />
        </Grid>
      </DialogTitle>
      <DialogContent sx={styles}>
        {props.pedidoCompra.idFornecedor && props.pedidoCompra.idFornecedor != '' ?
          <>
            <Grid container spacing={2}>
              <Grid item lg={12} md={12} xl={12} xs={12}>
                <TableContainer>
                  <Table id="tableResumo">
                    <TableHead>
                      <TableRow>
                        <TableCell>Descrição</TableCell>
                        <TableCell>Código Externo</TableCell>
                        <TableCell align="right">Qtd.</TableCell>
                        {(props.pedidoCompra.fornecedorIntegracao ?
                          <TableCell align="right">Preço Und.</TableCell>
                          :
                          null
                        )}
                        {(props.pedidoCompra.fornecedorIntegracao ?
                          <TableCell align="right">Valor</TableCell>
                          :
                          null
                        )}
                      </TableRow>
                    </TableHead>
                    {isLoadingPedidoCompraModal ?
                      <TableBody>
                        {Array.apply(null, { length: props.pedidoCompra.pedidosComprasProdutos.length }).map((e, i) => (
                          <TableRow hover>
                            <TableCell component="th" scope="row">
                              <Skeleton animation="wave" />
                            </TableCell>
                            <TableCell component="th" scope="row">
                              <Skeleton animation="wave" />
                            </TableCell>
                            <TableCell component="th" scope="row" align="right">
                              <Skeleton animation="wave" />
                            </TableCell>
                            {(props.pedidoCompra.fornecedorIntegracao ?
                              <TableCell component="th" scope="row" align="right">
                                <Skeleton animation="wave" />
                              </TableCell>
                              :
                              null
                            )}
                            {(props.pedidoCompra.fornecedorIntegracao ?
                              <TableCell component="th" scope="row" align="right">
                                <Skeleton animation="wave" />
                              </TableCell>
                              :
                              null
                            )}
                          </TableRow>
                        ))
                        }
                      </TableBody>
                      :
                      <TableBody>
                        {props.pedidoCompra != null && props.pedidoCompra.pedidosComprasProdutos != null && props.pedidoCompra.pedidosComprasProdutos.filter(e => e.quantidade != null && e.quantidade > 0).length > 0
                          ? props.pedidoCompra.pedidosComprasProdutos.filter(e => e.quantidade != null && e.quantidade > 0).map((n) => {
                            return (
                              <TableRow hover key={(n ? n.id : 0)}>
                                <TableCell component="th" scope="row">
                                  {n.caminhoImagem != null ?
                                    <Tooltip
                                      placement="right-start"
                                      classes={{ tooltip: classes.lightTooltip }}
                                      title={
                                        <>
                                          <Avatar
                                            key={(n ? n.id * 2 : 0)}
                                            variant="rounded"
                                            className={classes.avatar}
                                            src={n.caminhoImagem}
                                          >
                                            {getInitials(n.caminhoImagem)}
                                          </Avatar>
                                        </>
                                      }
                                    >
                                      <Link color="inherit" href={"/produtoCompra/" + (n ? n.id : '')} target="_blank">
                                        {(n && n.totvsInsumoUnidade ? n.totvsInsumoUnidade.nome.toUpperCase() : '')}
                                      </Link>
                                    </Tooltip>
                                    :
                                    n.caminhoImagem != null ?
                                      <Tooltip
                                        placement="right-start"
                                        classes={{ tooltip: classes.lightTooltip }}
                                        title={
                                          <>
                                            <Avatar
                                              key={(n ? n.id * 2 : 0)}
                                              variant="rounded"
                                              className={classes.avatar}
                                              src={n.caminhoImagem}
                                            >
                                              {getInitials(n.caminhoImagem)}
                                            </Avatar>
                                          </>
                                        }
                                      >
                                        <Link color="inherit" href={"/produtoCompra/" + (n ? n.id : '')} target="_blank">
                                          {(n && n.totvsInsumoUnidade ? n.totvsInsumoUnidade.nome.toUpperCase() : '')}
                                        </Link>
                                      </Tooltip>
                                      :
                                      <Link color="inherit" href={"/produtoCompra/" + (n ? n.id : '')} target="_blank">
                                        {(n && n.totvsInsumoUnidade ? n.totvsInsumoUnidade.nome.toUpperCase() : '')}
                                      </Link>}
                                </TableCell>
                                <TableCell component="th" scope="row">
                                  <Input
                                    id="codigoExterno"
                                    label="codigoExterno"
                                    className="form-control"
                                    value={n.totvsInsumoUnidade.codigoExterno || ''}
                                    fullWidth
                                    disabled
                                    inputProps={{
                                      min: 0,
                                      style: { width: '80%', paddingRight: '0%' }
                                    }}
                                  />
                                </TableCell>
                                <TableCell component="th" scope="row" style={{ backgroundColor: "#FAFAFA", textAlign: "end" }}>
                                  <Input
                                    id="quantidade"
                                    label="quantidade"
                                    className="form-control"
                                    type="number"
                                    value={n.quantidade}
                                    endAdornment={(n && n.totvsInsumoUnidade ? ((n.totvsInsumoUnidade.quantidadeUnidade) > 1 ?
                                      <InputAdornment position="end" fullWidth>{n.totvsInsumoUnidade.unidade.abreviacao + ' C/ ' + n.totvsInsumoUnidade.quantidadeUnidade}</InputAdornment>
                                      :
                                      <InputAdornment position="end" fullWidth>{n.totvsInsumoUnidade.unidade.abreviacao}</InputAdornment>) : '')}
                                    fullWidth
                                    disabled
                                    inputProps={{
                                      min: 0,
                                      style: { textAlign: "end", width: '80%', paddingRight: '0%' }
                                    }}
                                  />
                                </TableCell>
                                {(props.pedidoCompra.fornecedorIntegracao ?
                                  <TableCell component="th" scope="row" align="right">
                                    <Input
                                      id="valor"
                                      label="Valor"
                                      className="form-control"
                                      value={'R$ ' + (parseFloat(n.valorUnitario).toFixed(2).toString())}
                                      fullWidth
                                      disabled
                                      inputProps={{
                                        style: { textAlign: "right" }
                                      }}
                                    />
                                  </TableCell>
                                  :
                                  null
                                )}
                                {(props.pedidoCompra.fornecedorIntegracao ?
                                  <TableCell component="th" scope="row">
                                    <Input
                                      id="valor"
                                      label="Valor"
                                      className="form-control"
                                      value={'R$ ' + parseFloat(n.quantidade * n.valorUnitario).toFixed(2).toString()}
                                      fullWidth
                                      disabled
                                      inputProps={{
                                        style: { textAlign: "right" }
                                      }}
                                    />
                                  </TableCell>
                                  :
                                  null
                                )}
                              </TableRow>
                            )
                          })
                          :
                          (idLoja != '' ?
                            <TableRow>
                              <TableCell rowSpan={3} colSpan={7} align="center">Nenhuma quantidade informada</TableCell>
                            </TableRow>
                            :
                            <TableRow>
                              <TableCell rowSpan={3} colSpan={7} align="center">Informe uma Loja</TableCell>
                            </TableRow>
                          )
                        }
                      </TableBody>
                    }
                  </Table>
                </TableContainer>
              </Grid>
            </Grid>
          </>
          :
          null
        }
      </DialogContent>
      <DialogActions>
        <StickyContainer>
          <Sticky relative={false} isSticky={true}>
            {({ style }) => (
              <Grid style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-end', justifyContent: "space-between", marginRight: 12 }} spacing={3}>
                <Grid item>
                  <Typography style={{ display: 'flex', fontSize: "16px", fontWeight: 'bold', marginRight: 25, marginBottom: 7, justifyContent: 'left', alignItems: 'flex-start' }}>
                    TOTAL: R$ {getTotal()}
                  </Typography>
                </Grid>
                {/*{(props.pedidoCompra.fornecedorIntegracaoArquivo ?*/}
                {/*  <AccessControl*/}
                {/*    rule={'pedidosCompras.create'} //permissão necessária para acessar conteúdo*/}
                {/*    yes={() => (*/}
                {/*      <Grid item>*/}
                {/*        <Grid item display="flex" flexDirection="row" justifyContent="right" style={{ marginLeft: 5 }}>*/}
                {/*          <Button*/}
                {/*            color="primary"*/}
                {/*            variant="contained"*/}
                {/*            disabled={props.pedidoCompra.isLoading}*/}
                {/*            startIcon={<SaveIcon />}*/}
                {/*            endIcon={props.pedidoCompra.isLoading && <CircularProgress size={24} />}*/}
                {/*            onClick={(event) => handleDownloadFile(event, props.pedidoCompra.id)}*/}
                {/*          >*/}
                {/*            {(props.pedidoCompra.isLoading ? 'Baixando...' : 'Baixar')}*/}
                {/*          </Button>*/}
                {/*        </Grid>*/}
                {/*      </Grid>*/}
                {/*    )}*/}
                {/*  />*/}
                {/*  :*/}
                {/*  null*/}
                {/*)}*/}
                <AccessControl
                  rule={'pedidosCompras.create'} //permissão necessária para acessar conteúdo
                  yes={() => (
                    <Grid item>
                      <Grid item display="flex" flexDirection="row" justifyContent="right" style={{ marginLeft: 5 }}>
                        <Button
                          color="primary"
                          variant="contained"
                          disabled={isLoadingPedidoCompraModal}
                          startIcon={<ShareIcon />}
                          endIcon={isLoadingPedidoCompraModal && <CircularProgress size={24} />}
                          onClick={(event) => handleCopy(event, props.pedidoCompra.id)}
                        >
                          {(isLoadingPedidoCompraModal ? 'Copiando...' : 'Copiar')}
                        </Button>
                      </Grid>
                    </Grid>
                  )}
                />
                {(props.pedidoCompra.fornecedorEmail && props.pedidoCompra.fornecedorEmail != '' ?
                  <AccessControl
                    rule={'pedidosCompras.create'} //permissão necessária para acessar conteúdo
                    yes={() => (
                      <Grid item>
                        <Grid item display="flex" flexDirection="row" justifyContent="right" style={{ marginLeft: 5 }}>
                          <Button
                            color="primary"
                            variant="contained"
                            disabled={isLoadingPedidoCompraModal}
                            startIcon={<MailIcon />}
                            endIcon={isLoadingPedidoCompraModal && <CircularProgress size={24} />}
                            onClick={(event) => handleSendEmail(props.pedidoCompra.id)}
                          >
                            {(isLoadingPedidoCompraModal ? 'Enviando por E-mail...' : 'Enviar por E-mail')}
                          </Button>
                        </Grid>
                      </Grid>
                    )}
                  />
                  :
                  null
                )}
                {(props.pedidoCompra.fornecedorIntegracao ?
                  <AccessControl
                    rule={'pedidosCompras.create'} //permissão necessária para acessar conteúdo
                    yes={() => (
                      <Grid item>
                        <Detector
                          render={({ online }) => (
                            online ?
                              <Grid item xs={6} align="right" style={{ marginLeft: 5 }} >
                                <Button
                                  color="primary"
                                  variant="contained"
                                  disabled={props.pedidoCompra.isLoading}
                                  startIcon={<SendIcon />}
                                  endIcon={props.pedidoCompra.isLoading && <CircularProgress size={24} />}
                                  onClick={(event) => handleSend(props.pedidoCompra.id)}
                                  disabled={props.pedidoCompra.situacao == 'E'}
                                >
                                  {(props.pedidoCompra.isLoading ? 'Enviando...' : 'Enviar')}
                                </Button>
                                {isOfflineOneTime == true &&
                                  <script>
                                    {toast('Você está online agora!', {
                                      position: 'bottom-left',
                                      autoClose: true,
                                      toastId: 1,
                                      hideProgressBar: true,
                                      pauseOnHover: true,
                                      progress: undefined,
                                      theme: 'light',
                                    })}
                                  </script>
                                }
                              </Grid>
                              :
                              <Grid item xs={6} align="right" style={{ marginLeft: 5 }} >
                                <Button
                                  color="primary"
                                  variant="contained"
                                  disabled="true"
                                  startIcon={<SendIcon />}
                                >
                                  Enviar
                                </Button>
                                <script>
                                  {toast('Você está offline! 😥 Conecte-se a internet para prosseguir com o pedido de compra!', {
                                    position: 'bottom-left',
                                    autoClose: true,
                                    toastId: 2,
                                    hideProgressBar: true,
                                    pauseOnHover: true,
                                    progress: undefined,
                                    theme: 'light',
                                  })}
                                  {setIsOfflineOneTime(true)}
                                </script>
                              </Grid>
                          )}
                        />
                      </Grid>
                    )}
                  />
                  :
                  null
                )}
              </Grid>
            )}
          </Sticky>
        </StickyContainer>
      </DialogActions>
    </Dialog >
  )
}
