const initialState = {
  isLoading: false,
  totalRegistros: 0,
  checklistOperacaoItem: [],
  open: false,
  id: 0,
  idChecklistOperacao: 0,
  ordem: 0,
  diasVencimento: 0,
  coeficienteEstoqueMinimo: 0,
  coeficienteEstoqueMaximo: 0,
  idUnidadeProducao: 0,
  pesoLiquido: 0,
  descricao: '',
  idTotvsInsumo: 0,
  excluido: false
}

export function checklistOperacaoItem(state = initialState, action) {
  switch (action.type) {
    case 'FETCHED_ALL_CHECKLIST_OPERACAO_ITEM':
      return {
        ...state,
        checklistOperacaoItem: action.checklistOperacaoItem,
        totalRegistros: action.totalRegistros
      }
    case 'CHECKLIST_OPERACAO_ITEM_DETAIL':
      return {
        ...state,
        isLoading: false,
        id: action.id,
        descricao: action.descricao,
        idChecklistOperacao: action.idChecklistOperacao,
        idTotvsInsumo: action.idTotvsInsumo,
        ordem: action.ordem,
        diasVencimento: action.diasVencimento,
        coeficienteEstoqueMinimo: action.coeficienteEstoqueMinimo,
        coeficienteEstoqueMaximo: action.coeficienteEstoqueMaximo,
        idUnidadeProducao: action.idUnidadeProducao,
        pesoLiquido: action.pesoLiquido,
        excluido: action.excluido,
      }
    case 'CHECKLIST_OPERACAO_ITEM_UPDATED':
      return state
    case 'CHECKLIST_OPERACAO_ITEM_CLEAR':
      return initialState
    case 'CHECKLIST_OPERACAO_ITEM_CLEAR_ALL':
      return {
        ...state,
        checklistOperacaoItem: []
      }
    case 'CHECKLIST_OPERACAO_ITEM_CLEAR_LOJAS':
      return {
        ...state,
        checklistsOperacaoLojas: []
      }
    case 'CHECKLIST_OPERACAO_ITEM_CLEAR_ITENS':
      return {
        ...state,
        checklistOperacaoItem: []
      }
    case 'CHECKLIST_OPERACAO_ITEM_HANDLE_ON_CHANGE':
      return {
        ...state,
        [action.props]: action.value
      }
    case 'CHECKLIST_OPERACAO_ITEM_ISLOADING':
      return {
        ...state,
        isLoading: true
      }
    case 'CHECKLIST_OPERACAO_ITEM_NOTISLOADING':
      return {
        ...state,
        isLoading: false
      }
    default:
      return state
  }
}
