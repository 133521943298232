const initialState = {
  isLoading: false,
  totalRegistros: 0,
  listaPreco: [],
  open: false,
  id: 0,
  nome: '',
  idFornecedor: '',
  listasPrecosLojas: [],
  listasPrecosProdutos: [],
  excluido: false,
  page: 0,
  limit: 10,
  order: 'nome',
  direction: 'asc',
  term: ''
}

export function listaPreco(state = initialState, action) {
  switch (action.type) {
    case 'FETCHED_ALL_LISTA_PRECO':
      return {
        ...state,
        listaPreco: action.listaPreco,
        totalRegistros: action.totalRegistros
      }
    case 'LISTA_PRECO_DETAIL':
      return {
        ...state,
        isLoading: false,
        id: action.id,
        nome: action.nome,
        idFornecedor: action.idFornecedor,
        listasPrecosLojas: action.listasPrecosLojas,
        listasPrecosProdutos: action.listasPrecosProdutos,
        excluido: action.excluido,
      }
    case 'LISTA_PRECO_ESTADO_DETAIL':
      return {
        ...state,
        idEstado: action.idEstado
      }
    case 'LISTA_PRECO_UPDATED':
      return state
    case 'LISTA_PRECO_CLEAR':
      return {
        ...initialState,
        page: state.page,
        limit: state.limit,
        order: state.order,
        direction: state.direction,
        term: state.term
      }
    case 'LISTA_PRECO_CLEAR_ALL':
      return {
        ...state,
        listaPreco: []
      }
    case 'LISTA_PRECO_CLEAR_LOJAS':
      return {
        ...state,
        listasPrecosLojas: []
      }
    case 'LISTA_PRECO_CLEAR_PRODUTOS':
      return {
        ...state,
        listasPrecosProdutos: []
      }
    case 'LISTA_PRECO_HANDLE_ON_CHANGE':
      return {
        ...state,
        [action.props]: action.value
      }
    case 'LISTA_PRECO_ISLOADING':
      return {
        ...state,
        isLoading: true
      }
    case 'LISTA_PRECO_NOTISLOADING':
      return {
        ...state,
        isLoading: false
      }
    default:
      return state
  }
}
