const initialState = {
  dashboardHome: {
    comprasTotal: 0,
    faturamento: 0,
    cpvRealizado: 0,
    mediaPrecoEstado: 0,
    menorPrecoEstado: 0,
    impactoLucroOperacional: 0,
    resultadoAnualProjetado: 0
  },
  dashboardLoja: {
    graficoHistoricoPrecoMedioLoja: { options: [], series: [] },
    graficoHistoricoPrecoMedioNoEstado: { options: [], series: [] },
    graficoHistoricoPrecoMedioGeral: { options: [], series: [] },
    mediaPrecoLoja: 0.0,
    mediaPrecoEstado: 0.0,
    mediaPrecoRede: 0.0,
    unidadeInsumo: ''
  },
  rankingPrecoMedio: [],
  totalRankingPrecoMedio: 0,
  isLoadingRankingPrecoMedio: false,
}

export function dashboardCompra(state = initialState, action) {
  switch (action.type) {
    case 'FETCHED_DASHBOARD_COMPRAS_HOME_REQUEST':
      return {
        ...state,
        isLoading: true
      }
    case 'FETCHED_DASHBOARD_COMPRAS_HOME_SUCCESS':
      return {
        ...state,
        isLoading: false,
        dashboardHome: action.dashboardHome
      }
    case 'FETCHED_DASHBOARD_COMPRAS_HOME_FAILURE':
      return {
        ...state,
        isLoading: false,
        error: action.error
      }
    case 'FETCHED_DASHBOARD_COMPRAS_LOJA_REQUEST':
      return {
        ...state,
        isLoading: true
      }
    case 'FETCHED_DASHBOARD_COMPRAS_LOJA_SUCCESS':
      return {
        ...state,
        isLoading: false,
        dashboardLoja: action.dashboardLoja
      }
    case 'FETCHED_DASHBOARD_COMPRAS_LOJA_FAILURE':
      return {
        ...state,
        isLoading: false,
        error: action.error
      }
    case 'FETCHED_DASHBOARD_COMPRAS_LOJA_RANKING_PRECO_MEDIO_REQUEST':
      return {
        ...state,
        isLoadingRankingPrecoMedio: true
      }
    case 'FETCHED_DASHBOARD_COMPRAS_LOJA_RANKING_PRECO_MEDIO_SUCCESS':
      return {
        ...state,
        isLoadingRankingPrecoMedio: false,
        rankingPrecoMedio: action.rankingPrecoMedio,
        totalRankingPrecoMedio: action.totalRankingPrecoMedio
      }
    case 'FETCHED_DASHBOARD_COMPRAS_LOJA_RANKING_PRECO_MEDIO__FAILURE':
      return {
        ...state,
        isLoadingRankingPrecoMedio: false,
        error: action.error
      }    
    case 'DASHBOARD_COMPRAS_CLEAR':
      return initialState
    default:
      return state
  }
}
