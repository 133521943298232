const initialState = {
  isLoading: false,
  totalRegistros: 0,
  fornecedorFilial: [],
  id: 0,
  cnpj: '',
  idFornecedor: 0,
  fornecedor: null,
  excluido: false,
  fornecedoresLojas: [],
  todasLojas: false
}

export function fornecedorFilial(state = initialState, action) {
  switch (action.type) {
    case 'FETCHED_ALL_FORNECEDOR_FILIAL':
      return {
        ...state,
        fornecedorFilial: action.fornecedorFilial,
        totalRegistros: action.totalRegistros
      }
    case 'FORNECEDOR_FILIAL_DETAIL':
      return action.fornecedorFilial
    case 'FORNECEDOR_FILIAL_UPDATED':
      return state
    case 'FORNECEDOR_FILIAL_CLEAR':
      return initialState
    case 'FORNECEDOR_FILIAL_CLEAR_ALL':
      return {
        ...state,
        fornecedorFilial: []
      }
    case 'FORNECEDOR_FILIAL_HANDLE_ON_CHANGE':
      return {
        ...state,
        [action.props]: action.value
      }
    case 'FORNECEDOR_FILIAL_ISLOADING':
      return {
        ...state,
        isLoading: true
      }
    case 'FORNECEDOR_FILIAL_NOTISLOADING':
      return {
        ...state,
        isLoading: false
      }
    default:
      return state
  }
}
