const initialState = {
  isLoading: false,
  totalRegistros: 0,
  totvsInsumoConversao: [],
  id: 0,
  idTotvsInsumo: 0,
  idCategoriaCompra: 0,
  idSubcategoriaCompra: 0,
  totvsInsumo: [],
  campo: '',
  tipo: '',
  texto: '',
  campo2: '',
  tipo2: '',
  texto2: '',
  conversao: 0,
  prioridade: 3,
  tipoValor: '',
  excluido: false,
}

export function totvsInsumoConversao(state = initialState, action) {
  switch (action.type) {
    case 'FETCHED_ALL_TOTVS_INSUMO_CONVERSAO':
      return {
        ...state,
        totvsInsumoUnidadeConversaoConversao: action.totvsInsumoConversao,
        totalRegistros: action.totalRegistros
      }
    case 'TOTVS_INSUMO_CONVERSAO_DETAIL':
      return {
        ...state,
        isLoading: false,
        id: action.id,
        idTotvsInsumo: action.idTotvsInsumo,
        idCategoriaCompra: action.idCategoriaCompra,
        idSubcategoriaCompra: action.idSubcategoriaCompra,
        totvsInsumo: action.totvsInsumo,
        campo: action.campo,
        tipo: action.tipo,
        texto: action.texto,
        campo2: action.campo2,
        tipo2: action.tipo2,
        texto2: action.texto2,
        conversao: action.conversao,
        prioridade: action.prioridade,
        tipoValor: action.tipoValor,
        excluido: action.excluido
      }
    case 'TOTVS_INSUMO_CONVERSAO_UPDATED':
      return state
    case 'TOTVS_INSUMO_CONVERSAO_CLEAR':
      return initialState
    case 'TOTVS_INSUMO_CONVERSAO_CLEAR_TOTVS_INSUMO_CONVERSAOS_FORNECEDORES':
      return {
        ...state,
        totvsInsumosUnidadesFornecedores: []
      }
    case 'TOTVS_INSUMO_CONVERSAO_CLEAR_ALL':
      return {
        ...state,
        totvsInsumoConversao: []
      }
    case 'TOTVS_INSUMO_CONVERSAO_HANDLE_ON_CHANGE':
      return {
        ...state,
        [action.props]: action.value
      }
    case 'TOTVS_INSUMO_CONVERSAO_ISLOADING':
      return {
        ...state,
        isLoading: true
      }
    case 'TOTVS_INSUMO_CONVERSAO_NOTISLOADING':
      return {
        ...state,
        isLoading: false
      }
    case 'TOTVS_INSUMO_CONVERSAO_ISMODALOPEN':
      return {
        ...state,
        isModalOpen: true
      }
    case 'TOTVS_INSUMO_CONVERSAO_NOTISMODALOPEN':
      return {
        ...state,
        isModalOpen: false
      }
    default:
      return state
  }
}
