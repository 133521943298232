import React from 'react'
import { Skeleton } from '@material-ui/lab'

const SkeletonTable = () => {
  return (
    <div style={{ paddingTop: 20 }}>
      <Skeleton animation="wave" />
      <Skeleton animation="wave" />
      <Skeleton animation="wave" />
      <Skeleton animation="wave" />
    </div>
  )
}

export default SkeletonTable
