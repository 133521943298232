import React, { useEffect, useState } from 'react'
import Typography from '@material-ui/core/Typography'
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  MenuItem,
  Radio,
  RadioGroup
} from '@material-ui/core'
import { isEmpty } from 'lodash'
import {
  ValidatorForm,
  TextValidator,
  SelectValidator
} from 'react-material-ui-form-validator'
import moment from 'moment'
import { clienteAction, fidelizacaoAction, pontuacaoAction, tipoAjustePontuacaoAction, totvsVendaAction } from '_actions'
import { useDispatch, useSelector, connect } from 'react-redux'
import { withStyles } from '@material-ui/core/styles'
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'
import { toast } from 'react-toastify'

const styles = (theme) => ({
  root: {
    padding: theme.spacing(2)
  },
  content: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    textAlign: 'center'
  },
  nome: {
    marginTop: theme.spacing(1)
  },
  uploadButton: {
    marginRight: theme.spacing(2),
    justifyContent: 'space-between'
  },
  avatar: {
    height: 300,
    width: 500,
    borderRadius: '10px',
    webkitBorderRadius: '10px',
    mozBorderRadius: '10px',
    cursor: 'pointer'
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff'
  }
})

const ModalClientesAdicionarPontosManualmente = (props) => {

  const dispatch = useDispatch()
  const { classes } = props
  const [chave, setChave] = React.useState('');
  const formRef = React.createRef(null);
  const { match: { params } } = props;
  const [value, setValue] = useState('CP'); //cupom

  const tipoAjustePontuacao = useSelector((state) => state.tipoAjustePontuacao.tipoAjustePontuacao)

  const { openModalClientesAdicionarPontosManualmente, isLoading } = useSelector(
    (state) => state.cliente
  )

  const { totvsVenda } = useSelector(
    (state) => state.totvsVenda
  )

  const handleClose = () => {
    dispatch(clienteAction.openModalClientesAdicionarPontosManualmente(false))
    dispatch(totvsVendaAction.clear())
  }

  const handleSubmit = (event) => {
    event.preventDefault()

    if (value == 'CP') {
      if (chave && chave.length == 44 && totvsVenda) {
        let payload = {
          IdCliente: parseInt(params.id),
          chave: chave
        }

        dispatch(fidelizacaoAction.createFidelizacaoManualmente(payload))
        setChave('')
        dispatch(totvsVendaAction.clear())
        dispatch(clienteAction.openModalClientesAdicionarPontosManualmente(false))
      } else {
        toast.error('Forneça uma chave de cupom válida para fidelizar')
        setChave('')
        dispatch(totvsVendaAction.clear())
      }
    } else {
      if (props.pontuacao.pontos != 0 && props.pontuacao.idTipoAjustePontuacao != 0) {
        let payload = {
          IdTipoAjustePontuacao: props.pontuacao.idTipoAjustePontuacao,
          Pontos: props.pontuacao.pontos,
          IdCliente: parseInt(params.id),
          Tipo: 'E'
        }

        dispatch(pontuacaoAction.createPontuacao(payload))
        dispatch(pontuacaoAction.clear())
        dispatch(clienteAction.openModalClientesAdicionarPontosManualmente(false))
      } else {
        dispatch(pontuacaoAction.clear())
      }
    }
  }

  const handleChangePontos = (prop) => (event) => {
    dispatch(pontuacaoAction.onChangeProps(prop, event))
  }

  useEffect(() => {
    dispatch(totvsVendaAction.clear())
    setValue('CP');
  }, [])

  useEffect(() => {
    dispatch(tipoAjustePontuacaoAction.getTipoAjustePontuacaoSelect())
    setValue('CP');
  }, [])

  useEffect(() => {
    if (chave && chave != null && chave.length == 44) {
      let payload = {
        chave: chave
      }
      dispatch(totvsVendaAction.validarNota(payload));
    }
  }, [chave])

  const handleRadioChange = (event) => {
    setValue(event.target.value);
  };

  return (
    <Dialog
      open={openModalClientesAdicionarPontosManualmente}
      onClose={handleClose}
      aria-labelledby="Chave do cupom"
      maxWidth="sm"
      fullWidth
    >
      <ValidatorForm
        className={classes.form}
        ref={formRef}
        id="formAdicionarPontosManualmente"
        onSubmit={(event) => handleSubmit(event)}
      >
        <DialogTitle id="form-dialog-title">
          Selecione a partir de qual opção você deseja fidelizar
        </DialogTitle>
        <DialogContent style={{ overflow: 'hidden', paddingTop: 0, marginTop: 0 }}>
          <RadioGroup
            row
            aria-labelledby="opcao"
            name="opcao"
            value={value}
            onChange={handleRadioChange}
          >
            <FormControlLabel value="CP" control={<Radio />} label="Cupom" />
            <FormControlLabel value="PT" control={<Radio />} label="Somente pontuação" />
          </RadioGroup>
          {value == 'CP' &&
            <TextValidator
              className={classes.textField}
              fullWidth
              id="chave"
              label="Chave do cupom *"
              onChange={(e) => setChave(e.target.value)}
              value={chave}
              variant="outlined"
              InputLabelProps={{
                shrink: true
              }}
              margin="normal"
              validators={['required']}
              errorMessages={['Campo obrigatório!']}
            />
          }
          {value == 'PT' &&
            <Grid container spacing={2}>
              <Grid item md={4} xs={4}>
                <TextValidator
                  className={classes.textField}
                  fullWidth
                  id="pontuacao"
                  type="number"
                  label="Pontuação *"
                  onChange={handleChangePontos('pontos')}
                  value={props.pontuacao.pontos}
                  variant="outlined"
                  inputProps={{
                    min: 0
                  }}
                  InputLabelProps={{
                    shrink: true
                  }}
                  margin="normal"
                  validators={['required']}
                  errorMessages={['Campo obrigatório!']}
                />
              </Grid>
              <Grid item md={8} xs={8}>
                <SelectValidator
                  className={classes.textField}
                  validators={['required']}
                  errorMessages={['Campo obrigatório!']}
                  variant="outlined"
                  id="idTipoAjustePontuacao"
                  label="Tipo do Ajuste *"
                  value={props.pontuacao.idTipoAjustePontuacao}
                  onChange={handleChangePontos('idTipoAjustePontuacao')}
                  margin="normal"
                  InputLabelProps={{
                    shrink: true
                  }}
                  fullWidth
                  displayEmpty
                  labelId="idTipoAjustePontuacao"
                >
                  <MenuItem disabled value="">
                    <em>Tipo do Ajuste *</em>
                  </MenuItem>
                  {!isEmpty(tipoAjustePontuacao) &&
                    undefined !== tipoAjustePontuacao &&
                    tipoAjustePontuacao.length &&
                    tipoAjustePontuacao.map((row, index) => (
                      <MenuItem key={index} value={row.value}>
                        {row.text}
                      </MenuItem>
                    ))}
                </SelectValidator>
              </Grid>
            </Grid>
          }
          {totvsVenda && totvsVenda.chaveNota && chave.length == 44 ?
            <Grid container justifyContent="space-between" direction="row">
              <Grid item>
                <Typography variant="subtitle2" style={{ fontWeight: 500 }} alignItems="center">
                  CUPOM VÁLIDO
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="subtitle2">
                  {totvsVenda.data ? moment(totvsVenda.data).format("DD/MM/YYYY") : ''}
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="subtitle2">
                  {totvsVenda.valorTotalFidelidade ? 'R$ ' + totvsVenda.valorTotalFidelidade.toLocaleString('pt-br', { minimumFractionDigits: 2 }) : ''}
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="subtitle2">
                  {totvsVenda.loja ? totvsVenda.loja.nome.toUpperCase() : ''}
                </Typography>
              </Grid>
            </Grid>
            : chave.length >= 44 ?
              <Grid container justifyContent="space-between" direction="row">
                <Grid item>
                  <Typography variant="subtitle2" style={{ fontWeight: 500 }} alignItems="center">
                    CUPOM INVÁLIDO, CONFIRA A DATA DA ÚLTIMA IMPORTAÇÃO DE VENDAS DA LOJA
                  </Typography>
                </Grid>
              </Grid>
              :
              null
          }
        </DialogContent>
        <DialogActions>
          <Grid
            style={{ padding: 5 }}
            spacing={2}
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Grid item>
              <Button onClick={handleClose} variant="outlined" color="primary">
                Cancelar
              </Button>
            </Grid>
            <Grid item>
              <Button
                disabled={isLoading}
                type="submit"
                variant="contained"
                color="primary"
                endIcon={isLoading && <CircularProgress size={24} />}
              >
                {isLoading
                  ? 'Fidelizando...'
                  : 'Fidelizar'}
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </ValidatorForm>
    </Dialog>
  )
}

ModalClientesAdicionarPontosManualmente.propTypes = {
  classes: PropTypes.object.isRequired
}
const mapStateToProps = (state) => {
  return state
}

const connectedModalClientesAdicionarPontosManualmentePage = withRouter(
  connect(mapStateToProps, null, null, {
    pure: false
  })(withStyles(styles)(ModalClientesAdicionarPontosManualmente))
)
export { connectedModalClientesAdicionarPontosManualmentePage as ModalClientesAdicionarPontosManualmente }
