import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/styles'
import { Tooltip } from '@material-ui/core'
import HelpOutlineRoundedIcon from '@material-ui/icons/HelpOutlineRounded'
const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    paddingLeft: theme.spacing(1)
  },
  icon: {
    fontSize: 14,
    '&:hover': {
      color: theme.palette.primary.main,
      cursor: 'help'
    }
  }
}))

const HelperToolTip = (props) => {
  const { className, ...rest } = props

  const classes = useStyles()

  return (
    <div {...rest} className={clsx(classes.root, className)}>
      <Tooltip
        arrow
        placement="top"
        enterTouchDelay={0}
        leaveTouchDelay={3000}
        title={props.children}
      >
        <HelpOutlineRoundedIcon className={classes.icon} />
      </Tooltip>
    </div>
  )
}

HelperToolTip.propTypes = {
  children: PropTypes.node.isRequired
}

export default HelperToolTip
