const initialState = {
  isLoading: false,
  isModalOpen: false,
  totalRegistros: 0,
  totvsInsumoUnidade: [],
  open: false,
  id: 0,
  idTotvsInsumo: 0,
  codigoExterno: '',
  idExterno: '',
  nome: '',
  caminhoImagem: '',
  pesoLiquido: 0,
  preco: 0,
  idCategoriaInsumo: 0,
  idUnidade: 0,
  unidade: null,
  quantidadeUnidade: 0,
  contagem: true,
  idUnidadeContagem: 0,
  unidadeContagem: null,
  totvsInsumo: [],
  quantidadeUnidadeContagem: 0,
  categoriaInsumo: [],
  observacoes: '',
  marcaObrigatoria: '',
  pedidoMinimo: 0,
  pedidoMultiplo: '',
  idUnidadeIntegracao: 0,
  pesoLiquido: 0,
  unidadeIntegracao: null,
  excluido: false,
  totvsInsumosUnidadesFornecedores: [],
  page: 0,
  limit: 10,
  order: 'nome',
  direction: 'asc',
  term: '',
  indisponivel: false,
}

export function totvsInsumoUnidade(state = initialState, action) {
  switch (action.type) {
    case 'FETCHED_ALL_TOTVS_INSUMO_UNIDADE':
      return {
        ...state,
        totvsInsumoUnidade: action.totvsInsumoUnidade,
        totalRegistros: action.totalRegistros
      }
    case 'TOTVS_INSUMO_UNIDADE_DETAIL':
      return {
        ...state,
        isLoading: false,
        isModalOpen: false,
        id: action.id,
        idTotvsInsumo: action.idTotvsInsumo,
        codigoExterno: action.codigoExterno,
        idExterno: action.idExterno,
        nome: action.nome,
        caminhoImagem: action.caminhoImagem,
        pesoLiquido: action.pesoLiquido,
        preco: action.preco,
        categoriaInsumo: action.categoriaInsumo,
        idCategoriaInsumo: action.idCategoriaInsumo,
        idUnidade: action.idUnidade,
        unidade: action.unidade,
        quantidadeUnidade: action.quantidadeUnidade,
        contagem: action.contagem,
        idUnidadeContagem: action.idUnidadeContagem,
        unidadeContagem: action.unidadeContagem,
        totvsInsumo: action.totvsInsumo,
        quantidadeUnidadeContagem: action.quantidadeUnidadeContagem,
        observacoes: action.observacoes,
        marcaObrigatoria: action.marcaObrigatoria,
        pedidoMinimo: action.pedidoMinimo,
        pedidoMultiplo: action.pedidoMultiplo,
        idUnidadeIntegracao: action.idUnidadeIntegracao,
        pesoLiquidoIntegracao: action.pesoLiquidoIntegracao,
        unidadeIntegracao: action.unidadeIntegracao,
        excluido: action.excluido,
        totvsInsumosUnidadesFornecedores: action.totvsInsumosUnidadesFornecedores        
      }
    case 'TOTVS_INSUMO_UNIDADE_UPDATED':
      return state
    case 'TOTVS_INSUMO_UNIDADE_CLEAR':
      return {
        ...initialState,
        page: state.page,
        limit: state.limit,
        order: state.order,
        direction: state.direction,
        term: state.term,
        indisponivel: state.indisponivel
      }
    case 'TOTVS_INSUMO_UNIDADE_CLEAR_TOTVS_INSUMO_UNIDADES_FORNECEDORES':
      return {
        ...state,
        totvsInsumosUnidadesFornecedores: []
      }
    case 'TOTVS_INSUMO_UNIDADE_CLEAR_ALL':
      return {
        ...state,
        totvsInsumoUnidade: []
      }
    case 'TOTVS_INSUMO_UNIDADE_HANDLE_ON_CHANGE':
      return {
        ...state,
        [action.props]: action.value
      }
    case 'TOTVS_INSUMO_UNIDADE_ISLOADING':
      return {
        ...state,
        isLoading: true
      }
    case 'TOTVS_INSUMO_UNIDADE_NOTISLOADING':
      return {
        ...state,
        isLoading: false
      }
    case 'TOTVS_INSUMO_UNIDADE_ISMODALOPEN':
      return {
        ...state,
        isModalOpen: true
      }
    case 'TOTVS_INSUMO_UNIDADE_NOTISMODALOPEN':
      return {
        ...state,
        isModalOpen: false
      }
    default:
      return state
  }
}
