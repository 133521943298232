import { history } from 'utils'
import { crudService } from '../_services'
import { stringUtils } from 'utils'
import { toast } from 'react-toastify'

export const categoriaCompraAction = {
  getCategoria,
  getCategoriaSelect,
  addCategoria,
  getCategoriaById,
  onChangeProps,
  editCategoriaInfo,
  editCategoriasDetails,
  createCategoria,
  deleteCategoriaById,
  clear,
  clearAll
}

let filterModel = {
  Page: 0,
  Limit: 10,
  Term: ''
};

function getCategoria(filterModel, showWaiting) {
  return (dispatch) => {
    let apiEndpoint = 'categoriasCompras' + stringUtils.serialize(filterModel);
    crudService
      .get(apiEndpoint)
      .then((response) => {
        dispatch(changeCategoriasList(response.data.items, response.data.totalRows))
      })
      .catch((err) => {
        console.log(err)
      })
  }
}

function getCategoriaSelect() {
  return (dispatch) => {
    let apiEndpoint = 'categoriasCompras/select';
    crudService
      .get(apiEndpoint)
      .then((response) => {
        dispatch(changeCategoriasList(response.data, 0))
      })
      .catch((err) => {
        console.log(err.response);
        return err.response;
      })
  }
}

function createCategoria(payload) {
  return (dispatch) => {
    dispatch(isLoading())

    let apiEndpoint = 'categoriasCompras/'
    crudService.post(apiEndpoint, payload)
      .then((response) => {
        if (response.request != undefined && response.request.status === 200) {
          var ret = JSON.parse(response.request.responseText);
          dispatch(createCategoriaInfo())
          toast.success('Feito! Cadastro realizado com sucesso! 😎')
          dispatch(clear())
          dispatch(notIsLoading())
          history.push('/categoriasCompras')
        } else {
          toast.err('Oops! Erro ao cadastrar Categoria de Compra! 😥')
        }
      }).catch((err) => {
        dispatch(notIsLoading())
        toast.error('Oops! Erro ao cadastrar Categoria de Compra! 😥')
        return err.response;
      })
  }
}

function getCategoriaById(id) {
  return (dispatch) => {
    let apiEndpoint = 'categoriasCompras/' + id
    crudService.get(apiEndpoint).then((response) => {
      dispatch(editCategoriasDetails(response.data))
    })
  }
}

function onChangeProps(props, event) {
  return (dispatch) => {
    if (event.target.type === 'checkbox') {
      dispatch(handleOnChangeProps(props, event.target.checked))
    } else {
      dispatch(handleOnChangeProps(props, event.target.value))
    }
  }
}

function editCategoriaInfo(id, payload, redirect) {
  return (dispatch) => {
    dispatch(isLoading())
    let apiEndpoint = 'categoriasCompras/' + id
    crudService.put(apiEndpoint, payload)
      .then((response) => {
        if (response.request != undefined && response.request.status === 200) {
          var ret = JSON.parse(response.request.responseText);
          dispatch(updatedCategoriaInfo())
          dispatch(clear())
          if (redirect) {
            dispatch(notIsLoading())
            history.push('/categoriasCompras')
          }
        }
      }).catch((err) => {
        dispatch(notIsLoading())
        toast.error('Oops! Erro ao atualizar Categoria de Compra! 😥')
        return err.response;
      }
      )
  }
}

function deleteCategoriaById(id) {
  return (dispatch) => {
    dispatch(isLoading())
    let apiEndpoint = 'categoriasCompras/' + id
    crudService.deleteDetail(apiEndpoint).then(() => {
      dispatch(deleteCategoriasDetails())
      dispatch(getCategoria(filterModel))
      dispatch(notIsLoading())
      history.push('/categoriasCompras')
    })
  }
}

export function addCategoria(payload) {
  return [{ type: 'ADD_CATEGORIACOMPRA', categoriaCompra: payload }, clear()]
}

export function changeCategoriasList(categoriaCompra, totalRows) {
  return {
    type: 'FETCHED_ALL_CATEGORIACOMPRA',
    categoriaCompra: categoriaCompra,
    totalRegistros: totalRows
  }
}

export function clear() {
  return {
    type: 'CATEGORIACOMPRA_CLEAR'
  }
}

export function clearAll() {
  return {
    type: 'CATEGORIACOMPRA_CLEAR_ALL'
  }
}

export function handleOnChangeProps(props, value) {
  return {
    type: 'CATEGORIACOMPRA_HANDLE_ON_CHANGE',
    props: props,
    value: value
  }
}

export function editCategoriasDetails(categoriaCompra) {
  return [
    { type: 'CATEGORIACOMPRA_DETAIL', categoriaCompra },
  ]
}

export function updatedCategoriaInfo() {
  return {
    type: 'CATEGORIACOMPRA_UPDATED'
  }
}

export function createCategoriaInfo() {
  return {
    type: 'CATEGORIACOMPRA_CREATED_SUCCESSFULLY'
  }
}

export function deleteCategoriasDetails() {
  return {
    type: 'DELETED_CATEGORIACOMPRA_DETAILS'
  }
}
export function isLoading() {
  return {
    type: 'CATEGORIACOMPRA_ISLOADING'
  }
}

export function notIsLoading() {
  return {
    type: 'CATEGORIACOMPRA_NOTISLOADING'
  }
}
