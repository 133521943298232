import React, { useEffect, useState } from 'react'
import { connect, useDispatch } from 'react-redux'
import { parametroAction } from '../../_actions'
import PropTypes from 'prop-types'
import {
  withStyles,
  Table,
  TableContainer,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Tooltip,
  Input,
  Grid,
  TablePagination,
  Typography
} from '@material-ui/core'
import EditIcon from '@material-ui/icons/Edit'
import { withRouter } from 'react-router-dom'
import { history } from '../../utils'
import { AccessControl } from '../../components/Utils/AccessControl'
import AccessDenied from '../Errors/AccessDenied'
import { Page, PaginationActions } from 'components'
import { useDebounce } from 'use-debounce';
import { useSelector } from 'react-redux'

const styles = (theme) => ({
  root: {
    padding: theme.spacing(3, 2, 2, 2)
  }
})

const Parametro = (props) => {

  const dispatch = useDispatch()
  const { classes } = props
  const { parametro }  = props.parametro 
  const { totalRegistros } = props.parametro
  const { idLoja } = useSelector(state => state.usuarioLoja)
  const { page, limit, term, direction, order } = useSelector(state => state.parametro);
  const [debounceTerm] = useDebounce(term, 1000)

  useEffect(() => {
    fetchData();
  }, [page, limit, debounceTerm]);

  useEffect(() => {
    handleChangePage(null, 0);
  }, [totalRegistros])

  useEffect(() => {
    handleChangePage(null, page);
    fetchData();
  }, [idLoja]);

  const fetchData = () => {
    let filterModel = {
      Page: page || 0,
      Limit: limit || 10,
      Term: debounceTerm || '',
      Order: order || 'nome',
      Direction: direction || 'asc'
    };

    dispatch(parametroAction.getParametro(filterModel, true));
  };

  const handleChange = prop => event => {
    dispatch(parametroAction.onChangeProps(prop, event))
  };

  const handleChangePage = (_, value) => {
    const fakeEvent = {
      type: 'text',
      target: {
        value
      }
    }
    dispatch(parametroAction.onChangeProps('page', fakeEvent))
  }

  const handleChangeTerm = (value) => {
    const fakeEvent = {
      type: 'text',
      target: {
        value
      }
    }
    props.parametro.page = 0;
    dispatch(parametroAction.onChangeProps('term', fakeEvent))
  }

  return (
    <Page className={classes.root} title="Parâmetros">
      <AccessControl
        rule={'parametros'} //permissão necessária para acessar conteúdo
        yes={() => (
          <div>
            <Grid alignItems="flex-end" container justifyContent="space-between" spacing={3} >
              <Grid item>
                <Typography component="h2" gutterBottom variant="overline">
                  Gerência de Parâmetro
                </Typography>
                <Typography component="h1" variant="h3">
                  Lista de parâmetros
                </Typography>
              </Grid>
            </Grid>
            <Grid item xl={4} lg={4} md={6} xs={12}>
              <Input
                id="term"
                label="term"
                placeholder="Filtro"
                value={term}
                onChange={(event) => handleChangeTerm(event.target.value)}
                fullWidth
              />
            </Grid>
            <br />
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Descrição</TableCell>
                    <TableCell align="right">Ações</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {undefined !== parametro && parametro.length
                    ? parametro.map((n) => {
                      return (
                        <TableRow hover key={n.id}>
                          <TableCell component="th" scope="row">
                            {n.descricao}
                          </TableCell>
                          <TableCell align="right">
                            <AccessControl
                              rule={'parametros.edit'} //permissão necessária para acessar conteúdo
                              yes={() => (
                                <IconButton
                                  aria-label="Edit"
                                  component="a"
                                  onClick={() =>
                                    history.push(`/parametro/${n.id}`)
                                  }
                                  size="small"
                                >
                                  <Tooltip title="Editar">
                                    <Tooltip >
                                      <EditIcon />
                                    </Tooltip>
                                  </Tooltip>
                                </IconButton>
                              )}
                            />
                           
                          </TableCell>
                        </TableRow>
                      )
                    })
                    : null}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              component="div"
              count={totalRegistros}
              page={page}
              onPageChange={handleChangePage}
              rowsPerPage={limit}
              onRowsPerPageChange={handleChange('limit')}
              ActionsComponent={PaginationActions}
            />
          </div>
        )}
        no={() => <AccessDenied />}
      />
    </Page>
  )
}
Parametro.propTypes = {
  classes: PropTypes.object.isRequired
}
const mapStateToProps = (state) => {
  return {
    parametro: state.parametro
  }
}
const connectedParametroPage = withRouter(
  connect(mapStateToProps, null, null, {
    pure: false
  })(withStyles(styles)(Parametro))
)
export { connectedParametroPage as Parametro }
