import { history } from 'utils'
import { crudService } from '../_services'
import { stringUtils } from 'utils'
import { toast } from 'react-toastify'

export const campanhaAction = {
  getCampanha,
  addCampanha,
  getCampanhaSelectLoja,
  getCampanhaById,
  getCampanhaArquivo,
  onChangeProps,
  editCampanhaInfo,
  editCampanhasDetails,
  createCampanha,
  deleteCampanhaById,
  clear,
  clearAll
}

let filterModel = {
  Page: 0,
  Limit: 10,
  Term: ''
};

function getCampanha(filterModel, showWaiting) {
  return (dispatch) => {
    let apiEndpoint = 'campanhas' + stringUtils.serialize(filterModel);
    crudService
      .get(apiEndpoint)
      .then((response) => {
        dispatch(changeCampanhasList(response.data.items, response.data.totalRows))
      })
      .catch((err) => {
        console.log(err)
      })
  }
}

function createCampanha(payload) {
  return (dispatch) => {
    dispatch(isLoading())
    let apiEndpoint = 'campanhas/'
    crudService.post(apiEndpoint, payload)
      .then((response) => {
        if (response.request != undefined && response.request.status === 200) {
          var ret = JSON.parse(response.request.responseText);
          dispatch(createCampanhaInfo())
          toast.success('Feito! Cadastro realizado com sucesso! 😎')
          dispatch(clear())
          dispatch(notIsLoading())
          history.push('/campanhas')
        } else {
          toast.err('Oops! Erro ao cadastrar campanha! 😥')
        }
      }).catch((err) => {
       // console.log(err.response);
        dispatch(notIsLoading())
        toast.error('Oops! Erro ao cadastrar campanha! 😥')
        return err.response;
      })
  }
}

function getCampanhaSelectLoja(id) {
  return (dispatch) => {
    let apiEndpoint = 'campanhas/select/loja/' + id
    crudService
      .get(apiEndpoint)
      .then((response) => {
        if (response.data) {
          dispatch(changeCampanhasList(response.data))
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }
}

function getCampanhaById(id) {
  return (dispatch) => {
    let apiEndpoint = 'campanhas/' + id
    crudService.get(apiEndpoint).then((response) => {
      dispatch(editCampanhasDetails(response.data))
    })
  }
}

function getCampanhaArquivo(id, showWaiting) {
  return (dispatch) => {
    dispatch(isLoading())

    let apiEndpoint = 'campanhas/arquivo/' + id;
    crudService
      .get(apiEndpoint)
      .then((response) => {
        const BOM = '\uFEFF';
        var FileSaver = require('file-saver');
        var blob = new Blob([BOM + response.data], { type: "text/csv;charset=utf-8" });
        FileSaver.saveAs(blob, "campanha_" + id + ".csv");
        toast.success('Feito! Download do arquivo realizado com sucesso! 😎')
        dispatch(notIsLoading())
      })
      .catch((err) => {
        console.log(err)
      })
  }
}

function onChangeProps(props, event) {
  return (dispatch) => {
    if (event.target.type === 'checkbox') {
      dispatch(handleOnChangeProps(props, event.target.checked))
    } else {
      dispatch(handleOnChangeProps(props, event.target.value))
    }
  }
}

function editCampanhaInfo(id, payload, redirect) {
  return (dispatch) => {
    dispatch(isLoading())
    let apiEndpoint = 'campanhas/' + id
    crudService.put(apiEndpoint, payload)
      .then((response) => {
        if (response.request != undefined && response.request.status === 200) {
          var ret = JSON.parse(response.request.responseText);
          dispatch(updatedCampanhaInfo())
          dispatch(clear())
          if (redirect) {
            dispatch(notIsLoading())
            history.push('/campanhas')
          }
        }
      }).catch((err) => {
        dispatch(notIsLoading())
        toast.error('Oops! Erro ao atualizar campanha! 😥')
        //  console.log(err.response);
        return err.response;
      }
  )}
}

function deleteCampanhaById(id) {
  return (dispatch) => {
    dispatch(isLoading())
    let apiEndpoint = 'campanhas/' + id
    crudService.deleteDetail(apiEndpoint).then(() => {
      dispatch(deleteCampanhasDetails())
      dispatch(getCampanha(filterModel))
      dispatch(notIsLoading())
      history.push('/campanhas')
    })
  }
}

export function addCampanha(payload) {
  return [{ type: 'ADD_CAMPANHA', campanha: payload }, clear()]
}

export function changeCampanhasList(campanha, totalRows) {
  return {
    type: 'FETCHED_ALL_CAMPANHA',
    campanha: campanha,
    totalRegistros: totalRows
  }
}

export function clear() {
  return {
    type: 'CAMPANHA_CLEAR'
  }
}

export function clearAll() {
  return {
    type: 'CAMPANHA_CLEAR_ALL'
  }
}

export function handleOnChangeProps(props, value) {
  return {
    type: 'CAMPANHA_HANDLE_ON_CHANGE',
    props: props,
    value: value
  }
}

export function editCampanhasDetails(campanha) {
  return [
    { type: 'CAMPANHA_DETAIL', campanha },
  ]
}

export function updatedCampanhaInfo() {
  return {
    type: 'CAMPANHA_UPDATED'
  }
}

export function createCampanhaInfo() {
  return {
    type: 'CAMPANHA_CREATED_SUCCESSFULLY'
  }
}

export function deleteCampanhasDetails() {
  return {
    type: 'DELETED_CAMPANHA_DETAILS'
  }
}
export function isLoading() {
  return {
    type: 'CAMPANHA_ISLOADING'
  }
}

export function notIsLoading() {
  return {
    type: 'CAMPANHA_NOTISLOADING'
  }
}
