const initialState = {
  isLoading: false,
  totalRegistros: 0,
  loja: [],
  open: false,
  id: 0,
  idTotvs: '',
  idYungas: '',
  nome: '',
  codigoEstabelecimento: '',
  cnpj: '',
  ie: '',
  qrCode: '',
  dataUltimaAtualizacaoCardapio: '',
  dataUltimaVendaImportada: '',
  idCidade: '',
  idEstado: '',
  latitude: '',
  longitude: '',
  telefone: '',
  email: '',
  tipoIntegracao: '',
  regimeTributario: '',
  senhaEmail: '',
  caminhoImagem: '',
  toGo: false,
  delivery: false,
  usuariosLojas: [],
  excluido: false,
  cidade: null,
  estado: null,
  page: 0,
  limit: 10,
  order: 'nome',
  direction: 'asc',
  term: ''
}

export function loja(state = initialState, action) {
  switch (action.type) {
    case 'FETCHED_ALL_LOJA':
      return {
        ...state,
        loja: action.loja,
        totalRegistros: action.totalRegistros
      }
    case 'LOJA_DETAIL':
      return {
        ...state,
        isLoading: false,
        id: action.id,
        idTotvs: action.idTotvs,
        idYungas: action.idYungas,
        nome: action.nome,
        codigoEstabelecimento: action.codigoEstabelecimento,
        cnpj: action.cnpj,
        ie: action.ie,
        idCidade: action.idCidade,
        idEstado: action.idEstado,
        qrCode: action.qrCode,
        latitude: action.latitude,
        longitude: action.longitude,
        telefone: action.telefone,
        usuariosLojas: action.usuariosLojas,
        excluido: action.excluido,
        cidade: action.cidade,
        estado: action.estado,
        caminhoImagem: action.caminhoImagem,
        toGo: action.toGo,
        delivery: action.delivery,
        email: action.email,
        tipoIntegracao: action.tipoIntegracao,
        regimeTributario: action.regimeTributario,
        senhaEmail: action.senhaEmail,
        dataUltimaAtualizacaoCardapio: action.dataUltimaAtualizacaoCardapio,
        dataUltimaVendaImportada: action.dataUltimaVendaImportada
      }
    case 'LOJA_ESTADO_DETAIL':
      return {
        ...state,
        idEstado: action.idEstado
      }
    case 'LOJA_UPDATED':
      return state
    case 'LOJAEMAIL_UPDATED':
      return state
    case 'LOJA_CLEAR':
      return {
        ...initialState,
        page: state.page,
        limit: state.limit,
        order: state.order,
        direction: state.direction,
        term: state.term
      }
    case 'LOJA_CLEAR_ALL':
      return {
        ...state,
        loja: []
      }
    case 'LOJA_CLEAR_USUARIOS_LOJAS':
      return {
        ...state,
        usuariosLojas: []
      }
    case 'LOJA_HANDLE_ON_CHANGE':
      return {
        ...state,
        [action.props]: action.value
      }
    case 'LOJA_ISLOADING':
      return {
        ...state,
        isLoading: true
      }
    case 'LOJA_NOTISLOADING':
      return {
        ...state,
        isLoading: false
      }
    default:
      return state
  }
}
